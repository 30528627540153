import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import sucessImage from "../../../assets/images/authorize-user.svg";
import changeRole from "../../../assets/images/changeRole.svg";
import LabelInput from "../Input/LabelInput";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function DownloadAndDeactiveModal(props) {
  const {
    status,
    handleclosed,
    userId,
    selectedEmail,
    downloadUserActivity,
    deactivateUser,
    showDeactivate,
    deactivateAdminUser,
    showDeactivateadmin,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal
        open={true} // Open the modal whenever it is rendered
        onClose={handleclosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {showDeactivate ? null : (
              <img
                src={sucessImage}
                width="75px"
                height="95px"
                alt="email icon"
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              flexDirection: "column",
            }}
          >
            {showDeactivate ? (
              <Typography
                component={"h6"}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  textAlign: "center",
                }}
              >
                {t("AdministratorUsers.actionsTextActions2")}{" "}
                {status
                  ? t("AdministratorUsers.deactivate")
                  : t("AdministratorUsers.activate")}{" "}
                
                <Box component={"span"} sx={{ color: "primary.main" }}>
                  {t("AdministratorUsers.user")}
                </Box>
              </Typography>
            ) : (
              <Typography
                component={"h6"}
                sx={{
                  color: "neutral.900",
                  fontSize: 20,
                  fontFamily: "Gilroy-SemiBold",
                  textAlign: "center",
                }}
              >
                {t("AdministratorUsers.actionsTextActions1")}
                <Box component={"span"} sx={{ color: "primary.main" }}>
                  {" "}
                </Box>
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "300px",
              m: "auto",
              mt: 4,
            }}
          >
            <LabelInput
              type="text"
              subLabel=""
              label=""
              name="Email"
              id="Email"
              placeholder="Email"
              inputValue={selectedEmail}
              readOnly={true}
              inputStyle={{ textAlign: "center" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={() => {
                if (showDeactivate) {
                  // If showDeactivate is true, call deactivateUser(userId)
                  deactivateUser(userId);
                } else if (showDeactivateadmin) {
                  deactivateAdminUser(userId);
                } else {
                  // If showDeactivate is false, call downloadUserActivity
                  downloadUserActivity(userId);
                }
              }}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("buttons.Accept")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={handleclosed}
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {" "}
              {t("buttons.back")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
