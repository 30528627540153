import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useState } from "react";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Link, Navigate } from "react-router-dom";
import google from "../../assets/images/google.png";
import microSoft from "../../assets/images/microsoft.png";
import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import mailImage from "../../assets/images/mailIcon.png";
import couponimg from "../../assets/images/couponimg.png";
import sjcl from "sjcl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { serverRequest } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { emailPattern } from "../../utils/InputPatterns";
import {
  validateInput,
  validateInputCoupon,
} from "../../utils/inputValidation";
import AuthLeftScreen from "../@common/AuthLeftScreen";
function GuestUser() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [coupon, setCoupon] = useState("");
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const navigate = useNavigate();
  var arrayData = [];
  arrayData.push(email);
  arrayData.push(coupon);
  const [formErrors, setFormErrors] = useState({
    email: "",
    coupon: "",
  });
  const handleSubmit = () => {
    const errors = {};
    validateInput(email, emailPattern, "email", errors, t);
    validateInputCoupon(coupon, null, "coupon", errors, t);

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      guestLogin(arrayData);
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const guestLogin = async (data) => {
    var dataForm = {
      data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[0], {
        iter: 500,
      }),
      data_ecn: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[1], {
        iter: 500,
      }),
    };

    var data_eel = JSON.parse(dataForm["data_eel"]);
    var data_ecn = JSON.parse(dataForm["data_ecn"]);

    var dataForm_ = {
      data_eel: {
        data_eeli: data_eel["iv"],
        data_eels: data_eel["salt"],
        data_eelc: data_eel["ct"],
      },
      data_ecn: {
        data_ecni: data_ecn["iv"],
        data_ecns: data_ecn["salt"],
        data_ecnc: data_ecn["ct"],
      },
    };
    const response = await serverRequest(
      "post",
      "ingresar_invitado",
      dataForm_
    );
    if (typeof response.data == "object") {
      response.data.push(data[0]);
      response.data.push(data[1]);
      Cookies.set("guestUserInfo", response.data);
      navigate("/GuestDashboard");
    } else if (response.data === false) {
      setShowAlert(true);
      setShowtext(t("GuestUser.popup.loginPopUpMessageWrongCoupon"));
    } else {
      setShowAlert(true);
      setShowtext("Something went wrong");
    }
  };
  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: "#ffffff",
                  position: "relative",
                  zIndex: 1,
                  borderRadius: 1,
                  p: { xs: 2, sm: 4 },
                  width: { xs: "90%", sm: "500px" },
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <img src={logo} alt="logo" />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-SemiBold",
                      fontSize: "24px",
                      lineHeight: "35px",
                      textAlign: "center",
                      color: "#1C212D",
                    }}
                  >
                    {t("GuestUser.header.title")}
                  </Typography>
                  <Box sx={{ textAlign: "center", mt: -1 }}>
                    <img src={underLine} alt="under line" />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", mt: 2, flexDirection: "column" }}>
                  <LabelInputLeftIcon
                    mailIcon={mailImage}
                    type="text"
                    label={t("GuestUser.inputs.inputLabelEmail")}
                    name="emailAddress"
                    id="emailAddress"
                    placeholder={t("GuestUser.inputs.inputTextEmail")}
                    onChange={(e) => setEmail(e.target.value)}
                    error={formErrors.email}
                  />
                  {formErrors.email && (
                    <Typography variant="body2" color="error">
                      {formErrors.email}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 1 }}>
                  <LabelInputLeftIcon
                    mailIcon={couponimg}
                    type="text"
                    label={t("GuestUser.inputs.inputLabelCoupon")}
                    name="Coupon"
                    id="Coupon"
                    placeholder={t("GuestUser.inputs.inputTextPassword")}
                    onChange={(e) => setCoupon(e.target.value)}
                    error={formErrors.coupon}
                  />
                  {formErrors.coupon && (
                    <Typography variant="body2" color="error">
                      {formErrors.coupon}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 6,
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: "primary.main",
                      py: 1,
                      px: { xs: 4, sm: 10 },
                      fontSize: "18px",
                      fontFamily: "Gilroy-Medium",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                  >
                    {t("GuestUser.buttons.buttonTextGuestLogin")}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "row",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      color: "rgba(33, 33, 33, 0.5)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "underline",
                    }}
                  >
                    {t("GuestUser.buttons.returnMainPageLink")}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen
              text={t("Login.buttons.signUpLink")}
              url="/Signup"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default GuestUser;
{
  /* <Grid item xs={12} md={6}>
<AuthLeftScreen
  text={t("Login.buttons.signUpLink")}
  url="/Signup"
/>
</Grid> */
}
