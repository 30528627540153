import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Webcam from "react-webcam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import CameraRearIcon from "@mui/icons-material/CameraRear";
import { useTranslation } from "react-i18next";
import LogoModalLoader from "../../@common/Modal/WithBureauModal/LogoModalLoader";
import axios from "axios";
import AlertModal from "../../@common/Modal/AlertModal";
import { getCookiesList } from "../../../utils/cookiesHelper";

const CaptureModal = (props) => {
  const { t } = useTranslation();
  const { showWebcam, setShowWebcam, setFileList, setDropError, fileList } =
    props;
  const webcamRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const [uploadFile, setUploadFile] = useState(null); // Initialize as null
  const [showtext, setShowtext] = useState("");
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [cookieList, setCookieList] = useState([]);
  const [cameraType, setCameraType] = useState("front");
  const [isMobile, setIsMobile] = useState(false);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  const handleUserMediaError = useCallback((error) => {
    console.error("Webcam error:", error);
    setShowAlert(true);
    setShowtext(
      isMobile
        ? t("consultField.noCameraMobileError")
        : t("consultField.noCameraDesktopError")
    );
  }, []);

  const style = {
    "*::-webkit-scrollbar": {
      width: "6px !important",
      borderRadius: "3px !important",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "#E4EFEF !important",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#4165f1d4 !important",
      borderRadius: "3px !important",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: { sm: "75%", xs: "95%" },
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    padding: "40px",
    borderRadius: 1,
  };

  const capture = useCallback(() => {
    setPreview(true);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const newFile = new File([blob], `captured-${Date.now()}.jpeg`, {
            type: "image/jpeg",
          });
          const previewUrl = URL.createObjectURL(newFile);
          setUploadFile({ file: newFile, preview: previewUrl });
        })
        .catch((error) =>
          console.error("Failed to create file from captured image:", error)
        );
    }
  }, [webcamRef]);

  const handleClose = () => {
    setShowWebcam(false);
    setPreview(false);
  };

  const uploadImage = async () => {
    if (!uploadFile) {
      setDropError("No image to upload.");
      return;
    }
    // Show loader
    handleOpenLogoModal();
    try {
      const formData = new FormData();
      formData.append("file", uploadFile.file);
      formData.append("email", cookieList[3]);

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL2 + `upload_document`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data) {
        handleCloseLogoModal();
        // Check if the document already exists based on document_name
        const documentName = response?.data?.document_name;
        const isDuplicate = fileList.some(
          (existingFile) => existingFile.response.document_name === documentName
        );

        if (isDuplicate) {
          setShowAlert(true);
          setShowtext(t("consultField.alreadyUploadError"));
          return;
        }
        //

        if (response.data.error === null) {
          const uploadedFile = {
            ...uploadFile,
            response: response.data, // Add the server response to the uploaded file object
          };

          if (fileList.length >= 10) {
            setDropError(t("consultField.maxFileError"));
          } else {
            setFileList((prevFiles) => [...prevFiles, uploadedFile]);
            setDropError(""); // Clear previous errors
          }
          setShowWebcam(false);
          setPreview(false);
        } else if (response?.data?.error === "we already have a upload") {
          setShowAlert(true);
          setShowtext(t("consultField.alreadyUploadError"));
        } else {
          setDropError(t("consultField.invalidDoc"));
          setShowWebcam(false);
          setPreview(false);
        }
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    const checkIfMobile = () => {
      const mobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
      setIsMobile(mobile);
    };
    checkIfMobile();
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  return (
    <>
      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <Modal
        open={showWebcam}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "fixed",
              top: "5px",
              right: "5px",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {showWebcam && !preview ? (
            <Box
              sx={{
                textAlign: "center",
                // mt: 3,
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
                videoConstraints={{
                  facingMode: cameraType === "front" ? "user" : "environment",
                }}
                onUserMediaError={handleUserMediaError}
                style={{
                  objectFit: "fill",
                  height: "65vh",
                  borderRadius: "12px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "20px",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    setCameraType(cameraType === "front" ? "rear" : "front")
                  }
                  aria-label="Capture"
                  sx={{
                    mt: 2,
                    width: "50px",
                    height: "60px",
                    borderRadius: "50%",
                    border: "2px solid #fff",
                    margin: "0 auto",
                    backgroundColor: "primary.main",
                  }}
                >
                  {cameraType === "front" ? (
                    <CameraFrontIcon />
                  ) : (
                    <CameraRearIcon />
                  )}
                </Button>
                <Button
                  variant="contained"
                  onClick={capture}
                  aria-label="Capture"
                  sx={{
                    mt: 2,
                    width: "50px",
                    height: "60px",
                    borderRadius: "50%",
                    border: "2px solid #fff",
                    margin: "0 auto",
                    backgroundColor: "primary.main",
                  }}
                >
                  <CameraAltIcon />
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                // mt: 3,
                borderRadius: "12px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  objectFit: "fill",
                  height: "60vh",
                  borderRadius: "12px",
                }}
              >
                {uploadFile && (
                  <img
                    src={uploadFile.preview}
                    alt={`preview-${uploadFile.name}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "12px",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                    borderRadius: "8px",
                    py: { sm: 1.2, xs: 1 },
                    px: { sm: 5, xs: 3 },
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: { sm: 18, xs: 16 },
                    fontFamily: "Gilroy-Medium",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setPreview(false);
                  }}
                >
                  {t("consultField.cancel")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: "primary.main",
                    textAlign: "center",
                    borderRadius: "8px",
                    py: { sm: 1.2, xs: 1 },
                    px: { sm: 5, xs: 3 },
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: { sm: 18, xs: 16 },
                    fontFamily: "Gilroy-Medium",
                    textTransform: "capitalize",
                  }}
                  onClick={uploadImage}
                >
                  {t("consultField.done")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CaptureModal;
