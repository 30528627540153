import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getCookiesList } from "../../../utils/cookiesHelper";

export const UnProtectedRoute = ({ children }) => {
  const location = useLocation();
  const userCookiesList = getCookiesList("userInfo");
  const guestCookiesList = getCookiesList("guestUserInfo");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isGuestAuthenticated, setIsGuestAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkGuestIsAuthenticated = async () => {
    if (
      typeof guestCookiesList !== "object" ||
      guestCookiesList.length !== 0 ||
      guestCookiesList[3] !== undefined ||
      guestCookiesList[4] !== undefined
    ) {
      return true;
    }
    return false;
  };

  const checkUserIsAuthenticated = async () => {
    if (
      typeof userCookiesList !== "object" ||
      userCookiesList.length !== 0 ||
      userCookiesList[3] !== undefined ||
      userCookiesList[4] !== undefined
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const guest = await checkGuestIsAuthenticated();
      const userAuth = await checkUserIsAuthenticated();
      if (guest) {
        setIsGuestAuthenticated(guest);
      } else if (userAuth) {
        setIsUserAuthenticated(userAuth);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [location]);

  if (isLoading) {
    return null;
  }
  if (isUserAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }
  if (isGuestAuthenticated) {
    return <Navigate to="/GuestDashboard" replace />;
  }
  return <Outlet />;
};
