import { useRef } from "react";
import "./DropDownInputSimple.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import mailImage from "../../../../assets/images/company-icon.png";
import { useTranslation } from "react-i18next";

function DropDownInputSimple(props) {
  const {
    options_data = [],
    onChange,
    containerStyle,
    inputValue,
    label = "",
    errorMsg = "errorMsg",
    flag = false,
    name,
    value = "",
    firstName = value,
    id = "",
    star,
    disabled,
    hideFirst = false,
    disableFirst = false,
    icon = "expand_more",
    onBlur,
    mailIcon = { mailImage },
    placeholder,
    subLabel,
    mandatory,
    FilledOut,
    useCustomFormat = false,
    FirstOptionNull,
    PrefixNumber,
    selectedColor,
    showDefaultOption,
    clickable,
    emoduleInput
  } = props;
  const { t } = useTranslation();

  const selectRef = useRef(null);

  return (
    <div className="clddi_input_container"
      onClick={() => clickable && selectRef.current.click()} // Trigger select click if clickable

    >
      <label htmlFor="" className="clddi_label">
        {label ? label : ""}
        <span
          style={{
            color: "rgba(27, 32, 44, 0.6)",
            fontSize: "12px",
            marginLeft: "6px",
          }}
        >
          {subLabel ? subLabel : ""}
        </span>
      </label>
      <div className="clddi_div1">
        <div
          className="clddi_container"
          style={{
            backgroundColor: disabled ? 
            "rgba(100,100,100,0.5)": emoduleInput
              ? (
                FilledOut
                  ? "rgba(65, 101, 246, 0.06)"
                  : (
                    inputValue !== "State"
                      ? "rgba(65, 101, 246, 0.06)"
                      : (mandatory ? "rgba(255,140, 0, 0.1)" : "rgba(65, 101, 246, 0.06)")
                  )
              )
              : FilledOut ? 
              "rgba(65, 101, 246, 0.06)" : (inputValue !== "State" ? 
              "rgba(65, 101, 246, 0.06)" : mandatory ? 
              "rgba(255,140, 0, 0.1)" : (inputValue === "State" && "rgba(65,101,246,.06)")),
            marginTop: "-10px",
            borderRadius: "5px",
          }}
        >
          <select
            disabled={disabled}
            style={{
              // backgroundColor: (mandatory? "rgba(255,140, 0, 0.1)":null),
              marginTop: "0px",
              borderRadius: "5px",
              cursor: disabled ? "not-allowed" : "pointer",
            }}
            className="clddi_input form-select"
            value={inputValue}
            onChange={onChange}
            ref={selectRef}
            aria-describedby={flag ? "ErrorMessage" : ""}
            aria-required="true"
            aria-invalid={flag ? "true" : "false"}
            data-rule="select"
            placeholder={placeholder}
          >
            {showDefaultOption && <option value="">{t("labels.default")}</option>}

            {FirstOptionNull ? !hideFirst ? (
              <option value="" disabled={disableFirst}>
                {firstName}
              </option>
            ) : null : null}
            {!disabled ? (
              useCustomFormat ? (
                options_data.map((item, index) => (
                  <option value={item.value} key={index}>
                    {`${item.d_codigo}, ${item.d_asenta}, ${item.D_mnpio}, ${item.d_estado}`}
                  </option>
                ))
              ) :
                PrefixNumber ? (options_data.map(country => (
                  <option key={country.code} value={country.prefix} >
                    {`(+${country.prefix.split(',')[0]}) ${country.name} `} {/* Displaying only the first part of the prefix */}
                  </option>
                ))) : (
                  options_data.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.name}
                    </option>
                  ))
                )
            ) : (
              <option value={inputValue} key={1}>
                {inputValue}
              </option>
            )}
          </select>
          <ExpandMoreIcon className="clddi_icon" />
        </div>
        <div
          id="ErrorMessage"
          aria-hidden={flag ? "false" : "true"}
          role="alert"
          style={{ display: flag ? "block" : "none" }}
        >
          <p className="clddi_error_text">{errorMsg}</p>
        </div>
      </div>
    </div>
  );
}

export default DropDownInputSimple;
