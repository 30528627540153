import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileUploadInput from "../@common/Input/FileUploadInput/FileUploadInput";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ThankYouMessageModal from "./ThankYouMessageModal";
import { getCookiesList, removeCookie } from "../../utils/cookiesHelper";
import { encryptData } from "../../utils/encryptionHelper";
import axios from "axios";
import { serverRequest } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { useTranslation } from "react-i18next";
function LaborReferencesForm() {
  const { t } = useTranslation();
  const [age, setAge] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [cookieList, setCookieList] = useState([]);
  const [cardlength, setcardlength] = useState("");
  const [questionlength, setquestionlength] = useState("");
  const [card1, setcard1] = useState([]);
  const [question1, setquestion1] = useState([]);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showText, setShowText] = useState("");
  const [showSuccess, setSuccess] = useState(false);
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {}, [cookieList]);

  const handleFileSelection = (files) => {
    setSelectedFiles(files);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const uploadHandler = () => {
    setShowAlert(true);
    setShowText("uploadHandler");
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
    setSuccess(false);
  };
  const deleteHandler = (nombreDoc, tipoDoc) => {
    var data_array = [nombreDoc, tipoDoc];
    deletereferencedata(data_array);
  };
  const LengthMap = {
    Zero: 0,
    One: 1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5,
  };
  function convertDigitToLetter(digit) {
    const digitMap = {
      0: "Zero",
      1: "One",
      2: "Two",
      3: "Three",
      4: "Four",
      5: "Five",
    };

    return digitMap[digit] || "Invalid Digit";
  }
  const handleSubmit = async () => {
    if (cardlength === "Five" && questionlength === "Five") {
      setShowAlert(true);
      setShowText("no more files can be included since the limit is 5");
      return;
    }
    if (!age || (cardlength === "Five" && questionlength === "Five")) {
      setShowAlert(true);
      setShowText("Select the Reference Type");
      return;
    }
    if (!selectedFiles || selectedFiles.length === 0) {
      setShowAlert(true);
      setShowText("input the pdf file");
      return;
    }
    const tipoDoc = age;
    const fileName = selectedFiles[0].path;
    const file = selectedFiles[0]; // Keep the file object itself
    const nombre_empresa = cookieList[13];
    const formData = new FormData();
    formData.append("tipoDoc", tipoDoc);
    formData.append("fileName", fileName);
    formData.append("file", file);
    formData.append("nombre_empresa", nombre_empresa);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL2 + "rl/subir_documento",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShowThankYouModal(true);
      get_reference_data();
    } catch (error) {
      console.error(error);
    }
  };
  const handleclosed = () => {
    // Perform upload logic here
    setShowThankYouModal(false); // Set the state to show the modal
  };
  const get_reference_data = async () => {
    if (
      cookieList.length > 0 &&
      cookieList[13] !== undefined &&
      cookieList[13]
    ) {
      const data_ene = encryptData(cookieList[13], 500);
      const dataForm_ = {
        data_ene: {
          data_enei: data_ene.iv,
          data_enes: data_ene.salt,
          data_enec: data_ene.ct,
        },
        token: cookieList[13],
      };
      const response = await serverRequest("post", "rl/get_rl_docs", dataForm_);
      console.log("response", response);
      const question = convertDigitToLetter(
        response.data.cuestionarios?.length || 0
      );
      const cards = convertDigitToLetter(response.data?.cartas?.length || 0);
      setquestionlength(question);
      setcard1(response.data?.cartas);
      setquestion1(response.data?.cuestionarios);
      setcardlength(cards);
    }
  };
  useEffect(() => {
    get_reference_data();
  }, [cookieList]);

  const deletereferencedata = async (data_array) => {
    const data_eel = encryptData(cookieList[3], 500);
    const data_ene = encryptData(cookieList[13], 500);
    const data_end = encryptData(data_array[0], 500);
    const data_etd = encryptData(data_array[1], 500);
    let dataForm = {
      data_eel: {
        data_eeli: data_eel.iv,
        data_eels: data_eel.salt,
        data_eelc: data_eel.ct,
      },
      data_ene: {
        data_enei: data_ene.iv,
        data_enes: data_ene.salt,
        data_enec: data_ene.ct,
      },
      data_etd: {
        data_etdi: data_etd.iv,
        data_etds: data_etd.salt,
        data_etdc: data_etd.ct,
      },
      data_end: {
        data_endi: data_end.iv,
        data_ends: data_end.salt,
        data_endc: data_end.ct,
      },
    };

    try {
      const response = await serverRequest("post", "rl/eliminar_doc", dataForm);
      console.log("response", response);
      if (response.data === "done") {
        setSuccess(true);
        setShowAlert(true);
        setShowText(t("thanks.doc"));
        get_reference_data();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
        borderRadius: 2,
        px: 4,
        py: 3,
      }}
    >
      {showAlert && (
        <AlertModal
          text={showText}
          onClose={handleCloseAlert}
          thanks={showSuccess}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Box
              sx={{
                color: "text.opacity",
                fontSize: 16,
                fontFamily: "Gilroy-SemiBold",
                mb: 1,
              }}
              component={"label"}
            >
              {t("labels.labelReferenceType")}
            </Box>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="">
                <Typography sx={{ color: "rgba(33, 33, 33, 0.4)" }}>
                  {t("placeholders.ReferenceType")}
                </Typography>
              </MenuItem>

              {LengthMap[cardlength] < 5 ? (
                <MenuItem value={"carta"}>
                  {t("LaborReferencePage.Letter")}
                </MenuItem>
              ) : (
                age === "carta" && setAge("") // Reset the reference type when condition is false
              )}
              {LengthMap[questionlength] < 5 ? (
                <MenuItem value={"cuestionario"}>
                  {t("LaborReferencePage.Questionnaire")}
                </MenuItem>
              ) : (
                age === "cuestionario" && setAge("") // Reset the reference type when condition is false
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileUploadInput onFileSelection={handleFileSelection} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <ThankYouMessageModal
              showThankYouModal={showThankYouModal}
              onupload={handleSubmit}
              handleclosed={handleclosed}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <hr style={{ border: "dashed 1px rgba(27, 32, 44, 0.2)" }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 1 }}>
              <Box sx={{ width: "88%" }}>
                <Box
                  sx={{
                    color: "text.opacity",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    mb: 1,
                  }}
                  component={"label"}
                >
                  {t("LaborReferencePage.Cards")}
                </Box>
              </Box>
              <Box sx={{ width: "10%", ml: "2%", textAlign: "right" }}>
                <Box
                  sx={{
                    color: "primary.light",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    mb: 1,
                  }}
                  component={"label"}
                >
                  {LengthMap[cardlength]}
                </Box>
              </Box>
            </Box>
            <Box>
              {card1.map((card, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "88%",
                      border: "solid 1px #E6E8F0",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      p: 1.6,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "neutral.900",
                        fontSize: 16,
                        fontFamily: "Gilroy-Medium",
                        wordBreak: "break-all",
                      }}
                    >
                      {card.nombre}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%", ml: 2, textAlign: "right" }}>
                    <Button
                      onClick={() => deleteHandler(card.nombre, "cartas")}
                      sx={{
                        border: "solid 1px rgba(27, 32, 44, 0.2)",
                        borderRadius: 50,
                        p: "7px",
                        height: "40px",
                        width: "40px",
                        mx: "auto",
                        minWidth: "40px",
                      }}
                    >
                      <DeleteIcon sx={{ color: "#FE5A3E" }} />
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
            {/***************************** Questionnaires ********************************************/}
            <Box sx={{ display: "flex", flexDirection: "row", mb: 1, mt: 2 }}>
              <Box sx={{ width: "88%" }}>
                <Box
                  sx={{
                    color: "text.opacity",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    mb: 1,
                  }}
                  component={"label"}
                >
                  {t("LaborReferencePage.Questionnaires")}
                </Box>
              </Box>
              <Box sx={{ width: "10%", ml: "2%", textAlign: "right" }}>
                <Box
                  sx={{
                    color: "primary.light",
                    fontSize: 16,
                    fontFamily: "Gilroy-SemiBold",
                    mb: 1,
                  }}
                  component={"label"}
                >
                  {LengthMap[questionlength]}
                </Box>
              </Box>
            </Box>
            <Box>
              {question1.map((card, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "88%",
                      border: "solid 1px #E6E8F0",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      p: 1.6,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "neutral.900",
                        fontSize: 16,
                        fontFamily: "Gilroy-Medium",
                        wordBreak: "break-all",
                      }}
                    >
                      {card.nombre}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%", ml: 2, textAlign: "right" }}>
                    <Button
                      onClick={() =>
                        deleteHandler(card.nombre, "cuestionarios")
                      }
                      sx={{
                        border: "solid 1px rgba(27, 32, 44, 0.2)",
                        borderRadius: 50,
                        p: "7px",
                        height: "40px",
                        width: "40px",
                        mx: "auto",
                        minWidth: "40px",
                      }}
                    >
                      <DeleteIcon sx={{ color: "#FE5A3E" }} />
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LaborReferencesForm;
