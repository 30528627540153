import { Grid, Typography, Box, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import logoImage from "../../assets/images/configure-main.svg";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import mailImage from "../../assets/images/mailIcon.png";
import phoneImage from "../../assets/images/phoneIcon.png";
// ** MUI Imports
import { MenuItem, Select, styled } from '@mui/material';
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import SixDigitCode from "../@common/Input/SixDigitCode/SixDigitCode";
import verifyImage from "../../assets/images/verify-icon.svg";
import { Link } from "react-router-dom";
import VerifyAccountModal from "./VerifyAccountModal";
import AlertModal from "../@common/Modal/AlertModal";
import { getCookiesList } from "../../utils/cookiesHelper";
import { serverRequest2 } from "../../utils/requestHelper";
import { encryptData } from "../../utils/encryptionHelper";
import { isValidOTP } from "../../utils/inputValidation";
import { useTranslation } from "react-i18next";
import { options_phone } from "../../utils/optionsEdo";
// import flagSprite from "../../utils/CountryFlags/css/svg/sprite.css-13197d08.svg";

const CustomSelect = styled(Select)({
  '&& .MuiSelect-select': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',  // Use 'clip' to avoid ellipsis
    display: 'flex',
    alignItems: 'center',
  }
});

function Configuration() {
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);
  const [cuurentTwoFAFlag, setCurrentTwoFAFlag] = useState(false);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [viaEmail, setViaEmail] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccesstext, setShowSuccesstext] = useState("");
  const [showVerifyTwoFAModel, setShowVerifyTwoFAModel] = useState(false);
  // validation
  const [otpError, setOTPError] = useState(false);

  const activateHandler = async () => {
    if (phoneNumber.length >= 10) {
      await setupTwoFA();
    }
    else {
      setError(t("UserSignup.redNumber"))
    }
  };

  const closeModels = async () => {
    setShowSecondStep(false);
    setShowVerifyTwoFAModel(false);
    await checkTwoFADetails();
  };
  const setupTwoFA = async () => {
    // send otp to the phone number
    // Validate phone number
    const isValidPhoneNumber = /^(\+52)?\d{10}$/;
    if (!isValidPhoneNumber.test(phoneNumber)) {
      return setShowtext(t("UserSignup.contactInputTelRedText"));
    }

    if (cuurentTwoFAFlag != isTwoFAEnabled) {
      const data_eel = encryptData(cookieList[3], 500);
      const dataForm = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
        token: cookieList[4],
        phone_number: prefix + phoneNumber,
        two_fa_flag: isTwoFAEnabled,
        is_email_channel: viaEmail,
      };

      const response = await serverRequest2(
        "post",
        "perfil/2fa_setup",
        dataForm
      );
      if (
        typeof response.data == "object" &&
        response.data["2fa_verify_required"] == true
      ) {
        setShowSecondStep(true);
      } else if (response.data == "Unable to send otp to your email") {
        setShowtext(t("2FAConfiguration.enable"));
      } else {
        setShowtext("Check you connection");
      }
    } else {
      setShowtext(
        `${t("2FAConfiguration.two")} ${isTwoFAEnabled
          ? t("2FAConfiguration.textButton2")
          : t("2FAConfiguration.textButton1")
        }`
      );
    }
  };
  const backHandler = () => {
    setShowSecondStep(false);
  };

  const checkTwoFADetails = async () => {
    if (
      cookieList.length > 0 &&
      cookieList[3] != undefined &&
      cookieList[4] != undefined
    ) {
      const data_eel = encryptData(cookieList[3], 500);
      var dataForm_ = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
        token: cookieList[4],
      };
      const response = await serverRequest2("post", "check-twofa", dataForm_);
      if (
        typeof response.data == "object" &&
        JSON.stringify(response.data).length > 0
      ) {
        if (response.data["two_factor_enabled"] == true) {
          setIsTwoFAEnabled(true);
          setCurrentTwoFAFlag(true);
        }
        if (response.data["two_factor_enabled"] == false) {
          setIsTwoFAEnabled(false);
          setCurrentTwoFAFlag(false);
        }
        if (response.data["phone_number"]) {
          // setPhoneNumber(response.data["phone_number"]);
        }
        if (response.data["email"]) {
          setUserEmail(response.data["email"]);
        }
      } else {
        console.log("check connection");
      }
    }
  };

  const verifyTwoFA = async () => {
    if (isValidOTP(otp)) {
      setOTPError(false);
      const data_eel = encryptData(cookieList[3], 500);
      const dataForm = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
        token: cookieList[4],
        phone_number: prefix + phoneNumber,
        two_fa_flag: isTwoFAEnabled,
        otp: otp,
        via_email: viaEmail,
      };

      const response = await serverRequest2(
        "post",
        "perfil/2fa_verify",
        dataForm
      );
      const allowed_messages = [
        "Two Factor set too true",
        "Two Factor set too false",
      ];
      if (allowed_messages.includes(response.data["message"])) {
        setShowVerifyTwoFAModel(true);
        setOtp("");
      } else if (response.data["message"] == "Invalid OTP") {
        setShowtext(t("2FAConfiguration.popUpWrongInvalidOtp"));
      } else {
        setShowtext(t("thanks.check"));
      }
    } else {
      setOTPError(true);
      // setShowtext('OTP must be of 6 digits')
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowtext("");
  };

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {
    checkTwoFADetails();
  }, [cookieList]);

  useEffect(() => {
    if (showtext != "") {
      setShowAlert(true);
    }
  }, [showtext]);

  useEffect(() => {
    if (showSuccesstext != "") {
      setShowSuccessModal(true);
    }
  }, [showSuccesstext]);

  const [prefix, setPrefix] = useState('+52');

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    // Ensure maximum 10 characters for phone number (excluding prefix)
    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };


  const [error, setError] = useState('');

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: 1,
              boxShadow: 2,
              paddingX: 4,
              paddingY: 2,
              mt: 4,
            }}
          >
            <Box sx={{ display: showSecondStep == true ? "none" : "block" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(27, 32, 44, 0.6)",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  component={"h5"}
                >
                  {t("2FAConfiguration.header")}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 2 }}
              >
                <img
                  src={logoImage}
                  height="96px"
                  alt="Two-step verification"
                />
              </Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <LabelInputLeftIcon
                    mailIcon={mailImage}
                    inputValue={cookieList[3]}
                    readOnly={true}
                    type="text"
                    label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                    name="email"
                    id="email"
                    placeholder="Enter your email address"
                  />
                </Box>
                <div style={{ position: "relative" }}>
                  <Box sx={{ display: "flex", mt: 2, }}>
                    <LabelInputLeftIcon
                      isPhone={true}
                      prefix={prefix}
                      setPrefix={setPrefix}
                      mailIcon={phoneImage}
                      type="number"
                      label={t("labels.inputLabelNumber")}
                      name="mobile_number"
                      id="mobile_number"
                      placeholder={t("placeholders.number")}
                      inputValue={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={10} // Considering the prefix length
                    />
                  </Box>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>

                <Box sx={{ display: "flex", mt: 2, justifyContent: "center" }}>
                  <FormGroup row>
                    <FormControlLabel
                      sx={{ flexDirection: "row-reverse" }}
                      control={
                        <Switch
                          checked={isTwoFAEnabled}
                          onChange={(event) =>
                            setIsTwoFAEnabled(event.target.checked)
                          }
                          color="success"
                        />
                      }
                      label={t("labels.label2Fa")}
                    />
                  </FormGroup>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginY: 4,
                    }}
                  >
                    <Button
                      onClick={activateHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 8 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                    >
                      {isTwoFAEnabled
                        ? t("2FAConfiguration.textButton2")
                        : t("2FAConfiguration.textButton1")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: showSecondStep == true ? "block" : "none" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(27, 32, 44, 0.6)",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                  component={"h5"}
                >
                  {t("2FAConfiguration.tittle")}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 0 }}>
                  <Box
                    sx={{ display: "flex", my: 4, justifyContent: "center" }}
                  >
                    <img
                      src={verifyImage}
                      width="100px"
                      height="100px"
                      alt="Verify Your Account"
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", mb: 1, justifyContent: "center" }}
                  >
                    <SixDigitCode
                      onCodeChange={(value) => setOtp(value)}
                      otp={otp}
                    />
                  </Box>
                  {otpError && (
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flex: "row",
                        mt: 2,
                        mb: 2,
                      }}
                      variant="body2"
                      color="error"
                    >
                      {t("2FAConfiguration.redTextOTP")}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Link
                      style={{
                        color: "#4165F6",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        textDecoration: "none",
                      }}
                      onClick={setupTwoFA}
                    >
                      {t("2FAConfiguration.Resend OTP")}
                    </Link>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={verifyTwoFA}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 11 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                      }}
                      variant="contained"
                    >
                      {t("2FAConfiguration.textButton3")}
                    </Button>
                    <VerifyAccountModal
                      showVerifyTwoFAModel={showVerifyTwoFAModel}
                      closeVerifyTwoFAModel={closeModels}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={backHandler}
                      variant="text"
                      sx={{
                        color: "rgba(27, 32, 44, 0.6)",
                        fontSize: 16,
                        fontFamily: "Gilroy-Medium",
                        textDecoration: "underline",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("2FAConfiguration.textlinkGoBack")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
      </Grid >
    </>
  );
}

export default Configuration;
