
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { encryptData } from "../../../utils/encryptionHelper";
import { getCookiesList, removeCookie } from "../../../utils/cookiesHelper";
import { serverRequest } from "../../../utils/requestHelper";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
export default function GuestNavigation() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cookieList, setCookieList] = useState([]);
  var userInfo = Cookies.get("guestUserInfo");
  var userEmail = userInfo.split(",")[3];
  const { t } = useTranslation();
  useEffect(() => {
    const userCookiesList = getCookiesList("guestUserInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutGuest = async () => {
    if (cookieList.length > 0 && cookieList[3] != undefined) {
      const data_eel = encryptData(cookieList[3], 500);
      var dataForm_ = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
      };
      const response = await serverRequest(
        "post",
        "logout_invitado",
        dataForm_
      );
      if (response.data) {
        removeCookie("guestUserInfo");
        navigate("/Login");
      }
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
        onClick={handleClick}
      >
        <Tooltip title="Account settings">
          <IconButton disableRipple sx={{ p: 0 }}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                backgroundColor: "#fff",
                border: "solid 2px #f4f4f4",
              }}
            >
              <PersonIcon sx={{ color: "primary.light" }} />
            </Avatar>

            <IconButton
              sx={{
                p: 0,
              }}
            >
              <ArrowDropDownIcon
                sx={{ color: "rgba(27, 32, 44, 0.5) !important" }}
              />
            </IconButton>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar fontSize="small" />
          </ListItemIcon>
          <Box>
            {userEmail}
            <Typography
              sx={{
                fontSize: 12,
                fontFamily: "Gilroy-Medium",
                color: "text.opacity",
              }}
            >
              {t("GuestDashboard.headerGuestText")}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={logoutGuest}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("GuestDashboard.headerLogOutText")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
