import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Padding } from "@mui/icons-material";

const DocumentPreview = (props) => {
  const { viewDoc, setViewDoc, file } = props;
  const [certificateContent, setCertificateContent] = useState("");
  const [error, setError] = useState("");

  // Function to convert binary DER data to PEM format
  const convertDERtoPEM = (binaryData) => {
    const base64Cert = btoa(
      String.fromCharCode.apply(null, new Uint8Array(binaryData))
    );
    const pemCert = `-----BEGIN CERTIFICATE-----\n${base64Cert
      .match(/.{1,64}/g)
      .join("\n")}\n-----END CERTIFICATE-----`;
    return pemCert;
  };

  useEffect(() => {
    const fetchCerFile = async () => {
      try {
        const response = await fetch(file?.preview);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch the file. Status: ${response.status}`
          );
        }

        if (file?.response?.document_name === "cer") {
          // Read the file as binary data (ArrayBuffer)
          const arrayBuffer = await response.arrayBuffer();

          // Convert the binary data into Base64
          const base64String = arrayBufferToBase64(arrayBuffer);

          // Optionally, you can wrap the base64 string in PEM format:
          const formattedCert = `-----BEGIN CERTIFICATE-----\n${base64String}\n-----END CERTIFICATE-----`;

          setCertificateContent(formattedCert);
        } else {
          // If it's not a certificate, fetch the file as a regular text file
          const text = await response.text();
          setCertificateContent(text);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    if (file?.preview) {
      fetchCerFile();
    }
  }, [file?.preview, file?.response?.document_name]);

  // Function to convert ArrayBuffer to Base64
  const arrayBufferToBase64 = (buffer) => {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return window.btoa(binary); // This will convert binary to base64
  };

  const handleClose = () => {
    setViewDoc(false);
  };

  const style = {
    "*::-webkit-scrollbar": {
      width: "6px !important",
      borderRadius: "3px !important",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "#E4EFEF !important",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#4165f1d4 !important",
      borderRadius: "3px !important",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: file?.type === "application/pdf" ? "75%" : "800px",
    maxWidth: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  return (
    <Modal
      open={viewDoc}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "fixed",
            top: "-9px",
            right: "-13px",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              background: "#FE5A3E !important",
              color: "#fff",
            }}
          >
            <CloseIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </Box>
        {file ? (
          <Box>
            {file.type === "application/pdf" ? (
              <Box
                sx={{
                  height: "70vh",
                }}
              >
                <iframe
                  src={file.preview}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius: "12px",
                  }}
                  title={`preview-${file.name}`}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  height: "350px",
                  padding: 2,
                }}
              >
                {(file?.response?.document_name === "cer" ||
                  file?.response?.document_name === "key") &&
                certificateContent ? (
                  <Typography
                    variant="body1"
                    component="pre"
                    sx={{
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    {certificateContent}
                  </Typography>
                ) : (
                  <img
                    src={file.preview}
                    alt={`preview-${file.name}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "12px",
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Typography variant="h6" component="h2">
            No document to preview
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default DocumentPreview;
