import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InnerTable from "./InnerTable";
import AccordainTableMain from "./AccordainTableMain";
import AccordainHeader from "./AccordainHeader";
import {
  sectionAData,
  sectionBData,
  sectionCData,
  sectionEData,
  sectionDData,
  sectionFData,
  sectionGData,
} from "../../../utils/reportsConstants";
import ReportHeader from "../../@common/ReportHeader/ReportHeader";
import { useEffect, useState } from "react";
import { getCookiesList } from "../../../utils/cookiesHelper";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AccordainTable({ personalization, reportData, sub_dicts }) {
  const { t } = useTranslation();
  const TipoType =
    reportData["tipo_cupon"] === "SB" || reportData["tipo_cupon"] === "S.B.";
  const sections = TipoType
    ? [
        {
          title: "A",
          data: sectionAData,
          headerTitle: t("reportpage.sections.main_titles.A"),
        },
        {
          title: "B",
          data: sectionBData,
          headerTitle: t("reportpage.sections.main_titles.B"),
        },
        {
          title: "C",
          data: sectionCData,
          headerTitle: t("reportpage.sections.main_titles.C"),
        },
        {
          title: "E",
          data: sectionEData,
          headerTitle: t("reportpage.sections.main_titles.E"),
        },
        {
          title: "F",
          data: sectionFData,
          headerTitle: t("reportpage.sections.main_titles.F"),
        },
        {
          title: "G",
          data: sectionGData,
          headerTitle: t("reportpage.sections.main_titles.G"),
        },
        // { title: "H", data: "", headerTitle: "Resumen Legal" },
      ]
    : [
        {
          title: "A",
          data: sectionAData,
          headerTitle: t("reportpage.sections.main_titles.A"),
        },
        {
          title: "B",
          data: sectionBData,
          headerTitle: t("reportpage.sections.main_titles.B"),
        },
        {
          title: "C",
          data: sectionCData,
          headerTitle: t("reportpage.sections.main_titles.C"),
        },
        {
          title: "D",
          data: sectionDData,
          headerTitle: t("reportpage.sections.main_titles.D"),
        },
        {
          title: "E",
          data: sectionEData,
          headerTitle: t("reportpage.sections.main_titles.E"),
        },
        {
          title: "F",
          data: sectionFData,
          headerTitle: t("reportpage.sections.main_titles.F"),
        },
        {
          title: "G",
          data: sectionGData,
          headerTitle: t("reportpage.sections.main_titles.G"),
        },
        // { title: "H", data: "", headerTitle: "Resumen Legal" },
      ];
  const navigate = useNavigate();
  const userInfo = Cookies.get("guestUserInfo");

  const pathhandlerDasboard = () => {
    if (userInfo) {
      navigate("/GuestDashboard");
    } else {
      navigate("/dashboard");
    }
  };

  const [banorte, setbanorte] = useState(false);
  const [expanded, setExpanded] = useState(
    sections.map((section) => section.title)
  ); // Set the first section as expanded
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList[13] == "BANORTE") {
      setbanorte(true);
    }
  });

  return (
    <div>
      {banorte && (
        <Accordion
          key="G" // Render "G" section first
          expanded={expanded.includes("G")}
          onChange={handleChange("G")}
          sx={{
            mb: 2,
            "& .MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "45px !important",
            },
            "& .MuiButtonBase-root.MuiAccordionSummary-root": {
              p: { xs: "0px 5px !important", md: "0px 16px !important" },
            },
          }}
        >
          <TableContainer
            sx={{
              backgroundColor: "primary.main",
              m: 0,
              borderStartEndRadius: "4px",
              borderBottomRightRadius: 0,
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            component={Paper}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "primary.main",
                    backgroundColor: "#ffffff",
                    borderRadius: 50,
                    fontSize: { xs: "small", md: "1.5rem" },
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                my: "0px !important",
                "& .MuiAccordionSummary-content": {
                  m: "0px !important",
                  minHeight: "45px !important",
                },
              }}
            >
              <AccordainHeader
                tableRowTitle="G"
                tableRowheaderTitle="Final Recommendation"
              />
            </AccordionSummary>
          </TableContainer>
          <AccordionDetails
            sx={{ display: "flex", flexDirection: "row", p: 0 }}
          >
            <AccordainTableMain
              personalization={personalization}
              data={reportData}
              sectionAData={sectionGData}
              sub_dicts={sub_dicts}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {sections.map((section) => {
        if (section.title === "F") {
          return (
            <div>
              <Accordion
                key={section.title}
                expanded={expanded.includes(section.title)}
                onChange={handleChange(section.title)}
                sx={{
                  mb: 2,
                  "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "45px !important",
                  },
                  "& .MuiButtonBase-root.MuiAccordionSummary-root": {
                    p: { xs: "0px 5px !important", md: "0px 16px !important" },
                  },
                }}
              >
                <TableContainer
                  sx={{
                    backgroundColor: "primary.main",
                    m: 0,
                    borderStartEndRadius: "4px",
                    borderBottomRightRadius: 0,
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                  component={Paper}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: "primary.main",
                          backgroundColor: "#ffffff",
                          borderRadius: 50,
                          fontSize: { xs: "small", md: "1.5rem" },
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      my: "0px !important",
                      "& .MuiAccordionSummary-content": {
                        m: "0px !important",
                        minHeight: "45px !important",
                      },
                    }}
                  >
                    <AccordainHeader
                      tableRowTitle="F"
                      tableRowheaderTitle={t(
                        "reportpage.sections.main_titles.F"
                      )}
                    />
                  </AccordionSummary>
                </TableContainer>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "row", p: 0 }}
                >
                  <AccordainTableMain
                    personalization={personalization}
                    data={reportData}
                    sectionAData={sectionFData}
                    sub_dicts={sub_dicts}
                  />
                </AccordionDetails>
              </Accordion>
              {banorte === false && (
                <>
                  <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
                    <Table sx={{ borderRadius: 0 }} aria-label="simple table">
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            width: "100%",
                            p: 0,
                            border: "solid 1px #f4f4f4",
                          }}
                        >
                          <ReportHeader
                            imageShow={false}
                            Age={reportData["Edad"]}
                            Curp={reportData["CURP"]}
                            RFC={reportData["RFC"]}
                            Name={reportData["nombre"]}
                            Date={reportData["Fecha_Consulta"]}
                            NSS={reportData["NSS"]}
                            Total_Homonimos={reportData["Total_Homonimos"]}
                            Face_ID_Exists={reportData["Face_ID_Exists"]}
                            Face_ID_Status={reportData["Face_ID_Status"]}
                            Face_ID_Color={reportData["Face_ID_Color"]}
                          />
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </>
              )}
            </div>
          );
        }

        if (!banorte || section.title !== "G") {
          return (
            <Accordion
              key={section.title}
              expanded={expanded.includes(section.title)}
              onChange={handleChange(section.title)}
              sx={{
                mb: 2,
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px !important",
                },
                "& .MuiButtonBase-root.MuiAccordionSummary-root": {
                  p: { xs: "0px 5px !important", md: "0px 16px !important" },
                },
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "primary.main",
                  m: 0,
                  borderStartEndRadius: "4px",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: 0,
                }}
                component={Paper}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "primary.main",
                        backgroundColor: "#ffffff",
                        borderRadius: 50,
                        fontSize: { xs: "small", md: "1.5rem" },
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    my: "0px !important",
                    "& .MuiAccordionSummary-content": {
                      m: "0px !important",
                      minHeight: "45px !important",
                    },
                  }}
                >
                  <AccordainHeader
                    tableRowTitle={section.title}
                    tableRowheaderTitle={section.headerTitle}
                    reportData={reportData}
                  />
                </AccordionSummary>
              </TableContainer>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "row", p: 0 }}
              >
                <AccordainTableMain
                  personalization={personalization}
                  data={reportData}
                  sectionAData={section.data}
                  sub_dicts={sub_dicts}
                />
              </AccordionDetails>
            </Accordion>
          );
        }
        return null;
      })}
    </div>
  );
}
