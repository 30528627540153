import {
  IconArrowDown,
  IconBolt,
  IconBrandGoogle,
  IconPlayerStop,
  IconRepeat,
  IconSend,
} from '@tabler/icons-react';
import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { ChatContext } from './ChatContext';
import { Grid, Typography, Box } from '@mui/material'

export const ChatInput = ({
  onSend,
  onRegenerate,
  onScrollDownClick,
  stopConversationRef,
  textareaRef,
  showScrollDownButton,
}) => {
  const { t } = useTranslation('chat');

  const { chatLog, setChatLog } = useContext(ChatContext);

  const [content, setContent] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const [variables, setVariables] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegenHovered, setIsRegenHovered] = useState(false);
  const [isSendHovered, setIsSendHovered] = useState(false);
  const [isScrollHovered, setIsScrollHovered] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setContent(value);
  };

  const handleSend = () => {
    if (chatLog.messageIsStreaming) {
      return;
    }

    if (!content) {
      alert(t('Please enter a message'));
      return;
    }

    onSend({ 
      role: 'user',
      thread_x: chatLog.selectedConversation.messages[chatLog.selectedConversation.messages.length - 1].thread_x,
      thread_y: chatLog.selectedConversation.messages[chatLog.selectedConversation.messages.length - 1].thread_y + 1,
      content });
    setContent('');

    if (window.innerWidth < 640 && textareaRef && textareaRef.current) {
      textareaRef.current.blur();
    }
  };

  const handleStopConversation = () => {
    stopConversationRef.current = true;
    setTimeout(() => {
      stopConversationRef.current = false;
    }, 1000);
  };

  const isMobile = () => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return mobileRegex.test(userAgent);
  };

  const parseVariables = (content) => {
    const regex = /{{(.*?)}}/g;
    const foundVariables = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
      foundVariables.push(match[1]);
    }

    return foundVariables;
  };

  const handleSubmit = (updatedVariables) => {
    const newContent = content?.replace(/{{(.*?)}}/g, (match, variable) => {
      const index = variables.indexOf(variable);
      return updatedVariables[index];
    });

    setContent(newContent);

    if (textareaRef && textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
      textareaRef.current.style.overflow = `${textareaRef?.current?.scrollHeight > 400 ? 'auto' : 'hidden'
        }`;
    }
  }, [content]);

  return (
    <Box
    sx={{ 
      position: 'absolute', 
      bottom: 0, 
      left: 0, 
      right: 0,
    }}
    >
      <div
        style={{
          margin: '16px 8px', // '4' for mt, '2' for mx; adjust for md and lg
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          marginBottom: '8px', // '2' for last, adjust for md
          maxWidth: '-webkit-fill-available', // for lg
          margin: 'auto', // for lg
          padding: '10px'
        }}
      >
        {chatLog.messageIsStreaming && (
          <button
            style={{
              margin: '0 auto 0', // '3' for mb, adjust for md
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '5px',
              border: '1px solid',
              borderColor: 'neutral-200', // replace with 'neutral-600' for dark mode
              backgroundColor: isRegenHovered ? 'black' : 'white', // replace with '#343541' for dark mode
              padding: '8px 16px', // '2' for py, '4' for px
              color: isRegenHovered ? 'white' : 'black', // replace with 'white' for dark mode
              cursor: 'pointer',
            }}
            onMouseEnter={() => setIsRegenHovered(true)}
            onMouseLeave={() => setIsRegenHovered(false)}
            onClick={handleStopConversation}
          >
            <IconPlayerStop size={16} /> {t('Stop Generating')}
          </button>
        )}

        {!chatLog.messageIsStreaming &&
          chatLog.selectedConversation &&
          chatLog.selectedConversation.messages.length > 0 && (
            <button
              style={{
                margin: '0 auto 0', // '3' for mb, adjust for md
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                boxShadow: '-5px -5px 6px 0px rgba(0, 0, 0, 0.3)',
                borderRadius: '5px',
                border: '1px solid',
                borderColor: 'neutral-200', // replace with 'neutral-600' for dark mode
                backgroundColor: isRegenHovered ? 'black' : 'white', // replace with '#343541' for dark mode
                padding: '8px 16px', // '2' for py, '4' for px
                color: isRegenHovered ? 'white' : 'black', // replace with 'white' for dark mode
                cursor: 'pointer',
              }}
              onMouseEnter={() => setIsRegenHovered(true)}
              onMouseLeave={() => setIsRegenHovered(false)}
              onClick={onRegenerate}
            >
              <IconRepeat size={16} /> {t('Regenerate response')}
            </button>
          )}
        <div style={{
          position: 'relative',
          margin: '0',
          display: 'flex',
          width: '100%',
          flexGrow: 1,
          flexDirection: 'column',
          borderRadius: '0.375rem',
          border: '1px solid',
          borderColor: '#0000001A', // Approximation of 'black/10'
          backgroundColor: '#FFFFFF',
          boxShadow: '-5px -5px 6px 0px rgba(0, 0, 0, 0.3)',
          color: '#FFFFFF', // For dark mode
        }}>
          <textarea
            ref={textareaRef}
            style={{
              margin: '5px',
              width: '100%',
              resize: 'none',
              border: 'none',
              backgroundColor: 'transparent',
              padding: '0.5rem 2rem 0.5rem 2.5rem',
              color: '#000000', // For light mode
              resize: 'none',
              bottom: `${textareaRef?.current?.scrollHeight}px`,
              maxHeight: '400px',
              overflow: `${textareaRef.current && textareaRef.current.scrollHeight > 400
                ? 'auto'
                : 'hidden'
                }`,
              outline: 'none',
            }}
            placeholder={
              t('Type a message or type "/" to select a prompt...') || ''
            }
            value={content}
            rows={1}
            onCompositionStart={() => setIsTyping(true)}
            onCompositionEnd={() => setIsTyping(false)}
            onChange={handleChange}
          />

          <button
            style={{
              position: 'absolute',
              right: '0.5rem',
              top: '0.5rem',
              borderRadius: '0.25rem',
              padding: '0.25rem',
              backgroundColor: isSendHovered ? 'black' : 'white', // replace with '#343541' for dark mode
              color: isSendHovered ? 'white' : 'black', // replace with 'white' for dark mode
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
            onMouseEnter={() => setIsSendHovered(true)}
            onMouseLeave={() => setIsSendHovered(false)}
            onClick={handleSend}
          >
            {chatLog.messageIsStreaming ? (
              <div style={{
                height: '1rem',
                width: '1rem',
                animation: 'spin 1s linear infinite',
                borderRadius: '50%',
                borderTop: '2px solid',
                borderColor: '#2D3748', // Approximation of 'neutral-800'
                opacity: 0.6,
              }}></div>
            ) : (
              <IconSend size={18} />
            )}
          </button>

          {showScrollDownButton && (
            <div style={{
              position: 'absolute',
              bottom: '5rem',
              right: '2rem',
            }}>
              <button
                style={{
                  display: 'flex',
                  height: '1.75rem',
                  width: '1.75rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: isScrollHovered ? 'black' : 'white', // replace with '#343541' for dark mode
                  color: isScrollHovered ? 'white' : 'black', // replace with 'white' for dark mode
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', // Approximation of 'shadow-md'
                  outline: 'none',
                  cursor: 'pointer',
                }}
                onMouseEnter={() => setIsScrollHovered(true)}
                onMouseLeave={() => setIsScrollHovered(false)}
                onClick={onScrollDownClick}
              >
                <IconArrowDown size={18} />
              </button>
            </div>
          )}

        </div>
      </div>
    </Box>

  );
};
