import { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography"
import FormHelperText from '@mui/material/FormHelperText';

export default function SimpleDropDown(props) {

    const [age, setAge] = useState('');

    const {
        data = [
          { value: "NJ", name: "NJ" },
          { value: "NY", name: "NY" },
          { value: "PA", name: "PA" },
        ],
        onChange,
        containerStyle,
        inputValue,
        label = "label",
        errorMsg = "errorMsg",
        flag = false,
        name,
        value = "",
        firstName = value,
        id = "",
        star,
        disabled,
        hideFirst = false,
        disableFirst = false,
        icon = "expand_more",
        onBlur,
        placeholder,
        subLabel
      } = props;

      const handleChange = (event) => {
        const selectedValue = event.target.value;
        setAge(selectedValue);
        onChange(selectedValue); // Pass the selected value to the onChange function prop
      };

    return (
        <Box sx={{ display:"flex", flexDirection:"column",width:"100%"}}>
            <FormControl fullWidth>
                <Box sx={{ color: "text.opacity", fontSize: 16, fontFamily: "Gilroy-Medium", mb: 1,pt:1 }} component={"label"}>{label}</Box>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    displayEmpty
                    onChange={handleChange}
                    sx={{
                        "& .MuiSelect-select":{
                            p:1.8
                        }
                    }}
                >
                    <MenuItem value="">
                        <Typography sx={{ color: "rgba(33, 33, 33, 0.4)" }}>{firstName}</Typography>
                    </MenuItem>

                    <MenuItem value='Letter'>Letter</MenuItem>
                    <MenuItem value='Questionnaire'>Questionnaire</MenuItem>
                </Select>
                {flag
                ?
                <FormHelperText sx={{mt:"5px",fontSize:"12px",color:"red",ml:0}}>{errorMsg}</FormHelperText>
                :null
                }
            </FormControl>
        </Box>
    );
}