import "./FaceID.css";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AlertModal from "../@common/Modal/AlertModal";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import pdfImage from "../../assets/images/pdfImage.png";
import deleteFile from "../../assets/images/deletefile.svg";
import previewFile from "../../assets/images/previewFile.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";

const FaceID = ({}) => {
  const { t } = useTranslation();
  const search_params = new URLSearchParams(window.location.search);
  const email = search_params.get("email");
  const phone = search_params.get("phone");
  const vlid = search_params.get("vlid");
  const geo_location = search_params.get("geo_location");
  const faceid = search_params.get("faceid");

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState("document");
  const [documentData, setDocumentData] = useState(null);
  const [documentDataType, setDocumentDataType] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [selfieType, setSelfieType] = useState(null);
  const [selfiePreview, setSelfiePreview] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [closedModal, setClosedModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [taskStatus, setTaskStatus] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const videoRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleClosed = () => {
    setClosedModal(true);
    setShowtext(false);

    if (taskStatus) {
      if (geo_location) {
        navigate("/GeoLocation" + window.location.search);
      } else {
        window.open("https://searchlook.com.mx", "_self");
      }
    }
  };

  // Add useEffect to handle camera initialization
  useEffect(() => {
    let stream = null;

    const initializeCamera = async () => {
      if (isCameraActive && videoRef.current) {
        try {
          const constraints = {
            video: {
              facingMode: currentStep === "document" ? "environment" : "user",
              width: { ideal: 1280 },
              height: { ideal: 720 },
            },
            audio: false,
          };

          stream = await navigator.mediaDevices.getUserMedia(constraints);
          videoRef.current.srcObject = stream;

          await new Promise((resolve) => {
            videoRef.current.onloadedmetadata = () => resolve();
          });

          await videoRef.current.play();

          window.scrollTo(0, document.body.scrollHeight);

          setCameraError(null);
        } catch (err) {
          console.error("Camera initialization error:", err);
          const errorMessage = err.message || "Failed to access camera";
          setCameraError(errorMessage);
          setShowAlert(true);
          setTaskStatus(false);
          setShowtext(
            isMobile
              ? t("consultField.noCameraMobileError")
              : t("consultField.noCameraDesktopError")
          );
          setIsCameraActive(false);
        }
      }
    };

    const checkIfMobile = () => {
      const mobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
      setIsMobile(mobile);
    };

    initializeCamera();
    checkIfMobile();

    // Cleanup function
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isCameraActive, currentStep]);

  const dataURLtoBlob = (dataUrl) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  // Simplify startCamera function
  const startCamera = async (isBackCamera = false) => {
    try {
      await stopCamera();
      setIsCameraActive(true);
    } catch (err) {
      console.error("Failed to start camera:", err);
      setCameraError("Failed to start camera");
      setShowAlert(true);
      setTaskStatus(false);
      setShowtext("Failed to start camera");
    }
  };

  // Update stopCamera function
  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraActive(false);
    setCameraError(null);
  };

  const captureImage = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement("canvas"); // now this will work
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext("2d").drawImage(videoRef.current, 0, 0);

    const dataUrl = canvas.toDataURL("image/jpeg");

    if (currentStep === "document") {
      setDocumentData(dataUrl); // updated
      setDocumentDataType("image/jpeg");
      setDocumentPreview(URL.createObjectURL(dataURLtoBlob(dataUrl)));
    } else {
      setSelfie(dataUrl);
      setSelfieType("image/jpeg");
      setSelfiePreview(URL.createObjectURL(dataURLtoBlob(dataUrl)));
    }

    stopCamera();
  };

  // Document handling functions
  const handleDocumentUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setDocumentData(file); // updated
    setDocumentDataType(file.type);

    if (file.type === "application/pdf") {
      // For PDFs, create object URL
      setDocumentPreview(URL.createObjectURL(file));
    } else {
      // For images, use FileReader as before
      const reader = new FileReader();
      reader.onload = (e) => {
        setDocumentPreview(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  // Add these new handlers for drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("drag-over");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove("drag-over");

    const file = e.dataTransfer.files[0];
    if (
      file &&
      (file.type.startsWith("image/") || file.type === "application/pdf")
    ) {
      setDocumentData(file); // updated
      setDocumentDataType(file.type);

      if (file.type === "application/pdf") {
        setDocumentPreview(URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setDocumentPreview(URL.createObjectURL(file));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const verifyUser = async (data) => {
    setSpinnerVisibility(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL2 + "face_rec",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.similarity > 0.5) {
        setTaskStatus(true);
        setShowtext("Successful");
      } else {
        setTaskStatus(false);
        setShowtext("Mismatch");
      }
    } catch (err) {
      console.error(err);
      setTaskStatus(false);
      let err_msg = err.response.data.error || "Encountered an error!";
      if (err_msg.includes("Face not found in Documents")) {
        err_msg = t("consultField.noFaceInDocument");
      } else if (err_msg.includes("Face not found in Selfie")) {
        err_msg = t("consultField.noFaceInSelfie");
      }
      setShowtext(err_msg);
    } finally {
      setSpinnerVisibility(false);
      setShowAlert(true);
    }
  };

  const nextStep = () => {
    if (geo_location) {
      navigate("/GeoLocation" + window.location.search);
    } else {
      window.open("https://searchlook.com.mx", "_self");
    }
  };

  // Step rendering functions
  const renderDocumentStep = () => (
    <div className="kyc-step">
      <h2>Subir Documento de Identificación</h2>
      <div className="instructions">
        <h3>Documentos Aceptables:</h3>
        <ul>
          <li>Pasaporte válido</li>
          <li>Documento de identidad emitido por el gobierno</li>
          <li>Licencia de conducir</li>
        </ul>
        <h3>Requisitos:</h3>
        <ul>
          <li>El documento debe ser válido y no estar vencido</li>
          <li>Todas las esquinas deben ser visibles</li>
          <li>El texto debe ser claramente legible</li>
          <li>Sin reflejos ni sombras</li>
        </ul>
      </div>

      {!isCameraActive && !documentPreview && (
        <div
          className="document-upload-area"
          onClick={() => fileInputRef.current?.click()}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="upload-icon">📄</div>
          <p>Arrastra y suelta tu documento aquí</p>
          <p>- o -</p>
          <p>Haz clic para elegir un archivo</p>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,.pdf"
            onChange={handleDocumentUpload}
            style={{ display: "none" }}
          />
          {/Mobi|Android|iPhone/i.test(navigator.userAgent) && (
            <button
              className="button camera-button"
              onClick={(e) => {
                e.stopPropagation();
                startCamera(true);
              }}
            >
              📸 Tomar Foto
            </button>
          )}
        </div>
      )}

      {isCameraActive && (
        <div className="camera-view">
          <video ref={videoRef} autoPlay playsInline muted />
          {cameraError && <div className="camera-error">{cameraError}</div>}
          <div className="button-group">
            <Button
              variant="contained"
              onClick={captureImage}
              aria-label="Capture"
              sx={{
                mt: 2,
                width: "50px",
                height: "60px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "0 auto",
                backgroundColor: "primary.main",
              }}
            >
              <CameraAltIcon />
            </Button>
            <Button
              variant="contained"
              onClick={stopCamera}
              aria-label="Capture"
              sx={{
                mt: 2,
                width: "50px",
                height: "60px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "0 auto",
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      )}

      {documentPreview && (
        <div className="preview-section">
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  boxShadow: "4px 6px 6px #FF8D001A",
                  border: "1px solid #FFF8F0",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "6px",
                  marginTop: "10px",
                  marginBottom: "5px",
                  width: { sm: "85px", xs: "68px" },
                }}
              >
                <Box
                  component="img"
                  src={
                    documentData?.type === "application/pdf"
                      ? pdfImage
                      : documentPreview
                  } // Replace with your PDF icon
                  alt={documentData.name}
                  sx={{
                    width: "100%",
                    height: { sm: "65px", xs: "65px" },
                    // borderRadius: "12px",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    wordBreak: "break-all",
                    color: "rgba(33, 33, 33, 0.4)",
                    fontSize: { sm: 12, xs: 10 },
                    fontFamily: "Gilroy-Medium",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  {documentData.name}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "-5px",
                  }}
                >
                  <Box
                    component="img"
                    src={deleteFile} // Replace with your PDF icon
                    alt={documentData.name}
                    sx={{
                      width: { sm: "18px", xs: "16px" },
                      height: { sm: "18px", xs: "16px" },
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setDocumentData(null); // updated
                      setDocumentDataType(null);
                      setDocumentPreview(null);
                      // Clean up object URL if it exists
                      if (documentData?.type === "application/pdf") {
                        // updated
                        URL.revokeObjectURL(documentPreview);
                      }
                    }}
                  />
                  <Box
                    component="img"
                    src={previewFile} // Replace with your PDF icon
                    alt={documentData.name}
                    sx={{
                      width: { sm: "18px", xs: "16px" },
                      height: { sm: "18px", xs: "16px" },
                      display: "block",
                      marginTop: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(documentPreview, "_blank")}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  borderRadius: "6px",
                  px: 8,
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: 18,
                  fontFamily: "Gilroy-Medium",
                  textTransform: "capitalize",
                  width: "32px",
                }}
                onClick={() => setCurrentStep("selfie")}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "secondary.main",
            textAlign: "center",
            whiteSpace: "nowrap",
            borderRadius: "6px",
            px: 8,
            color: "#fff",
            textDecoration: "none",
            fontSize: 18,
            fontFamily: "Gilroy-Medium",
            textTransform: "capitalize",
            width: "32px",
          }}
          onClick={nextStep}
        >
          {t("consultField.skip")}
        </Button>
      </Box>
    </div>
  );

  const renderSelfieStep = () => (
    <div className="kyc-step">
      <h2>Tomar una Selfie</h2>
      <div className="instructions">
        <h3>Requisitos para la Selfie:</h3>
        <ul>
          <li>Asegúrate de que tu rostro sea claramente visible</li>
          <li>Mira directamente a la cámara</li>
          <li>Quítate las gafas de sol o sombreros</li>
          <li>Utiliza buena iluminación</li>
        </ul>
      </div>

      {!isCameraActive && !selfie && (
        <div className="start-camera-container">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              textAlign: "center",
              whiteSpace: "nowrap",
              borderRadius: "6px",
              px: 8,
              color: "#fff",
              textDecoration: "none",
              fontSize: 18,
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize",
            }}
            onClick={() => {
              startCamera(false);
            }}
          >
            Iniciar Cámara
          </Button>
        </div>
      )}

      {isCameraActive && (
        <div className="selfie-container">
          <div className="selfie-frame">
            <div className="camera-view">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                style={{ transform: "scaleX(-1)" }}
              />
              {cameraError && <div className="camera-error">{cameraError}</div>}
            </div>
          </div>
          <Button
            variant="contained"
            onClick={captureImage}
            aria-label="Capture"
            sx={{
              mt: 2,
              width: "50px",
              height: "60px",
              borderRadius: "50%",
              border: "2px solid #fff",
              margin: "0 auto",
              backgroundColor: "primary.main",
              position: "relative",
              bottom: "90px",
            }}
          >
            <CameraAltIcon />
          </Button>
        </div>
      )}

      {selfie && (
        <div className="preview-section">
          <div className="image-preview-section">
            <img src={selfie} alt="Vista previa de selfie" />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                textAlign: "center",
                whiteSpace: "nowrap",
                borderRadius: "6px",
                px: 8,
                color: "#fff",
                textDecoration: "none",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
                width: "32px",
              }}
              onClick={() => {
                setSelfie(null);
                setSelfieType(null);
              }}
            >
              Rehacer
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                textAlign: "center",
                whiteSpace: "nowrap",
                borderRadius: "6px",
                px: 8,
                color: "#fff",
                textDecoration: "none",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
                width: "32px",
              }}
              onClick={() =>
                verifyUser({
                  documentDataType,
                  documentData,
                  selfieType,
                  selfie,
                  vlid,
                })
              }
            >
              Enviar
            </Button>
          </Box>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "secondary.main",
            textAlign: "center",
            whiteSpace: "nowrap",
            borderRadius: "6px",
            px: 8,
            color: "#fff",
            textDecoration: "none",
            fontSize: 18,
            fontFamily: "Gilroy-Medium",
            textTransform: "capitalize",
            width: "32px",
          }}
          onClick={nextStep}
        >
          {t("consultField.skip")}
        </Button>
      </Box>
    </div>
  );

  return (
    <>
      {showAlert && (
        <AlertModal
          closedModal={closedModal}
          thanks={taskStatus}
          closedModalFunction={handleClosed}
          text={showtext}
          onClose={handleClosed}
        />
      )}
      <CustomSpinner visible={spinnerVisibility} />
      <div className="kyc-container">
        {currentStep === "document" ? renderDocumentStep() : renderSelfieStep()}
      </div>
    </>
  );
};

export default FaceID;
