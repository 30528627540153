import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReportInnerTable from './ReportInnerTable';
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import EreportSubInnerTable from './EreportSubInnerTable';
import { PictureAsPdf } from '@mui/icons-material';

const AccordianEreportTableMain = ({
    data,
    sectionAData,
    sub_dicts,
    is_download = true,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState([]);
    const [filterDataPartner, setFilterDataPartner] = useState([]);
    const StyledTableCell = styled(TableCell)(({ theme }) =>
    ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "transparent",
            color: theme.palette.common.black,
        },

        backgroundColor: "transparent",
        borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
    })
    );
    const StyledTableRow = styled(TableRow)(({ theme }) =>
    ({
        borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
    })
    );

    useEffect(() => {
        Object.entries(sectionAData).forEach(([key, section]) => {
            if (section.titlealphabet === "C" && section.inner_table?.parent_key === "lawsuits") {
                const parentKey = section.inner_table.parent_key;
                const dataKey = section.inner_table.data;

                if (parentKey && data.reportData[parentKey]?.[dataKey]) {
                    const originalArray = data.reportData[parentKey][dataKey];
                    const splitCompanyName = data.reportData.razon_social?.split(",") || [];
                    const mainCompanyName = splitCompanyName[0]?.trim() || "";

                    // Filter the array
                    const filteredArray = originalArray.filter(item => {
                        const containsCompanyInActor = item.Actor?.includes(mainCompanyName);
                        const containsCompanyInDemandado = item.Demandado?.includes(mainCompanyName);
                        return (containsCompanyInActor || containsCompanyInDemandado);
                    });

                    // Store filtered data for this specific section using 'sub' as key
                    setFilterData(prev => ({
                        ...prev,
                        [section.sub]: filteredArray.length === 0 ? [] : filteredArray
                    }));
                } else {
                    // If conditions not met, store empty array for this section
                    setFilterData(prev => ({
                        ...prev,
                        [section.sub]: []
                    }));
                }
            }
            else if (section.titlealphabet === "D" && section.inner_table?.parent_key === "lawsuits") {
                const parentKey = section.inner_table.parent_key;
                const dataKey = section.inner_table.data;

                if (parentKey && data.reportData[parentKey]?.[dataKey]) {
                    const originalArray = data.reportData[parentKey][dataKey];
                    const splitCompanyName = data.reportData.razon_social?.split(",") || [];
                    const mainCompanyName = splitCompanyName[0]?.trim() || "";

                    // Filter the array
                    const filteredArray = originalArray.filter(item => {
                        const containsCompanyInActor = item.Actor?.includes(mainCompanyName);
                        const containsCompanyInDemandado = item.Demandado?.includes(mainCompanyName);
                        return !(containsCompanyInActor || containsCompanyInDemandado);
                    });

                    // Store filtered data for this specific section using 'sub' as key
                    setFilterDataPartner(prev => ({
                        ...prev,
                        [section.sub]: filteredArray.length === 0 ? [] : filteredArray
                    }));
                } else {
                    // If conditions not met, store empty array for this section
                    setFilterDataPartner(prev => ({
                        ...prev,
                        [section.sub]: []
                    }));
                }
            }
        });
    }, [sectionAData, data]);



    return (
        <>
            <TableContainer sx={{ borderRadius: 0, boxShadow: 0 }} component={Paper}>
                <Table sx={{ borderRadius: 0 }} aria-label="simple table">
                    <TableBody sx={{ borderRadius: 0 }}>
                        {Object.entries(sectionAData).map(([key, section], rowIndex) =>
                        (

                            <>
                                <TableRow sx={{
                                    borderRadius: 0,
                                    "& td, & th": {
                                        border: 0,
                                        borderRadius: 0,
                                        minWidth: "50px",
                                        lineHeight: "normal",
                                        // maxWidth: "130px",
                                        // wordBreak: "break-all",
                                        wordWrap: "break-word",  // Breaks long words only when necessary
                                        whiteSpace: "normal",    // Allows the text to wrap normally
                                        "*::-webkit-scrollbar": {
                                            width: "6px !important", // Adjust as needed
                                            borderRadius: "3px !important",
                                            height: "6px"
                                        },

                                        "*::-webkit-scrollbar-track": {
                                            backgroundColor: "#E4EFEF !important", // Adjust as needed
                                        },

                                        "*::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#4165f1d4 !important", // Adjust as neede
                                            borderRadius: "3px !important", // Rounded corners
                                        },
                                    },
                                    backgroundColor:
                                        rowIndex % 2 === 1 ? "#f5faff" : "inherit",
                                }}>

                                    {
                                        section?.child_key ?
                                            <EreportSubInnerTable
                                                section={section}
                                                data={data} />
                                            :
                                            <TableCell
                                                colSpan={2}
                                                sx={{
                                                    width: "60%",
                                                    borderBottom: "5px solid #ffffff !important",
                                                    borderRadius: 0,
                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                }}
                                            >
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                    <Typography
                                                        sx={{
                                                            color: "primary.main",
                                                            width: "50%",
                                                            fontSize: { xs: "1vw", md: 14 },
                                                            fontFamily: "Gilroy-Medium",
                                                        }}
                                                    >
                                                        {t(`${section.title}`)}
                                                    </Typography>



                                                    <Typography
                                                        sx={{
                                                            color: section?.inner_table?.status === true && section.inner_table.parent_key === "bdc" && section?.titlealphabet !== "E" ? (
                                                                (!data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] || data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 0) ? "#000000" : "primary.main")
                                                                : section?.inner_table?.status === true && section.inner_table.data === "licitaciones" ?
                                                                    ((data.reportData[section.inner_table.data]?.length === 0 || !data.reportData[section.inner_table.data]) ? "#000000" : "primary.main")
                                                                    : (section?.inner_table?.data === "SOCIOS" &&
                                                                        data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 1 &&
                                                                        Object.values(data.reportData[section.inner_table.parent_key][section.inner_table.data][0]).every(
                                                                            value => value === "" || value === null || value === undefined
                                                                        )) ? "#000000"
                                                                        : (section?.inner_table?.data === "profeco") ?
                                                                            ((!data.reportData[section.inner_table.data] || Object.keys(data.reportData[section.inner_table.data]).length === 0) ?
                                                                                "#000000" : "primary.main")
                                                                            : (section.titlealphabet === "C" && section.inner_table?.parent_key === "lawsuits") ?
                                                                                (filterData[section.sub] || []).length === 0 ? "#000000" : "primary.main"
                                                                                : (["siem", "psm", "siger", "condusef", "pyvs"].includes(section?.inner_table?.parent_key)) ?
                                                                                    ((!data.reportData[section.inner_table.parent_key] ||
                                                                                        data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 0) ? "#000000" : "primary.main")
                                                                                    : (section.titlealphabet === "D" && section.inner_table?.parent_key === "lawsuits") ?
                                                                                        (filterDataPartner[section.sub] || []).length === 0 ? "#000000" : "primary.main"
                                                                                        : (section?.inner_table?.parent_key === "repse" && section?.inner_table?.data === "servicios") ?
                                                                                            (
                                                                                                // Check if the data is valid and not empty, otherwise return "No"
                                                                                                data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] &&
                                                                                                    Array.isArray(data.reportData[section.inner_table.parent_key][section.inner_table.data]) ?
                                                                                                    (
                                                                                                        data.reportData[section.inner_table.parent_key][section.inner_table.data].length === 0 ? "#000000" : "primary.main"
                                                                                                    ) : "#000000"
                                                                                            )
                                                                                            : (data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 0 ||
                                                                                                data.reportData[section.inner_table.data]?.length === 0) ? "#000000"
                                                                                                : section?.parent_key === "pyvs" ? (data?.reportData?.[section?.parent_key]?.[section?.format] ? "primary.main" : "#000000")
                                                                                                    : (["cnbv", "rupc"].includes(section.format)) ?
                                                                                                        (data.reportData[section.format] ? "primary.main" : "#000000")
                                                                                                        : section?.inner_table?.status
                                                                                                            ? (section?.inner_table?.status === true && section.inner_table.parent_key === "bdc" &&
                                                                                                                (
                                                                                                                    (!data.reportData[section.format] && !data.reportData[section.parent_key]?.[section.format]) ||
                                                                                                                    (Array.isArray(data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]) &&
                                                                                                                        data.reportData[section.inner_table.parent_key][section.inner_table.data].length === 0)
                                                                                                                )
                                                                                                                ? "#000000"  // If both conditions fail, set color to black
                                                                                                                : "primary.main")  // If conditions pass, use the primary color
                                                                                                            : "#000000",
                                                            width: "50%",
                                                            fontSize: { xs: "1vw", md: 12 },
                                                            fontFamily: "Gilroy-Medium",
                                                            textAlign: "center",
                                                            whiteSpace: "pre-wrap",
                                                        }}
                                                    >

                                                        {
                                                            // Table E check with inner table and parent key bdc
                                                            section?.inner_table?.status === true && section.inner_table.parent_key === "bdc" && section?.titlealphabet !== "E" ? (
                                                                data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 0 ? "No"
                                                                    : data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length || "No"
                                                            )
                                                                //check for format
                                                                : section?.inner_table?.status === true && section.inner_table.data === "licitaciones" ? (
                                                                    // Check if licitaciones data exists and has length greater than 0
                                                                    data.reportData[section.inner_table.data]?.length === 0 ? "No" : data.reportData[section.inner_table.data]?.length || "No"
                                                                )
                                                                    // Check for repse and servicios
                                                                    : (section?.inner_table?.parent_key === "repse" && section?.inner_table?.data === "servicios") ? (
                                                                        data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] &&
                                                                            Array.isArray(data.reportData[section.inner_table.parent_key][section.inner_table.data]) ? (
                                                                            data.reportData[section.inner_table.parent_key][section.inner_table.data].length === 0 ? "No" : ""
                                                                        ) : "No"
                                                                    )
                                                                        // Check for pyvs parent key
                                                                        : section?.parent_key === "pyvs" ? (
                                                                            data?.reportData?.[section?.parent_key]?.[section?.format] ? "Si" : "No"
                                                                        )
                                                                            // Check for cnbv or rupc formats
                                                                            : ["cnbv", "rupc"].includes(section.format) ? (
                                                                                data.reportData[section.format]?.length > 0 ? "Si" : "No"
                                                                            )
                                                                                // Table E check with inner table and parent key bdc
                                                                                : section?.inner_table?.status === true && section.inner_table.parent_key === "bdc" && section?.titlealphabet === "E" &&
                                                                                    (!data.reportData[section.format] && !data.reportData[section.parent_key]?.[section.format]) ? (
                                                                                    t("Emodule.Information not available")
                                                                                )
                                                                                    // Lawsuits for titlealphabet C and sub data
                                                                                    : section.titlealphabet === "C" && section.inner_table?.parent_key === "lawsuits" ? (
                                                                                        (filterData[section.sub] || []).length === 0 ? "No" : (filterData[section.sub] || []).length
                                                                                    )
                                                                                        // Lawsuits for titlealphabet D and sub data
                                                                                        : section.titlealphabet === "D" && section.inner_table?.parent_key === "lawsuits" ? (
                                                                                            (filterDataPartner[section.sub] || []).length === 0 ? "No" : (filterDataPartner[section.sub] || []).length
                                                                                        )
                                                                                            // Profeco entity counting logic
                                                                                            : section?.inner_table?.status && section?.inner_table?.data === "profeco" ? (
                                                                                                Object.keys(data.reportData.profeco || {}).reduce((acc, year) => {
                                                                                                    if (year === "_id" || year === "update") return acc;
                                                                                                    const entityCount = Object.keys(data.reportData.profeco[year] || {}).length;
                                                                                                    return acc + entityCount;
                                                                                                }, 0) === 0 ? "No" : Object.keys(data.reportData.profeco || {}).reduce((acc, year) => {
                                                                                                    if (year === "_id" || year === "update") return acc;
                                                                                                    return acc + Object.keys(data.reportData.profeco[year] || {}).length;
                                                                                                }, 0)
                                                                                            )
                                                                                                // repse data validation
                                                                                                : section.format === "repse" ? (
                                                                                                    Object.values(data.reportData[section.format] || {}).every(
                                                                                                        value => value === "" || value === 0 || value === null || value === undefined
                                                                                                    ) ? "No" : ""
                                                                                                )
                                                                                                    // Handling for other formats and data conditions
                                                                                                    : (section?.inner_table?.status === false && (!data.reportData[section.format] && !data.reportData[section.parent_key]?.[section.format])) ? (
                                                                                                        t("Emodule.Information not available")
                                                                                                    )
                                                                                                        // Special case for sat-related data
                                                                                                        : ["sat_69_B", "sat_not_located", "sat_canceled", "sat_69_B_socios", "sat_not_located_socios", "sat_canceled_socios", "servidores_sancionados"].includes(section?.inner_table?.data) ? (
                                                                                                            data.reportData[section.inner_table.data]?.length === 0 ? "No" : data.reportData[section.inner_table.data]?.length || "No"
                                                                                                        )
                                                                                                            // Siger and SOCIOS data validation
                                                                                                            : section?.inner_table?.parent_key === "siger" && section?.inner_table?.data === "SOCIOS" ? (
                                                                                                                data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length ?
                                                                                                                    data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length : t("Emodule.No SOCIOS")
                                                                                                            )
                                                                                                                // siem, psm, siger, condusef, pyvs parent keys check
                                                                                                                : ["siem", "psm", "siger", "condusef", "pyvs"].includes(section?.inner_table?.parent_key) ? (
                                                                                                                    data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 0 ? "No" : data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length || "No"
                                                                                                                )
                                                                                                                    // Fallback for parent_key or format
                                                                                                                    : section?.parent_key ? (
                                                                                                                        data.reportData[section.parent_key]?.[section.format] || ""
                                                                                                                    ) : section.format ? (
                                                                                                                        data.reportData[section.format] || ""
                                                                                                                    ) : ""
                                                        }

                                                    </Typography>
                                                </Box>


                                                {/* Inner table for profeco case  and all other than repse */}
                                                {
                                                    section?.inner_table?.status && section?.inner_table?.data === "pep" &&
                                                        Array.isArray(data.reportData[section?.inner_table?.data]) &&
                                                        data.reportData[section.inner_table.data].length > 0 ?
                                                        (
                                                            <>
                                                                {data.reportData[section.inner_table.data].map(
                                                                    (item, index) => {
                                                                        // Loop through the keys in each object and render key-value pairs
                                                                        const key = Object.keys(item)[0];  // Get the key (e.g., "FINANCIERAS ÚLTIMOS 12 MESES")
                                                                        const value = item[key];           // Get the value (e.g., 2)
                                                                        return (
                                                                            <Box key={index} sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#000000",
                                                                                        width: "50%",
                                                                                        fontSize: { xs: "1vw", md: 12 },
                                                                                        fontFamily: "Gilroy-Medium",
                                                                                        paddingLeft: "12px"
                                                                                    }}
                                                                                >
                                                                                    {key}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#000000",
                                                                                        width: "50%",
                                                                                        fontSize: { xs: "1vw", md: 12 },
                                                                                        fontFamily: "Gilroy-Medium",
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        )
                                                        : section?.inner_table?.status && section?.titlealphabet === "E" &&
                                                            section.inner_table.parent_key === "bdc" &&
                                                            Array.isArray(data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]) &&
                                                            data.reportData[section.inner_table.parent_key][section.inner_table.data].length > 0 ? (
                                                            <>
                                                                {data.reportData[section.inner_table.parent_key][section.inner_table.data].map(
                                                                    (item, index) => {
                                                                        // Loop through the keys in each object and render key-value pairs
                                                                        const key = Object.keys(item)[0];  // Get the key (e.g., "FINANCIERAS ÚLTIMOS 12 MESES")
                                                                        const value = item[key];           // Get the value (e.g., 2)
                                                                        return (
                                                                            <Box key={index} sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#000000",
                                                                                        width: "50%",
                                                                                        fontSize: { xs: "1vw", md: 12 },
                                                                                        fontFamily: "Gilroy-Medium",
                                                                                        paddingLeft: "12px"
                                                                                    }}
                                                                                >
                                                                                    {key}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#000000",
                                                                                        width: "50%",
                                                                                        fontSize: { xs: "1vw", md: 12 },
                                                                                        fontFamily: "Gilroy-Medium",
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        )
                                                            : section?.inner_table?.status && section.inner_table.parent_key !== "bdc" && section?.titlealphabet !== "E" ?
                                                                (
                                                                    section.titlealphabet === "C" && section.inner_table?.parent_key === "lawsuits" ?
                                                                        (
                                                                            filterData[section.sub]?.length > 0 &&
                                                                            (
                                                                                <ReportInnerTable
                                                                                    tableData={filterData[section.sub] || []}
                                                                                    dataType={section.inner_table["type"]}
                                                                                    isDownload={is_download}
                                                                                    hideButton={true}
                                                                                />
                                                                            )
                                                                        )

                                                                        : section.titlealphabet === "D" && section.inner_table?.parent_key === "lawsuits" ?
                                                                            (
                                                                                filterDataPartner[section.sub]?.length > 0 && (
                                                                                    <ReportInnerTable
                                                                                        tableData={filterDataPartner[section.sub] || []}
                                                                                        dataType={section.inner_table["type"]}
                                                                                        isDownload={is_download}
                                                                                        hideButton={true}
                                                                                    />
                                                                                )
                                                                            )
                                                                            : section?.inner_table?.data === "profeco" ?
                                                                                <>
                                                                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                        <TableContainer sx={{ borderRadius: 1 }}>

                                                                                            {
                                                                                                data.reportData.profeco && Object.keys(data.reportData.profeco).length > 0 && (
                                                                                                    <Table
                                                                                                        sx={{
                                                                                                            borderRadius: 2,
                                                                                                            border: "0px solid rgba(167, 169, 169, 0.16)",
                                                                                                        }}
                                                                                                        aria-label="customized table"
                                                                                                    >
                                                                                                        <TableHead>
                                                                                                            <TableRow>
                                                                                                                <StyledTableCell
                                                                                                                    sx={{
                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                        width: "inherit",
                                                                                                                    }}
                                                                                                                    align="left"
                                                                                                                    component="th"
                                                                                                                >
                                                                                                                    {t("Emodule.table_headers.Year")}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell
                                                                                                                    sx={{
                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                        width: "inherit",
                                                                                                                    }}
                                                                                                                    align="center"
                                                                                                                    component="th"
                                                                                                                >
                                                                                                                    {t("Emodule.table_headers.Entity")}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell
                                                                                                                    sx={{
                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                        width: "inherit",
                                                                                                                    }}
                                                                                                                    align="center"
                                                                                                                    component="th"
                                                                                                                >
                                                                                                                    {t("Emodule.table_headers.Complaints Received")}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell
                                                                                                                    sx={{
                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                        width: "inherit",
                                                                                                                    }}
                                                                                                                    align="center"
                                                                                                                    component="th"
                                                                                                                >
                                                                                                                    {t("Emodule.table_headers.Complaints Concluded")}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell
                                                                                                                    sx={{
                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                        width: "inherit",
                                                                                                                    }}
                                                                                                                    align="center"
                                                                                                                    component="th"
                                                                                                                >
                                                                                                                    {t("Emodule.table_headers.Reasons")}
                                                                                                                </StyledTableCell>
                                                                                                            </TableRow>
                                                                                                        </TableHead>
                                                                                                        <TableBody sx={{ backgroundColor: "transparent" }}>
                                                                                                            {data.reportData.profeco && Object.keys(data.reportData.profeco).length > 0 ? ( // Check if there is data excluding "_id" and "update"
                                                                                                                Object.keys(data.reportData.profeco).map((year) => {
                                                                                                                    // Skip the "_id" and "update" keys
                                                                                                                    if (year === "_id" || year === "update") return null;

                                                                                                                    const entities = data.reportData.profeco[year];

                                                                                                                    return Object.keys(entities).map((entity) => {
                                                                                                                        const entityData = entities[entity];

                                                                                                                        return (
                                                                                                                            <TableRow key={`${year}-${entity}`}>
                                                                                                                                <StyledTableCell
                                                                                                                                    sx={{
                                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                                        width: "inherit",
                                                                                                                                    }}
                                                                                                                                    align="left"
                                                                                                                                >
                                                                                                                                    {year ? year : "---"}
                                                                                                                                </StyledTableCell>
                                                                                                                                <StyledTableCell
                                                                                                                                    sx={{
                                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                                        width: "inherit",
                                                                                                                                    }}
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    {entity ? entity : "---"}
                                                                                                                                </StyledTableCell>
                                                                                                                                <StyledTableCell
                                                                                                                                    sx={{
                                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                                        width: "inherit",
                                                                                                                                    }}
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    {entityData.quejas_totales_recibidas ? entityData.quejas_totales_recibidas : "---"}
                                                                                                                                </StyledTableCell>
                                                                                                                                <StyledTableCell
                                                                                                                                    sx={{
                                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                                        width: "inherit",
                                                                                                                                    }}
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    {entityData.quejas_concluidas ? entityData.quejas_concluidas : "---"}
                                                                                                                                </StyledTableCell>
                                                                                                                                <StyledTableCell
                                                                                                                                    sx={{
                                                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                                                        width: "inherit",
                                                                                                                                    }}
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    {entityData.principales_motivos_de_reclamacion_a_nivel_nacional ? entityData.principales_motivos_de_reclamacion_a_nivel_nacional : "---"}
                                                                                                                                </StyledTableCell>
                                                                                                                            </TableRow>
                                                                                                                        );
                                                                                                                    });
                                                                                                                })
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </TableBody>
                                                                                                    </Table>
                                                                                                )
                                                                                            }

                                                                                        </TableContainer>
                                                                                    </Box>
                                                                                </>
                                                                                : (
                                                                                    (data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length > 0 ||
                                                                                        data.reportData[section.inner_table.data]?.length > 0) &&
                                                                                    (
                                                                                        (section?.inner_table?.parent_key === "siger" && section?.inner_table?.data === "SOCIOS") ?
                                                                                            (data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length === 1 &&
                                                                                                data.reportData[section.inner_table.parent_key][section.inner_table.data][0] &&
                                                                                                Object.values(data.reportData[section.inner_table.parent_key][section.inner_table.data][0]).every(
                                                                                                    value => value === "" || value === null || value === undefined
                                                                                                ) ? ("")
                                                                                                : (
                                                                                                    <ReportInnerTable
                                                                                                        tableData={
                                                                                                            section?.inner_table?.parent_key
                                                                                                                ? data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] || []
                                                                                                                : data.reportData[section.inner_table.data] || []
                                                                                                        }

                                                                                                        dataType={section.inner_table["type"]}
                                                                                                        isDownload={is_download}
                                                                                                        hideButton={true}
                                                                                                    />
                                                                                                ))
                                                                                            : (
                                                                                                (
                                                                                                    <ReportInnerTable
                                                                                                        tableData={
                                                                                                            section?.inner_table?.parent_key
                                                                                                                ? data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] || []
                                                                                                                : data.reportData[section.inner_table.data] || []
                                                                                                        }

                                                                                                        dataType={section.inner_table["type"]}
                                                                                                        isDownload={is_download}
                                                                                                        hideButton={true}
                                                                                                    />
                                                                                                )
                                                                                            )
                                                                                    )
                                                                                )
                                                                )
                                                                : section?.inner_table?.status && section.inner_table.parent_key === "bdc" && section?.titlealphabet !== "E" ?
                                                                    (
                                                                        (Array.isArray(data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]) &&
                                                                            data.reportData[section.inner_table.parent_key]?.[section.inner_table.data]?.length > 0) ||
                                                                        (Array.isArray(data.reportData[section.inner_table.data]) &&
                                                                            data.reportData[section.inner_table.data]?.length > 0)
                                                                    ) &&
                                                                    (
                                                                        <ReportInnerTable
                                                                            tableData={
                                                                                section?.inner_table?.parent_key
                                                                                    ? data.reportData[section.inner_table.parent_key]?.[section.inner_table.data] || []
                                                                                    : data.reportData[section.inner_table.data] || []
                                                                            }

                                                                            dataType={section.inner_table["type"]}
                                                                            isDownload={is_download}
                                                                            hideButton={true}
                                                                        />
                                                                    )
                                                                    : null
                                                }


                                                {/*Inner table for  repse ,cnbv ,pycs , rupc ====objects */}
                                                {
                                                    section.format === "repse" ?
                                                        <>
                                                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                <TableContainer sx={{ borderRadius: 1 }}>
                                                                    {
                                                                        data.reportData.repse ?
                                                                            <Table
                                                                                sx={{
                                                                                    borderRadius: 2,
                                                                                    border: "0px solid rgba(167, 169, 169, 0.16)",
                                                                                }}
                                                                                aria-label="customized table"
                                                                            >
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Current")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Validity")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.RegistrationDate")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Folio")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Registration")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Registration Notice")}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                            component="th"
                                                                                        >
                                                                                            {t("Emodule.table_headers.Status")}
                                                                                        </StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody sx={{ backgroundColorColor: "transparent" }}>
                                                                                    <StyledTableRow
                                                                                        sx={{ backgroundColor: "transparent" }}
                                                                                    >
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            {new Date(data.reportData.repse.vigencia) > new Date() ? "Sí" : "No"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.vigencia ? data.reportData.repse.vigencia : "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.fecha_aviso_registro ? data.reportData.repse.fecha_aviso_registro : "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.folio ? data.reportData.repse.folio : "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.registro ? data.reportData.repse.registro : "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.aviso_registro ? data.reportData.repse.aviso_registro : "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="center"
                                                                                        >
                                                                                            {data.reportData.repse.entidad ? data.reportData.repse.entidad : "---"}
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                            : ""}
                                                                </TableContainer>
                                                            </Box>
                                                        </>
                                                        : section.format === "cnbv" ?
                                                            <>
                                                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                    <TableContainer sx={{ borderRadius: 1 }}>
                                                                        {
                                                                            data.reportData[section.format] && (
                                                                                <Table
                                                                                    sx={{
                                                                                        borderRadius: 2,
                                                                                        border: "0px solid rgba(167, 169, 169, 0.16)",
                                                                                    }}
                                                                                    aria-label="customized table"
                                                                                >
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                                component="th"
                                                                                            >
                                                                                                {t("Emodule.table_headers.Sector")}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="center"
                                                                                                component="th"
                                                                                            >
                                                                                                {t("Emodule.table_headers.CASFIM")}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="center"
                                                                                                component="th"
                                                                                            >
                                                                                                {t("Emodule.table_headers.Controlling Entity")}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="center"
                                                                                                component="th"
                                                                                            >
                                                                                                {t("Emodule.table_headers.PDF")}
                                                                                            </StyledTableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <StyledTableRow sx={{ backgroundColor: "transparent" }}>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            {data.reportData[section.format]?.sector || "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            {data.reportData[section.format]?.casfim || "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            {data.reportData[section.format]?.entidad_controladora || "---"}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            sx={{
                                                                                                p: { xs: "5px", md: "10px 16px" },
                                                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                width: "inherit",
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            {
                                                                                                data.reportData[section.format]?.exportar_a_pdf &&
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    color="primary"
                                                                                                    onClick={() => {
                                                                                                        const link = document.createElement('a');
                                                                                                        link.href = data.reportData[section.format]?.exportar_a_pdf;
                                                                                                        link.download = 'report.pdf';
                                                                                                        link.click();
                                                                                                    }}
                                                                                                    sx={{
                                                                                                        cursor: 'pointer',
                                                                                                        border: "none !important",
                                                                                                        padding: " 12px 20px !important"
                                                                                                    }}
                                                                                                >
                                                                                                    <PictureAsPdf sx={{ fontSize: 20 }} />
                                                                                                </Button>
                                                                                            }

                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                </Table>
                                                                            )
                                                                        }

                                                                    </TableContainer>
                                                                </Box>
                                                            </>
                                                            : section.parent_key === "pyvs" ?
                                                                <>
                                                                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                        <TableContainer sx={{ borderRadius: 1 }}>
                                                                            {
                                                                                data?.reportData?.[section?.parent_key]?.[section?.format] && (
                                                                                    <Table
                                                                                        sx={{
                                                                                            borderRadius: 2,
                                                                                            border: "0px solid rgba(167, 169, 169, 0.16)",
                                                                                        }}
                                                                                        aria-label="customized table"
                                                                                    >
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="left"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Law violated")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.File")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Fine")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Disqualification period")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Cause")}
                                                                                                </StyledTableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <StyledTableRow sx={{ backgroundColor: "transparent" }}>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data?.reportData?.[section?.parent_key]?.[section?.format]?.ley_infringida || "---"}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data?.reportData?.[section?.parent_key]?.[section?.format]?.expediente || "---"}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data?.reportData?.[section?.parent_key]?.[section?.format]?.monto_de_la_multa || "---"}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data?.reportData?.[section?.parent_key]?.[section?.format]?.periodo_de_inhabilitacion || "---"}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: {
                                                                                                        xs: data?.reportData?.[section?.parent_key]?.[section?.format]?.ficha_tecnica_del_infractor?.causa.length > 100 ? "0.8vw" : "1vw",
                                                                                                        md: data?.reportData?.[section?.parent_key]?.[section?.format]?.ficha_tecnica_del_infractor?.causa.length > 100 ? "0.675rem" : "0.875rem"
                                                                                                    },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data?.reportData?.[section?.parent_key]?.[section?.format]?.ficha_tecnica_del_infractor?.causa || "---"}
                                                                                            </StyledTableCell>
                                                                                        </StyledTableRow>
                                                                                    </Table>
                                                                                )}
                                                                        </TableContainer>
                                                                    </Box>
                                                                </>
                                                                : section.format === "rupc" ?
                                                                    <>
                                                                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                            <TableContainer sx={{ borderRadius: 1 }}>
                                                                                {
                                                                                    data.reportData[section.format]?.nombre_de_la_empresa &&
                                                                                    <Table
                                                                                        sx={{
                                                                                            borderRadius: 2,
                                                                                            border: "0px solid rgba(167, 169, 169, 0.16)",
                                                                                        }}
                                                                                        aria-label="customized table"
                                                                                    >
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="left"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Sector and Business")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.CompanyType")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Contracts")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Contracts and % Compliance LAASSP")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.Contracts and % Compliance LOPSRM")}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell
                                                                                                    sx={{
                                                                                                        p: { xs: "5px", md: "10px 16px" },
                                                                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                        width: "inherit",
                                                                                                    }}
                                                                                                    align="center"
                                                                                                    component="th"
                                                                                                >
                                                                                                    {t("Emodule.table_headers.RegistrationDate")}
                                                                                                </StyledTableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <StyledTableRow sx={{ backgroundColor: "transparent" }}>
                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.sector + " — " + data.reportData[section.format]?.giro || "---"}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.estratificacion || "---"}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.contratos || "---"}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.contratos_evaluados_laassp + " / " + data.reportData[section.format]?.grado_de_cumplimiento_laassp + "%" || "---"}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.contratos_evaluados_lopsrm + " / " + data.reportData[section.format]?.grado_de_cumplimiento_lopsrm + "%" || "---"}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell
                                                                                                sx={{
                                                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                                                    width: "inherit",
                                                                                                }}
                                                                                                align="left"
                                                                                            >
                                                                                                {data.reportData[section.format]?.fecha_de_inscripcion_al_rupc || "---"}
                                                                                            </StyledTableCell>
                                                                                        </StyledTableRow>
                                                                                    </Table>
                                                                                }
                                                                            </TableContainer>
                                                                        </Box>
                                                                    </>
                                                                    : ""
                                                }

                                            </TableCell>
                                    }
                                    <TableCell
                                        sx={{
                                            width: "20%",
                                            backgroundColor: "rgba(0, 127, 255, 0.2)",
                                            borderBottom: "5px solid #ffffff !important",
                                            borderRight: "5px solid #ffffff !important",
                                            borderRadius: 0,
                                            p: { xs: "5px", md: "16px" },
                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                        }}
                                        align="center"
                                    >
                                        {section.concepto ? t(`${section.concepto}`) : ""}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            width: "20%",
                                            backgroundColor: "rgba(0, 127, 255, 0.2)",
                                            borderRadius: 0,
                                            borderBottom: "5px solid #ffffff !important",
                                            p: { xs: "5px", md: "16px" },
                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                        }}
                                        align="left"
                                    >
                                        <ul>
                                            {
                                                section.resultado ? (section.resultado.map((item) => (
                                                    <li style={{ listStyle: "none" }}> {t(`${item}`)}</li>
                                                ))) : ("")
                                            }

                                        </ul>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default AccordianEreportTableMain