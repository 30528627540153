import { Grid, Typography, Box } from "@mui/material";
import { Fragment } from "react";
import underLine from "../../assets/images/under-line.png";
import GuestFooter from "../../Footer/GuestFooter";
import SimpleLogoHeader from "../@common/Header/SimpleLogoHeader";
import EmployReferenceContent from "./EmployReferenceContent";
import { Link, useNavigate } from "react-router-dom";
import EmployRefInternalFroms from "./EmployRefInternalFroms";
import Container from "@mui/material/Container";

function ReferencesRemaining() {
  const navigate = useNavigate();

  const sendHandler = () => {
    alert("okay");
    navigate("/ReferenciaspPDF");
  };

  return (
    <Fragment>
      <SimpleLogoHeader />
      <Box sx={{ mt: 14, mb: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "24px",
                    lineHeight: "35px",
                    textAlign: "center",
                    color: "#1C212D",
                  }}
                >
                  Employment References
                </Typography>
                <Box sx={{ textAlign: "center", mt: -1 }}>
                  <img src={underLine} alt="under line" />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <EmployRefInternalFroms onClick={sendHandler} />
          </Box>
        </Container>
      </Box>
      <GuestFooter />
    </Fragment>
  );
}

export default ReferencesRemaining;
