import { Grid, Typography, Box, Divider } from "@mui/material";
import { useState, Fragment } from "react";
import underLine from "../../assets/images/under-line.png";
import GuestFooter from "../../Footer/GuestFooter";
import SimpleLogoHeader from "../@common/Header/SimpleLogoHeader";
import EmployReferenceContent from "./EmployReferenceContent";
import { Link, useNavigate } from "react-router-dom";
import EmployRefInternalFroms from "./EmployRefInternalFroms";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import IconButton from "@mui/material/IconButton";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import PanToolIcon from "@mui/icons-material/PanTool";
import NorthWestSharpIcon from "@mui/icons-material/NorthWestSharp";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import BrushIcon from "@mui/icons-material/Brush";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import inIcons from "../../assets/images/InIcons.svg";
import textIcons from "../../assets/images/textIcon.svg";
import stampIcon from "../../assets/images/stampIcon.svg";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import TextField from "@mui/material/TextField";
import EmployRefEmailSuccessSent from "../@common/Modal/EmployRefEmailSuccessSent";

const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function ReferencesPDF() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendHandler = () => {
    // alert("oka")
    navigate("/EmploymentReferences");
  };

  return (
    <Fragment>
      <SimpleLogoHeader />
      <Box sx={{ mt: 14, mb: 6 }}>
        {/* <Container > */}
        <Box sx={{ backgroundColor: "#ffffff", borderRadius: 1, m: 4, py: 4 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "24px",
                    lineHeight: "35px",
                    textAlign: "center",
                    color: "#1C212D",
                  }}
                >
                  Referencias.pdf
                </Typography>
                <Box sx={{ textAlign: "center", mt: -1 }}>
                  <img src={underLine} alt="under line" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box>
                  <Checkbox {...label} />
                </Box>
                <Box>
                  <HorizontalRuleIcon
                    sx={{ transform: "rotate(90deg)", mt: 1 }}
                  />
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <SettingsSharpIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <PanToolIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <NorthWestSharpIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <RemoveCircleOutlineSharpIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <AddCircleOutlineSharpIcon />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                  <FormControl fullWidth>
                    <NativeSelect
                      sx={{
                        "&:before": {
                          borderBottom: 0,
                        },
                        "&:after": {
                          borderBottom: 0,
                        },
                      }}
                      defaultValue={30}
                      inputProps={{
                        name: "age",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={10}>94 %</option>
                      <option value={20}>90 %</option>
                      <option value={30}>84 %</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Box>
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <BrushIcon />
                      <ArrowDropDownIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option}
                          selected={option === "Pyxis"}
                          onClick={handleClose}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Box>
                <Box>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <FontDownloadIcon />
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <PanoramaFishEyeIcon />
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img width={26} src={inIcons} alt="In" />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 0.6 }}>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img width={12} src={textIcons} alt="Text" />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <ChatBubbleRoundedIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img src={stampIcon} alt="stamp" />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreHorizSharpIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option}
                          selected={option === "Pyxis"}
                          onClick={handleClose}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Box>
                <Box>
                  <HorizontalRuleIcon
                    sx={{ transform: "rotate(90deg)", mt: 1, fontSize: 30 }}
                  />
                </Box>
                <Box>
                  <IconButton
                    color="neutral.700"
                    aria-label="upload picture"
                    component="label"
                  >
                    <SearchSharpIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                  <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option}
                          selected={option === "Pyxis"}
                          onClick={handleClose}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Divider
              sx={{
                borderStyle: "solid",
                borderColor: "neutral.300",
                width: "100%",
              }}
            />
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  p: 4,
                  borderStyle: "solid",
                  borderColor: "neutral.300",
                  borderRadius: 1,
                  mt: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ textAlign: "left", width: "50%" }}>
                    <Typography
                      sx={{
                        color: "neutral.900",
                        fontSize: 22,
                        fontFamily: "Gilroy-SemiBold",
                      }}
                    >
                      Referencias Laborales
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "right", width: "50%" }}>
                    <Typography
                      sx={{
                        color: "neutral.600",
                        fontSize: 16,
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      Tuesday, July 6, 2021
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    borderStyle: "solid",
                    borderColor: "neutral.300",
                    width: "100%",
                    my: 2,
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      fontSize: 18,
                      fontFamily: "Gilroy-Medium",
                      color: "text.opacity",
                    }}
                    component={"label"}
                  >
                    Questionnaire
                  </Box>
                  <Box sx={{ pt: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label=""
                      multiline
                      rows={4}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      fontSize: 18,
                      fontFamily: "Gilroy-Medium",
                      color: "text.opacity",
                    }}
                    component={"label"}
                  >
                    Candidate name…
                  </Box>
                  <Box sx={{ pt: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label=""
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      fontSize: 18,
                      fontFamily: "Gilroy-Medium",
                      color: "text.opacity",
                    }}
                    component={"label"}
                  >
                    Entry date: the company.
                  </Box>
                  <Box sx={{ pt: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label=""
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      fontSize: 18,
                      fontFamily: "Gilroy-Medium",
                      color: "text.opacity",
                    }}
                    component={"label"}
                  >
                    Company departure date..
                  </Box>
                  <Box sx={{ pt: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label=""
                    />
                  </Box>
                </Box>
                {/************************ Modal Email successfully **************************/}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <EmployRefEmailSuccessSent
                    text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed atturpis vitae velit euismod"
                    buttonText="OK"
                    onClick={sendHandler}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </Box>
        {/* </Container> */}
      </Box>
      <GuestFooter />
    </Fragment>
  );
}

export default ReferencesPDF;
