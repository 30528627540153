import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import underLine from "../../../src/assets/images/under-line.png";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import chatGptIcon from "../../../src/assets/images/chatGptIcon.svg";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader";
import AlertModal from "../@common/Modal/AlertModal";
import axios from "axios";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const style = {
  "*::-webkit-scrollbar": {
    width: "7px !important",
    borderRadius: "3px !important",
  },
  "*::-webkit-scrollbar-track": {
    backgroundColor: "#F3F3F3 !important",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#D4D4D4 !important",
    borderRadius: "3px !important",
  },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#FF8D00",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const AddNewCommentModal = ({
  addCommentOpen,
  setAddCommentOpen,
  commentNbc,
  setCommentNbc,
  userEmail,
  editComment,
  setEditComment,
  setIsSuccessModalOpen,
  setAllCommentOpen,
  commentType,
  setCommentType,
}) => {
  const isEdit = editComment && Object.keys(editComment).length > 0; // Check if editComment is defined
  const { t } = useTranslation();
  const [comment, setComment] = useState(editComment?.comment || "");
  const [isPrivate, setIsPrivate] = useState(editComment?.is_private || false);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");

  const handleClose = () => {
    setAddCommentOpen(false);
    setComment("");
    setCommentNbc("");
    setEditComment({});
    setCommentType("");
  };

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleSave = async () => {
    handleOpenLogoModal();

    const endpoint = isEdit
      ? `${process.env.REACT_APP_BASE_URL2}edit_comment/${editComment._id}` // Update endpoint for editing
      : `${process.env.REACT_APP_BASE_URL2}add_comment`; // Endpoint for adding

    // Define the payload dynamically
    const payload = isEdit
      ? { comment: comment, is_private: isPrivate, user_email: userEmail } // For editing, minimal fields
      : {
          nbc_id: commentNbc,
          comment: comment,
          user_email: userEmail,
          is_private: isPrivate,
          module_type: commentType,
        }; // For adding, include all fields

    console.log("payload", payload);
    console.log("payload", {
      comment: comment,
      is_private: isPrivate,
      user_email: userEmail,
    });
    console.log("payload", {
      nbc_id: commentNbc,
      comment: comment,
      user_email: userEmail,
      is_private: isPrivate,
      module_type: commentType,
    });
    try {
      const response = await axios({
        method: isEdit ? "put" : "post", // Use PUT for editing, POST for adding
        url: endpoint,
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      });

      handleCloseLogoModal();

      if (response.data.message) {
        handleClose();
        setIsSuccessModalOpen(true);
      } else {
        setShowtext(response.data.error);
        setShowAlert(true);
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      setShowAlert(true);
    }
  };

  // Polish Comment API Call
  const handlePolishComment = async () => {
    const endpoint = process.env.REACT_APP_BASE_URL2 + "polish_comment";
    try {
      const response = await axios.post(endpoint, { comment: comment });
      if (response.data) {
        setComment(response.data.polished_comment); // Assuming the API returns the polished comment
      }
    } catch (error) {
      console.error("Error while calling Polish API:", error);
    }
  };

  const handleBack = () => {
    handleClose();

    if (isEdit) {
      setAllCommentOpen(true);
    }
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />
      <Modal
        open={addCommentOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12} sm={12} sx={{ marginBottom: "16px" }}>
            <Box
              sx={{
                mt: { xs: 2, sm: 1 },
                paddingBottom: "16px",
                textAlign: "start",
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: "#fff",
                  color: "#FF8D28",
                  borderRadius: 50,
                  boxShadow: 4,
                  p: 0,
                }}
                onClick={handleBack}
              >
                <ChevronLeftIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
          </Grid>
          <Box sx={{ position: "relative" }}>
            <TextField
              fullWidth
              multiline
              rows={5}
              placeholder={t("expansionField.commentPlaceHolder")}
              value={comment}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setComment(e.target.value);
                }
              }}
              inputProps={{ maxLength: 200 }}
              sx={{
                marginBottom: "16px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
            <Tooltip title={t("expansionField.aiText")} arrow placement="top">
              <Button
                sx={{
                  background: "none !important",
                  border: "none !important",
                  p: 0,
                  minWidth: "0 !important",
                  position: "absolute",
                  bottom: "40px",
                  right: "10px",
                }}
                onClick={handlePolishComment}
              >
                <img src={chatGptIcon} alt="chatgpt icon" />
              </Button>
            </Tooltip>
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                bottom: "18px",
                right: "10px",
                color: "gray",
              }}
            >
              {t("expansionField.aiText")}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#888",
              textAlign: "right",
              marginTop: "-10px",
            }}
          >
            {`${comment.length}/200`}
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
          >
            <Typography sx={{ marginRight: "8px" }}>
              {t("expansionField.private")}
            </Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              }
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                borderRadius: "6px",
                background: "#A7A6A6 !important",
                boxShadow: "0px 10px 50px #0836650F",
                color: "#fff",
                border: "none !important",
                py: 1,
                px: 3,
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
            >
              {t("expansionField.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: 3,
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              disabled={
                isEdit
                  ? (comment === editComment?.comment &&
                      isPrivate === editComment?.is_private) ||
                    !comment.trim()
                  : !comment.trim()
              }
            >
              {t("expansionField.save")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewCommentModal;
