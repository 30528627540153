
import { useRef } from "react";
import "./DropDownInputSimple.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mailImage from "../../../../assets/images/company-icon.png"

function DropDownInputSimple(props) {
  const {

    options_data = [],
    onChange,
    containerStyle,
    inputValue,
    label = "label",
    errorMsg = "errorMsg",
    flag = false,
    name,
    value = "",
    firstName = value,
    id = "",
    star,
    disabled,
    hideFirst = false,
    disableFirst = false,
    icon = "expand_more",
    onBlur,
    mailIcon = { mailImage },
    placeholder,
    subLabel
  } = props;

  const selectRef = useRef(null);

  return (
    <div className="clddi_input_container">
      <label htmlFor="" className="clddi_label">
        {label}
        <span style={{ color: "rgba(27, 32, 44, 0.6)", fontSize: "12px", marginLeft: "6px" }}>{subLabel}</span>
      </label>
      <div className="clddi_div1">
        <div className="clddi_container">
          <select
            className="clddi_input form-select"
            value={inputValue}
            onChange={onChange}
            ref={selectRef}
            aria-describedby={flag ? "ErrorMessage" : ""}
            aria-required="true"
            aria-invalid={flag ? "true" : "false"}
            data-rule="select"
          >
            <option value="" disabled={!hideFirst || disableFirst}>
              {inputValue ? inputValue : placeholder || "Select Client"}
            </option>
            {!disabled &&
              options_data.map((item, index) => (
                  <option value={name === "document" ? item : item.value} key={index}>
                    {name === "document" ? item : item.name}
                  </option>
                ))}
          </select>

          <ExpandMoreIcon className="clddi_icon" />
        </div>
        <div
          id="ErrorMessage"
          aria-hidden={flag ? "false" : "true"}
          role="alert"
          style={{ display: flag ? "block" : "none" }}
        >
          <p className="clddi_error_text">{errorMsg}</p>
        </div>
      </div>
    </div>
  );
}

export default DropDownInputSimple;
