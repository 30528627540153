import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomizedReportTabsBody from "./CustomizedReportTabsBody";

import {
  sectionAData,
  sectionBData,
  sectionCData,
  sectionEData,
  sectionDData,
  sectionFData,
  sectionGData,
} from "../../utils/reportsConstants";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { t } = useTranslation();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            "@media (max-width: 600px)": {
              p: 0,
            },
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomizedReportTabs(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { personalization, setPersonalization, savePersonalization, TipoType } =
    props;
  const withBureau = TipoType === "With Bureau";

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              py: 4,
              px: 6,
              borderRadius: 2,
              "@media (max-width: 600px)": {
                p: 0,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 22,
                color: "neutral.900",
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("CustomizedReport.sectionsTextAbove")}
            </Typography>
            <Box sx={{ width: "100%", mt: 6 }}>
              <Box
                sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons={false}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            top: "35%",
                            right: "0",
                            width: "1px",
                            height: "50%",
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          A
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.A")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.Study")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            top: "35%",
                            right: "0",
                            width: "1px",
                            height: "50%",
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          B
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.B")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.History")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            top: "35%",
                            right: "0",
                            width: "1px",
                            height: "50%",
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          C
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.C")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.Legal")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(2)}
                  />
                  {withBureau && (
                    <Tab
                      sx={{
                        px: 3.6,
                      }}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            "&:before": {
                              content: '""',
                              position: "absolute",
                              top: "35%",
                              right: "0",
                              width: "1px",
                              height: "50%",
                              backgroundColor: "rgba(27, 32, 44, 0.1)",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              fontFamily: "Gilroy-Medium",
                              fontSize: 12,
                              borderRadius: 50,
                              backgroundColor: "rgba(27, 32, 44, 0.1)",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            D
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Gilroy-Medium",
                              fontSize: 16,
                              mt: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {t("Tabs-SubTabs.D")}
                          </Box>
                          <Box
                            sx={{
                              fontFamily: "Gilroy-Medium",
                              fontSize: 16,
                              mt: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            {t("Tabs1-SubTabs.Analysis")}
                          </Box>
                        </Box>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            top: "35%",
                            right: "0",
                            width: "1px",
                            height: "50%",
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          E
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.E")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.Credit")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(4)}
                  />
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          "&:before": {
                            content: '""',
                            position: "absolute",
                            top: "35%",
                            right: "0",
                            width: "1px",
                            height: "50%",
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          F
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.F")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.Information")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(5)}
                  />
                  <Tab
                    sx={{
                      px: 3.6,
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 12,
                            borderRadius: 50,
                            backgroundColor: "rgba(27, 32, 44, 0.1)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          G
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs-SubTabs.G")}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "Gilroy-Medium",
                            fontSize: 16,
                            mt: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {t("Tabs1-SubTabs.Recommendation")}
                        </Box>
                      </Box>
                    }
                    {...a11yProps(6)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  sectionData={sectionAData}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  sectionData={sectionBData}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  sectionData={sectionCData}
                />
              </TabPanel>
              {withBureau && (
                <TabPanel value={value} index={3}>
                  <CustomizedReportTabsBody
                    personalization={personalization}
                    setPersonalization={setPersonalization}
                    savePersonalization={savePersonalization}
                    sectionData={sectionDData}
                  />
                </TabPanel>
              )}
              <TabPanel value={value} index={withBureau ? 4 : 3}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  sectionData={sectionEData}
                />
              </TabPanel>
              <TabPanel value={value} index={withBureau ? 5 : 4}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  sectionData={sectionFData}
                />
              </TabPanel>
              <TabPanel value={value} index={withBureau ? 6 : 5}>
                <CustomizedReportTabsBody
                  personalization={personalization}
                  setPersonalization={setPersonalization}
                  savePersonalization={savePersonalization}
                  withBureau={withBureau}
                  sectionData={sectionGData}
                />
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CustomizedReportTabs;
