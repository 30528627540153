import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getCookiesList } from "../../../utils/cookiesHelper";
import { serverRequest2 } from "../../../utils/requestHelper";
import { encryptData } from "../../../utils/encryptionHelper";
import { removeCookie } from "../../../utils/cookiesHelper";

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const userCookiesList = getCookiesList("userInfo");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkIsAuthenticated = async () => {
    if (
      typeof userCookiesList != "object" ||
      userCookiesList.length != 0 ||
      userCookiesList[3] != undefined ||
      userCookiesList[4] != undefined
    ) {
      const data_eel = encryptData(userCookiesList[3], 500);
      const dataForm = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
        data_etn: userCookiesList[4],
      };
      const response = await serverRequest2(
        "post",
        "autenticar_usuario",
        dataForm
      );
      return response.data === "ok";
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkIsAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (!isAuthenticated) {
        removeCookie("userInfo");
        removeCookie("guestUserInfo");
      }
      setIsLoading(false);
    };

    fetchData();
  }, [location]);

  if (isLoading) {
    return null;
  }
  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/Login" replace />;
  }
};
