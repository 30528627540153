import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import {useLocation, Link, useNavigate }from 'react-router-dom';
import sucessImage from "../../../assets/images/exportIcon.svg"
import { useEffect, useState } from "react";
import { getCookiesDict } from "../../../utils/cookiesHelper"
import { useTranslation } from 'react-i18next';
import { getPersonalization } from '../../../utils/bureauHelper';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  maxWidth:"95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:4
};

export default function CustomizeReportModal({personalization, setPersonalization, savePersonalization}) {
  const TipoType = localStorage.getItem('TipoType');
  const { t } = useTranslation();
  const location = useLocation();
  let reportname = "";
  if (TipoType === "Without Bureau") {
    reportname = "SB";
  } else if (TipoType === "With Bureau") {
    reportname = "CB";
  } else {
    reportname = "";
  }
  
  const text = `${t("CustomizedReport.popUpConfirmationSubtittle")} ${reportname}`;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (location.pathname === "/CustomizedReport") {
      localStorage.setItem('customizedReport', true);
      localStorage.removeItem('adminstratoruser');
    } else if (location.pathname === '/AdministratorUsers') {
      localStorage.setItem('adminstratoruser', true);
      localStorage.removeItem('customizedReport');
    }
  }, [location.pathname, ]);
  const sendHandler = async () => {
    const cookies = getCookiesDict();
    const email = cookies.email;
    if (email) {
      const TipoType = localStorage.getItem("TipoType");
      const isForAdminstratorUser = localStorage.getItem('adminstratoruser');
      const generalCustomization = localStorage.getItem('customizedReport');
      try {
        let response = null;
        if (isForAdminstratorUser) {
          response = await savePersonalization(email, TipoType, true);
        } else if (generalCustomization) {
          response = await savePersonalization(email, TipoType, false);
          if (response?.status === 200) {
            localStorage.removeItem('isForAllUsers');
          }
        }
        if (response?.status === 200) {
          handleClose();
        }
      } catch (e) {
        console.log(e);
      }
    }
    
  };
  


  return (
    <Fragment>
      <Button
      sx={{
        backgroundColor:"primary.main",
        textAlign:"center",
        borderWidth:1,
        borderColor:"primary.main",
        borderStyle:"solid",
        borderRadius:"6px",
        py:1.5,
        px:8,
        color:"#fff",
        textDecoration:"none",
        fontSize:18,
        fontFamily:"Gilroy-Medium",
        textTransform:"capitalize"
     }}
       onClick={handleOpen} variant="contained">{t("CustomizedReport.button")}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box sx={{justifyContent:"center",display:"flex", alignItems:"center"}}>
                <img src={sucessImage} width="70px" height="70px" alt="email icon" />
            </Box>
            <Box sx={{display:"flex",justifyContent:"center", mt:4,flexDirection:"column"}}>
            <Typography component={"h6"} sx={{color:"neutral.900",fontSize:20,fontFamily:"Gilroy-SemiBold",textAlign:"center"}}>{t("CustomizedReport.popUpConfirmationTittle")} <Box component={"span"} sx={{color:"primary.main"}}>{t("CustomizedReport.save")}</Box></Typography>
          
          <Typography
          sx={{
            fontSize:16,
            fontFamily: "Gilroy-Medium",
            color:"rgba(27, 32, 44, 0.6)",
            textAlign:"center",
            mt:2
          }}
          >
         {text}
          </Typography>
          </Box>
         
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
          <Button onClick={sendHandler} sx={{backgroundColor:"primary.main",py:1,px:{xs:4,sm:8},fontSize:"18px",  fontFamily: "Gilroy-Medium",textTransform:"capitalize" }} variant="contained">{t("CustomizedReport.button")}</Button>
          </Box>
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:2,mb:1}}>
            <Button onClick={handleClose} style={{color:"rgba(27, 32, 44, 0.6)",fontSize:16,fontFamily:"Gilroy-Medium",textDecoration:"underline",textTransform:"capitalize"}}  variant="text">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}