import { useTranslation } from "react-i18next";
import { Grid, Box, Tooltip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import InputRightIcon from "../@common/Input/InputRightIcon/InputRightIcon";
import personIcon from "../../assets/images/person.svg";
import DropDownInputSimple from "../@common/Input/DropDownInputSimple/DropDownInputSimple";
import LabelInput from "../@common/Input/LabelInput";
import RadioButtonsGroup from "../@common/Input/radios";
import Button from "@mui/material/Button";
import axios from "axios";
import { options_edo } from "../../utils/optionsEdo";
import { getCookiesList } from "../../utils/cookiesHelper";
import "./Emodule.css";
import SwitchesCustomized from "../../../src/components/WithBureau/WithBureauQueryForm/SwitchButton";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import AlertModal from "../@common/Modal/AlertModal";
import CompanyListModal from "../@common/Modal/EmoduleBureauModal/CompanyListModal";
import { checkCupons, timeSleep } from "../../utils/locales/EbureauHelper";

const ESubModule = ({}) => {
  const [isMandatory, setIsMandatory] = useState(false);
  const [scrapperRequestId, setScrapperRequestId] = useState();
  const [companyListData, setCompanyListData] = useState([]);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [sigerCeroId, setSigerCeroId] = useState(null);
  const [DataSend, setDataSend] = useState({});
  const [isWeekend, setIsWeekend] = useState(false);

  useEffect(() => {
    const checkIfWeekend = () => {
      const today = new Date().getDay();
      setIsWeekend(today === 6 || today === 0);
    };

    checkIfWeekend();
  }, []);

  const { t } = useTranslation();
  const multiReportTypeList = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  const multiReportListPartner = [
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  options_edo[0].name = t("placeholders.ddEdonto");

  // cookie state
  const [cookieList, setCookieList] = useState([]);
  // console.log(cookieList, "cokkies");

  // Modals states
  const [loadFunction, setLoadFunction] = useState(true);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [timerTimeoutStatus, setTimerTimeoutStatus] = useState(false);

  // Form data
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [rfc, setRfc] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [isAutoComplete, setIsAutoComplete] = useState(false); // auto complete state

  //when with bureau data
  const [street, setStreet] = useState(""); //calle
  const [number, setNumber] = useState(""); //number
  const [cologne, setCologne] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setState] = useState("State"); //estada
  const [multiReportType, setMultiReportType] = useState("With Bureau");

  const [error, setError] = useState("");
  const [optionsEdo, setOptionsEdo] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeColor2, setChangeColor2] = useState(false);

  const [termsModal, setTermsModal] = useState(false);

  const isNameEmpty = name.trim() === "";
  const isCompanyName = companyName.trim() === "";
  const isRfc = rfc.trim() === "";
  const isLastNameEmpty = last_name.trim() === "";
  const isMotherNameEmpty = mother_last_name.trim() === "";
  const isStreetEmpty = street.trim() === "";
  const isPostal_code = postal_code.trim() === "";
  const isCologneEmpty = cologne.trim() === "";
  const isStateEmpty = state.trim() === "State";
  const isMunicipalityEmpty = municipality.trim() === "";
  const isNumber = number.trim() === "";

  const isCompanyNameValid = companyName.length >= 2;

  const multiReportTypeHandler = useCallback((e) => {
    setMultiReportType(multiReportType == e.target.value ? "" : e.target.value);
  });

  const loadAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_load",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data) {
      setIsAutoComplete(true);
    }
  };

  const autoFill = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL2}get_address_auto?d_codigo=${postal_code}`;
    try {
      const response = await axios.get(endpoint);
      const data = response.data;
      if (Array.isArray(data) && data.length === 1) {
        const item = data[0];
        autofillFields(
          `${item.d_codigo}, ${item.d_asenta}, ${item.D_mnpio}, ${item.d_estado}`
        );
      } else if (Array.isArray(data) && data.length > 1) {
        setOptionsEdo(data); // Include a default option
        setShowDropdown(true);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  const handleDropdownChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedData = optionsEdo[selectedIndex];
    setSelectedOption(selectedData);
    autofillFields(selectedIndex);
    setShowDropdown(false);
  };

  const autofillFields = (addressData) => {
    // Split the addressData string into individual parts
    const [d_codigo, d_asenta, D_mnpio, d_estado] = addressData.split(", ");
    // Set each part into separate states
    setCologne(d_asenta);
    setMunicipality(D_mnpio);
    setState(d_estado);
    setChangeColor2(true);
  };

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setSpinnerVisibility(true);
    setTimerTimeoutStatus(false);
  };

  const handleCloseLogoModal = () => {
    setSpinnerVisibility(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const subject_name = name + " " + last_name + " " + mother_last_name;
  //user
  const user_tjt = "NA";
  const user_master = "1";
  const user_customer = "qwert";

  const handleSubmit = async (flag) => {
    // RFC pattern for 12 or 13 characters with at least one letter and one number
    const rfcPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9]{12,13}$/;

    if (rfc.trim() !== "" && !rfcPattern.test(rfc)) {
      setError(t("Emodule.RFC_error"));
      return;
    }
    if (rfc.trim() !== "" && isMandatory) {
      setTermsModal(true);
    }

    setError(false);
    handleOpenLogoModal();
    let eModuleInfo = {
      data_ucl: user_customer,
      data_userAgent: navigator.userAgent,
      sujeto_razon_social: companyName.trim(),
      sujeto_rfc: rfc,
      sujeto_nombre: subject_name,
      sujeto_apellido_m: "",
      sujeto_apellido_p: "",
      sujeto_codigo_postal: postal_code,
      sujeto_calle: street + " " + number,
      sujeto_colonia: cologne,
      sujeto_municipio: municipality,
      sujeto_estado: multiReportType === "Without Bureau" ? "NA" : state,
      sujeto_confirmacion_legal: "1",
      sujeto_email: "",
      sujeto_tel: "",
      usuario_apellido_m: cookieList[1],
      usuario_apellido_p: cookieList[2],
      // "usuario_cupon": multiReportType === "Without Bureau" ? "SB" : "CB",
      usuario_cupon: isMandatory ? "CB" : "SB",
      usuario_email: cookieList[3],
      usuario_id: cookieList[6],
      usuario_master: user_master,
      usuario_master_id: cookieList[6],
      usuario_nombre: cookieList[0],
      usuario_tel: cookieList[5],
      usuario_tjt: user_tjt,
      first_name: name,
      last_name: last_name,
    };

    const endpoint = process.env.REACT_APP_BASE_URL2 + "siger_cero";
    // Validate coupon before proceeding
    const isCouponValid = await validarTipoConsulta();
    if (!isCouponValid) {
      // Coupon validation failed, stop further execution
      return;
    }

    try {
      const response = await axios.post(endpoint, eModuleInfo, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.mensaje === "Guardado correctamente") {
        setDataSend(eModuleInfo);
        setSigerCeroId(response.data._id);

        let retryCount = 0;
        while (true) {
          if (retryCount < 3) {
            await timeSleep(3000);
          } else {
            await timeSleep(10000);
          }
          if (await checkSigerResponse(response.data._id)) break;

          retryCount++;
        }
      } else {
        handleCloseLogoModal();
        setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
        setShowAlert(true);
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };

  // Check siger response
  const checkSigerResponse = async (sc_id) => {
    const endpoint =
      process.env.REACT_APP_BASE_URL2 + "siger_wait_for_response";

    try {
      console.log({ siger_cero_id: sc_id, step_no: 1 });

      const response = await axios.post(
        endpoint,
        { siger_cero_id: sc_id, step_no: 1 },
        { headers: { "Content-Type": "application/json" } }
      );

      if (timerTimeoutStatus) {
        return true;
      }

      if (response?.data?.status == "pending") {
        return false;
      } else if (response?.data?.status == "error") {
        if (response?.data?.message == "too_many_results") {
          handleCloseLogoModal();
          setShowAlert(true);
          setShowtext(t("Emodule.tooManyResults"));
          return true;
        }
        handleCloseLogoModal();
        setShowAlert(true);
        setShowtext(t("Emodule.companyError"));
        return true;
      } else if (response?.data?.results?.length) {
        handleCloseLogoModal();
        setCompanyModalOpen(true);
        setCompanyListData(response.data.results);
        return true;
      } else {
        handleCloseLogoModal();
        setShowAlert(true);
        setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
        return true;
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      return true;
    }
  };

  const onTimerTimeout = () => {
    setTimerTimeoutStatus(true);
    handleCloseLogoModal();
    setShowAlert(true);
    setShowtext(t("GuestDashboard.timerTimeoutError"));
  };

  // clear input field on changes of esubmodule
  useEffect(() => {
    setName("");
    setLastName("");
    setMotherLastName("");
    setCompanyName("");
    setRfc("");
    setPostalCode("");
    setStreet("");
    setNumber("");
    setCologne("");
    setMunicipality("");
    setState("State");
    setMultiReportType("With Bureau");
    setIsAutoComplete(false);
  }, [companyModalOpen]);

  // clear input field on changes of CB/SB
  useEffect(() => {
    setPostalCode("");
    setStreet("");
    setNumber("");
    setCologne("");
    setMunicipality("");
    setState("State");
  }, [multiReportType]);

  useEffect(() => {
    if (cookieList.length != 0) {
      loadAutoComplete();
    }
  }, [cookieList]);

  // Effects
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {
    if (isAutoComplete) {
      setLoadFunction(true);
    }
  }, [isAutoComplete]);

  const areAllFieldsEmpty =
    street === "" &&
    postal_code === "" &&
    cologne === "" &&
    municipality === "" &&
    state === "State";

  const handleInputChange = (event, setValue) => {
    setValue(event.target.value);
  };

  const validarTipoConsulta = async () => {
    // decide which modal box to open
    const cupon = "Companies";
    const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
    console.log(checkCuponsResponse, "checkCuponsResponse");
    if (checkCuponsResponse === "error") {
      handleCloseLogoModal();
      setShowAlert(true);
      let messageData;
      if (localStorage.getItem("selectedLanguage") == "en") {
        messageData =
          "We apologize, but you have run out of 'Companies' coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
      } else {
        messageData =
          "Lo sentimos, has agotado tus cupones de consultas a Empresas. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
      }
      setShowtext(messageData);
      return false;
    }
    return true;
  };

  useEffect(() => {
    setIsMandatory(!areAllFieldsEmpty);
  }, [areAllFieldsEmpty]);

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      {/* <LogoModalLoader isOpenLogoModal={isLogoModalOpen} /> */}
      {spinnerVisibility && (
        <CustomSpinner
          visible={spinnerVisibility}
          timer={180}
          onTimerTimeout={onTimerTimeout}
        />
      )}

      <CompanyListModal
        companyModalOpen={companyModalOpen}
        setCompanyModalOpen={setCompanyModalOpen}
        companyListData={companyListData}
        sigerCeroId={sigerCeroId}
        scrapperRequestId={scrapperRequestId}
        emoduleData={DataSend}
        termsModal={termsModal}
      />

      <Box sx={{ backgroundColor: "#ffffff", p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <SwitchesCustomized
                isAutoComplete={isAutoComplete}
                toggleButton={(switchState) => {
                  setLoadFunction(true);
                  setIsAutoComplete(switchState);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <Tooltip
                title={t("Emodule.Company Name Input Tip")}
                arrow
                placement="top"
              >
                <InputRightIcon
                  mailIcon={personIcon}
                  type="text"
                  label={t("Emodule.Company Name")}
                  name="companyName"
                  id="companyName"
                  inputValue={companyName}
                  placeholder={t("placeholders.ecompanyname")}
                  onChange={(event) => {
                    setCompanyName(event.target.value.trimStart());
                  }}
                  isValid={isCompanyNameValid}
                  mandatory={true}
                  disabled={isWeekend}
                />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <InputRightIcon
                mailIcon={personIcon}
                type="text"
                label="RFC"
                name="RFC"
                id="RFC"
                placeholder={t("placeholders.rfc")}
                inputValue={rfc}
                onChange={(event) => {
                  setRfc(
                    event.target.value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .toUpperCase()
                      .trim()
                  );
                }}
                maxLength={13}
                // mandatory={multiReportType == "With Bureau" ? true : false}
                mandatory={false}
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
            {error && (
              <Box mt={{ xs: 2, sm: 0 }} style={{ color: "red", fontSize: 12 }}>
                {error}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <div>
                {showDropdown ? (
                  <DropDownInputSimple
                    options_data={optionsEdo}
                    label={t("labels.inputLabelPostalCode")}
                    inputValue={selectedOption ? selectedOption.value : ""}
                    onChange={(event) => handleDropdownChange(event)}
                    useCustomFormat={true}
                    mandatory={isMandatory}
                    showDefaultOption={true}
                    emoduleInput="true"
                  />
                ) : (
                  <LabelInput
                    type="text"
                    subLabel=""
                    label={t("labels.inputLabelPostalCode")}
                    name="code"
                    id="code"
                    placeholder={t("placeholders.postalcode")}
                    inputValue={postal_code}
                    onChange={(event) =>
                      setPostalCode(event.target.value.replace(/[^0-9]/g, ""))
                    }
                    onKeyUp={autoFill}
                    maxLength={5}
                    Mandatory={isMandatory}
                    emoduleInput="true"
                    disabled={isWeekend}
                  />
                )}
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              <LabelInput
                type="text"
                subLabel=""
                label={t("labels.inputLabelStreet")}
                name="street"
                id="street"
                placeholder={t("placeholders.street")}
                inputValue={street}
                onChange={(event) => handleInputChange(event, setStreet)}
                Mandatory={isMandatory} // Street is not mandatory
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              <LabelInput
                type="text"
                subLabel=""
                label={t("labels.inputLabelNumber")}
                name="number"
                id="number"
                placeholder={t("placeholders.number")}
                inputValue={number}
                onChange={(event) => setNumber(event.target.value)}
                maxLength={10}
                Mandatory={false}
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <LabelInput
                type="text"
                subLabel=""
                label={t("labels.inputLabelCologne")}
                name="cologne"
                id="cologne"
                placeholder={t("placeholders.cologne")}
                inputValue={cologne}
                onChange={(event) => handleInputChange(event, setCologne)}
                Mandatory={isMandatory}
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              <LabelInput
                type="text"
                subLabel=""
                label={t("labels.inputLabelMavorsOffice")}
                name="office"
                id="office"
                placeholder={t("placeholders.mavorsoffice")}
                inputValue={municipality}
                onChange={(event) => handleInputChange(event, setMunicipality)}
                Mandatory={isMandatory}
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              <DropDownInputSimple
                options_data={options_edo}
                type="text"
                name="condition"
                subLabel=""
                id="condition"
                label={t("labels.inputLabelCondition")}
                onChange={(event) => {
                  setState(event.target.value);
                  setChangeColor2(true);
                  if (!isMandatory && event.target.value !== "") {
                    setIsMandatory(true);
                  }
                }}
                inputValue={state}
                mandatory={isMandatory}
                selectedColor={changeColor2}
                emoduleInput="true"
                disabled={isWeekend}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 4,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  textAlign: "center",
                  borderRadius: "6px",
                  py: 1.5,
                  px: 8,
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: 18,
                  fontFamily: "Gilroy-Medium",
                  textTransform: "capitalize",
                }}
                // disabled={
                //   (multiReportType === "Without Bureau") ?
                //     (isCompanyName) :
                //     (multiReportType === "With Bureau") ?
                //       (isStreetEmpty || isCologneEmpty || isMunicipalityEmpty || isStateEmpty || isPostal_code || isRfc || isCompanyName) :
                //       false
                // }
                disabled={
                  isMandatory
                    ? isCologneEmpty ||
                      isMunicipalityEmpty ||
                      isStateEmpty ||
                      isPostal_code ||
                      isStreetEmpty ||
                      !isCompanyNameValid
                    : !isCompanyNameValid
                }
                onClick={() => handleSubmit("company")}
              >
                {t("buttons.consult")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ESubModule;
