import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { textAlign } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import sucessImage from "../../assets/images/successfull-email.png"
import lockIcon from "../../assets/images/lock-icon.svg"
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  maxWidth: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

const subjectList = [
  "The present subject is found",
]

export default function VerifyAccountModal(props) {
  const { t } = useTranslation();
  const { text = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", showVerifyTwoFAModel, closeVerifyTwoFAModel } = props
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const handleClose = () => showVerifyTwoFAModel = false;
  const sendHandler = () => {
    alert("sendHandler")
  };
  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <Fragment>
      {/* <Button onClick={handleOpen} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 11 }, fontSize: "18px", fontFamily: "Gilroy-Medium", }} variant="contained">Enter</Button> */}
      <Modal
        open={showVerifyTwoFAModel}
        onClose={closeVerifyTwoFAModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
            <img width={120} height={120} src={lockIcon} alt="download icon" />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, flexDirection: "column" }}>


            <Typography
              sx={{
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                color: "rgba(27, 32, 44, 0.6)",
                textAlign: "center",
                mt: 2,
                mb:2
              }}
            >
              {t("2FAConfiguration.Success")}
            </Typography>
          </Box>

          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:1}}>
            <Button onClick={closeVerifyTwoFAModel} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 11 }, fontSize: "18px", fontFamily: "Gilroy-Medium", }} variant="contained">Ok</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}