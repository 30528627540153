import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
// mui imports
import Login from "./components/Login/Login";
import ErrorPage from "./components/@common/404";
import Signup from "./components/SignupOrRegistration/Signup";
import UserRegistration from "./components/SignupOrRegistration/UserRegistration";
import SuperAdminSignUp from "./components/SignupOrRegistration/SuperAdminSignUp";
import ForgotPassword from "./components/ForgetPassword/ForgotPassword";
import ResetPassword from "./components/ForgetPassword/ResetPassword";
import LoginScreen from "./components/LoginScreen/LoginScreen";
import GuestUser from "./components/GuestUser/GuestUser";
import DashboardMain from "./components/Dashboard/DashboardMain";
import Emodule from "./components/Emodule/Emodule";
import FaceID from "./components/FaceID/FaceID";
import GeoLocation from "./components/GeoLocation/GeoLocation";
import Report from "./components/Report/Report";
import Summary from "./components/Summary/Summary";
import Homonym from "./components/Homonym/Homonym";
import Chat from "./components/Chat/Chat";
import { ChatProvider } from "./components/Chat/ChatContext";
import ReportWithBureau from "./components/ReportWithBureau/ReportWithBureau";
import AuthorizationPanel from "./components/AuthorizationPanel/AuthorizationPanel";
import AdministratorUsers from "./components/AdministratorUsers/AdministratorUsers";
import CouponsManager from "./components/CouponsManager/CouponsManager";
import LaborReferences from "./components/LaborReferences/LaborReferences";
import GuestGenerateQuery from "./components/GuestUserPages/GuestGenerateQuery";
import GuestUserReport from "./components/GuestUserPages/GuestUserReport";
import Coupons from "./components/Coupons/Coupons";
import GuestCoupon from "./components/Coupons/GuestCoupon";
import EmployReferences from "./components/EmploymentReferences/EmployReferences";
import ReferencesRemaining from "./components/EmploymentReferences/ReferencesRemaining";
import ReferencesPDF from "./components/EmploymentReferences/ReferencesPDF";
import BulkUpload from "./components/BulkUpload/BulkUpload";
import CustomizedReport from "./components/CustomizedReport/CustomizedReport";
import CompanyInformation from "./components/CompanyInformation/CompanyInformation";
import APInformation from "./components/APIPage/APInformation";
import AidPage from "./components/AidPage/AidPage";
import Aid from "./components/AidWithFooter/Aid";
import VerifyAccount from "./components/VerifyAccount/VerifyAccount";
import Configuration from "./components/2FAConfiguration/Configuration";
import { ProtectedRoute } from "./components/@common/ProtectedRoute/protectedRoute";
import { GuestProtectedRoute } from "./components/@common/ProtectedRoute/guestProtectedRoute";
import { UnProtectedRoute } from "./components/@common/ProtectedRoute/unprotectedRouteMiddleware";
import Autorizar from "./components/Autorizar/Autorizar";
import LegalNip from "./components/LegalNip/LegalNip";
import { useTranslation, Trans } from "react-i18next";
import AppLayout from "./components/layout/AppLayout";
import { GlobalStyles } from "@mui/material";
import ScrollToTop from "./components/@common/ScrollToTop";
import PublicPrivacyNotice from "./components/NoticePrivacy/PublicPrivacyNotice";
import PublicTermsAndConditions from "./components/TermsConditions/PublicTermsAndConditions";
import EReport from "./components/Ereport/EReport";
import LegalEmail from "./components/LegalEmail/LegalEmail";
import LegalEmailPanel from "./components/LegalEmailPanel/LegalEmailPanel";
import Documents from "./components/AuthorizationPanel/Documents";
function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    i18n.changeLanguage(storedLanguage);
    if (!storedLanguage) {
      localStorage.setItem("selectedLanguage", "es");
    }
  }, []);

  return (
    <>
      <GlobalStyles
        styles={{
          h1: { color: "grey" },
          "*::-webkit-scrollbar": {
            width: "0.1em",
          },
          "*::-webkit-scrollbar-track": {
            " WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0)",
          },
        }}
      />
      <Routes>
        <Route element={<ScrollToTop />}>
          <Route path="/PrivacyNotice" element={<PublicPrivacyNotice />} />
          <Route
            path="/TermsAndConditions"
            element={<PublicTermsAndConditions />}
          />
          <Route path="/legal-email" element={<LegalEmail />} />
          <Route path="/FaceID" element={<FaceID />} />
          <Route path="/GeoLocation" element={<GeoLocation />} />
          <Route element={<UnProtectedRoute />}>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login_lala" element={<Login is_lala={true} />} />
            <Route path="/login/callback" element={<Login />} />
            <Route path="/UserSignup" element={<UserRegistration />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/VerifyAccount" element={<VerifyAccount />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/SuperAdminSignUp" element={<SuperAdminSignUp />} />
            <Route path="/GuestUser" element={<GuestUser />} />
            <Route path="/Autorizar" element={<Autorizar />} />
            <Route path="/legal-nip" element={<LegalNip />} />
          </Route>

          {/* Guest User Routes */}

          <Route element={<GuestProtectedRoute />}>
            <Route path="/GuestDashboard" element={<GuestGenerateQuery />} />
            <Route path="/GuestReport" element={<GuestUserReport />} />
            <Route path="/GuestCoupon" element={<GuestCoupon />} />
          </Route>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route
              path="/ReferencesRemaining"
              element={<ReferencesRemaining />}
            />
            <Route path="/ReferencesPDF" element={<ReferencesPDF />} />
            <Route element={<AppLayout />}>
              <Route path="/dashboard" element={<DashboardMain />} />
              <Route path="/Emodule" element={<Emodule />} />
              <Route path="/Ereport" element={<EReport />} />
              <Route path="/report" element={<Report />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/homonym" element={<Homonym />} />
              <Route
                path="/chat"
                element={
                  <ChatProvider>
                    <Chat />
                  </ChatProvider>
                }
              />
              <Route
                path="/authorizationpanel"
                element={<AuthorizationPanel />}
              />
               <Route
                path="/Documents" element={<Documents/>}
              />
              <Route path="/reportwithbureau" element={<ReportWithBureau />} />
              <Route
                path="/AdministratorUsers"
                element={<AdministratorUsers />}
              />

              <Route path="/CouponsManager" element={<CouponsManager />} />
              <Route path="/LaborReferences" element={<LaborReferences />} />
              <Route path="/Coupons" element={<Coupons />} />
              <Route
                path="/EmploymentReferences"
                element={<EmployReferences />}
              />

              <Route path="/BulkUpload" element={<BulkUpload />} />
              <Route path="/CustomizedReport" element={<CustomizedReport />} />
              <Route
                path="/CompanyInformation"
                element={<CompanyInformation />}
              />
              <Route path="/api" element={<APInformation />} />
              <Route path="/aid" element={<AidPage />} />
              <Route path="/2FAConfiguration" element={<Configuration />} />
            </Route>
          </Route>
          {/* Others */}
          <Route path="/aidVideo" element={<Aid />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
