import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

const AccordianEreportHeader = (props) => {
  const { t } = useTranslation();

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              width: "50px",
              py: 0,
              border: 0,
              color: "#ffffff",
              p: { xs: "0px 6px 0px 0px", md: "0px 12px" },
              fontSize: {
                xs: "2vw",
                md: "0.875rem",
              },
            }}
          >
            {props.tableRowTitle}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              py: 0,
              border: 0,
              color: "#ffffff",
              p: { xs: "0px 5px", md: "0px 12px" },
              fontSize: { xs: "2vw", md: "0.875rem" },
              lineHeight: { xs: "2.5vw", md: "1.5rem" },
            }}
          >
            {props.tableRowheaderTitle}
          </TableCell>
          <TableCell
            sx={{
              width: "30%",
              py: 0,
              border: 0,
              color: "#ffffff",
              p: { xs: "0px 5px", md: "0px 12px" },
              fontSize: { xs: "2vw", md: "0.875rem" },
            }}
            align="center"
          >
            {t("landingpage.leftContent.tabs.subheading.Report Format")}
          </TableCell>
          <TableCell
            sx={{
              width: "20%",
              py: 0,
              border: 0,
              color: "#ffffff",
              p: { xs: "0px 5px", md: "0px 12px" },
              fontSize: { xs: "2vw", md: "0.875rem" },
            }}
            align="center"
          >
            {t("landingpage.leftContent.tabs.subheading.Concept")}
          </TableCell>
          <TableCell
            sx={{
              width: "20%",
              py: 0,
              border: 0,
              color: "#ffffff",
              p: { xs: "0px 5px", md: "0px 12px" },
              fontSize: { xs: "2vw", md: "0.875rem" },
            }}
            align="center"
          >
            {t("landingpage.leftContent.tabs.subheading.Result")}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  )
}

export default AccordianEreportHeader