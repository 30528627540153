import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import superAdmin from "../../../assets/images/super-admin.svg";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

function MenuCard(props) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [cookieList, setCookieList] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { link = "", img, title = "", Subtitle = "", videoId } = props;

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          textDecoration: "none",
          p: 0,
          width: "100%",
        }}
      >
        <Box
          sx={{
            border: "solid 1px rgba(192, 193, 211, 0.6)",
            borderRadius: 1,
            boxShadow: 4,
            p: { xs: 2, sm: 4 },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              component={"span"}
              sx={{
                backgroundColor: "#D9E0FD",
                height: 72,
                width: 72,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
              }}
            >
              <img src={img} alt="menu image" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: "Gilroy-Medium",
                textAlign: "center",
                color: "neutral.900",
                textDecoration: "none",
                textTransform: "capitalize",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: "Gilroy-Medium",
                textAlign: "center",
                color: "neutral.900",
                textDecoration: "none",
                textTransform: "capitalize",
              }}
            >
              {Subtitle}
            </Typography>
          </Box>
        </Box>
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: "10px",
              mt: "-20px",
              mr: "-20px",
            }}
          >
            <IconButton
              sx={{
                color: "gray",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ width: "100%" }}>
            <iframe
              style={{ width: "100%" }}
              height="315"
              src={`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </Box>

          {/* <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:1}}>
            <Button onClick={handleClose} style={{color:"rgba(27, 32, 44, 0.6)",fontSize:16,fontFamily:"Gilroy-Medium",textDecoration:"underline",textTransform:"capitalize"}}  variant="text">Go Back</Button>
          </Box> */}
        </Box>
      </Modal>
    </>
  );
}

export default MenuCard;
