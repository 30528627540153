import axios from "axios";
import { encryptData } from "./encryptionHelper";
import { call_api_function } from "./verifyAPICalls";
import { getCookiesList } from "./cookiesHelper";
import { serverRequest2 } from "./requestHelper";
import report_es2en from "./locales/es2en.json";

export const timeSleep = (milliseconds = 7000) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};


export const getPersonalization = async (cupon, cookieKey="userInfo") => {
  const cookiesList = getCookiesList(cookieKey);
  if (cookiesList.length > 0 && cookiesList[3] != undefined) {
    const email = cookiesList[3];
    const TipoType = cupon === "SB" || cupon === "S.B." ? "Without Bureau"  : "With Bureau";
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("TipoType", TipoType);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get_personalization",
        formdata
      );
      if (
        TipoType === "Without Bureau" &&
        response.data["G"] &&
        response.data["G"]["III"] !== undefined
      ) {
        response.data["G"]["III"] = false;
      }
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
};

export const savePersonalization = async (email, TipoType, personalization, isForAllUsers = false) => {
  const formdata = new FormData();
  formdata.append("email", email);
  formdata.append("TipoType", TipoType);
  formdata.append("personalization", JSON.stringify(personalization));
  try {
    const response = await call_api_function(
      process.env.REACT_APP_BASE_URL2 + (isForAllUsers ? "customize_all_users" : "save_personalization"),
      formdata
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const checkCurpZero = async (_id) => {
  let formData = new FormData();
  formData.append("From", "Submit");
  formData.append("_id", _id);
  let URL_upload_cvs =
    process.env.REACT_APP_BASE_URL2 + "check_final_curp_zero";
  try {
    const response = await axios.post(URL_upload_cvs, formData);
    if (response.status == 200) {
      return response.data;
    }
  } catch (err) {}
};

export const checkCupons = async (bureauCase, currentUserEmail) => {
  try {
    const checkCuponsResponse = await serverRequest2(
      "post",
      "perfil/checar_cupones",
      { email: currentUserEmail }
    );
    var cupones = checkCuponsResponse.data;
    if (document.getElementById("SB") && cupones.SB) {
      document.getElementById(
        "SB"
      ).innerText = `${cupones.SB?.disponible}/${cupones.SB?.total}`;
    }
    if (document.getElementById("CB") && cupones.CB) {
      document.getElementById(
        "CB"
      ).innerText = `${cupones.CB?.disponible}/${cupones.CB?.total}`;
    }
    if (cupones[bureauCase]["disponible"] > 0) {
      // Comprobar cupones disponibles o si efectuó modal de pago
      if (bureauCase == "CB") {
        return "CB";
      } else if (bureauCase == "SB") {
        return "SB";
      }
    }
    return "error";
  } catch (error) {
    throw error;
  }
};

export const checkGuestUserCupons = async () => {
  const userCookiesList = getCookiesList("guestUserInfo");
  const data_ecn = encryptData(userCookiesList[4], 500);
  const data_eel = encryptData(userCookiesList[3], 500);
  var dataForm_ = {
    data_ecn: {
      data_ecni: data_ecn["iv"],
      data_ecns: data_ecn["salt"],
      data_ecnc: data_ecn["ct"],
    },
    data_eel: {
      data_eeli: data_eel["iv"],
      data_eels: data_eel["salt"],
      data_eelc: data_eel["ct"],
    },
  };
  try {
    const response = await serverRequest2(
      "post",
      "validar_cupon_invitado",
      dataForm_
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const userCupons = async (bureauCase, currentUserEmail) => {
  try {
    var dataCupon = new FormData();
    dataCupon.append("data_etcn", bureauCase);
    dataCupon.append("data_eel", currentUserEmail);
    const checkCuponsResponse = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "perfil/usar_cupon",
      dataCupon,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    var cupones = checkCuponsResponse.data;
    if (document.getElementById("SB") && cupones.SB) {
      document.getElementById(
        "SB"
      ).innerText = `${cupones.SB?.disponible}/${cupones.SB?.total}`;
    }
    if (document.getElementById("CB") && cupones.CB) {
      document.getElementById(
        "CB"
      ).innerText = `${cupones.CB?.disponible}/${cupones.CB?.total}`;
    }
    if (checkCuponsResponse.data.CB && checkCuponsResponse.data.SB) {
      return cupones;
    }
    return "error";
  } catch (error) {
    console.error("Error:", error);
    return "error";
  }
};

export const verReport = async (id_sujeto_legal) => {
  const formData = new FormData();
  formData.append("vlid", id_sujeto_legal);
  try {
    return await axios.post(
      process.env.REACT_APP_BASE_URL2 + "ver_reporte",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  } catch (error) {
    return error;
  }
};
export const imprimir = async (
  resultados,
  email,
  secciones,
  bureauCase,
  isGuest
) => {
  let usercookie = getCookiesList("userInfo");
  try {
    resultados["email_usuario"] = email;
    resultados["tipo_cupon"] = bureauCase;
    resultados["secciones"] = secciones;
    if (isGuest) {
      resultados["logo"] =
        "https://searchlook.s3-us-west-2.amazonaws.com/compartir/logoSearchlookGarangou.png";
      resultados["mensaje_contacto_footer"] =
        "Si tienes alguna pregunta, escríbenos a contacto@searchlook.mx o llama al 55 3332 6533";
    } else {
      if (usercookie[14] == "") {
        resultados["logo"] =
          "https://searchlook.s3-us-west-2.amazonaws.com/compartir/logoSearchlookGarangou.png";
      } else {
        resultados["logo"] = usercookie[14];
      }

      if (usercookie[16] != "" && usercookie[17] != "") {
        resultados["mensaje_contacto_footer"] =
          "Si tienes alguna pregunta, escríbenos a " +
          usercookie[16] +
          " o llama al " +
          usercookie[17];
      } else if (usercookie[16] == "" && usercookie[17] != "") {
        resultados["mensaje_contacto_footer"] =
          "Si tienes alguna pregunta, llama al " + usercookie[17];
      } else if (usercookie[16] != "" && usercookie[17] == "") {
        resultados["mensaje_contacto_footer"] =
          "Si tienes alguna pregunta, escríbenos a " + usercookie[16];
      } else {
        resultados["mensaje_contacto_footer"] = "-";
      }
    }
    if (
      /@workus.com.mx\s*$/.test(email) ||
      /@michaelpage.com.mx\s*$/.test(email) ||
      /@pageinterim.com.mx\s*$/.test(email) ||
      /@pagepersonnel.com.mx\s*$/.test(email)
    ) {
      var excel_data = { data: resultados };
      await axios.post("api/excel_report", excel_data).then((response) => {});
    }
    await axios.post(
      process.env.REACT_APP_BASE_URL2 + "imprimir_v1",
      resultados,
      { headers: { "Content-Type": "application/json" } }
    );
  } catch (error) {
    throw error;
  }
};

export const getEspera = async (oid) => {
  let isDataReady = "";
  const startedAt = new Date();
  const maxWaitFor = 50000;

  while (isDataReady != "ready" && new Date() - startedAt < maxWaitFor) {
    // Wait for 5 seconds before checking again
    await timeSleep(5000);
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + `espera?oid=${oid}`
      );
      isDataReady = response.data.status;
    } catch (error) {
      // Handle any errors that occur while checking
      console.log("Error checking data readiness:", error);
    }
  }

  if (isDataReady == "waiting") {
    console.log("Data not ready after 55 seconds.");
  }
};

export const getReport = async (
  numeroCookie,
  bureauCase,
  data,
  isGuest,
  vistosReporte,
  vl_id
) => {
  const userCookies = getCookiesList("userInfo");
  var dataBuro = {
    numero: numeroCookie,
    a_expiration: "2024",
    m_expiration: "12",
    cvc: "123",
  };
  var dataSend = new FormData();
  dataSend.append("_id", data._id);
  dataSend.append("number", dataBuro.numero);
  dataSend.append("exp_year", dataBuro.a_expiration);
  dataSend.append("exp_month", dataBuro.m_expiration);
  dataSend.append("cvc", dataBuro.cvc);
  dataSend.append("email", data.email);
  dataSend.append("zona", data.zona);
  dataSend.append("sucursal", data.sucursal);
  dataSend.append("staff", data.staff);
  dataSend.append("cliente", data.cliente);
  dataSend.append("filtro_legal", data.filtro_legal);
  dataSend.append("nombre", data.nombre);
  dataSend.append("colonia", data.colonia);
  dataSend.append("estado", data.estado);
  dataSend.append("municipio", data.municipio);
  dataSend.append("vl_id", vl_id);
  dataSend.append("lang", localStorage.getItem("selectedLanguage") || "es");
  dataSend.append("is_guest", isGuest);

  const endpoint = bureauCase == "CB" ? "bp" : "vsb";
  try {
    await getEspera(data._id);
    const bpResponse = await axios.post(
      process.env.REACT_APP_BASE_URL2 + endpoint,
      dataSend,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    var resultados = bpResponse.data.dicok;
    resultados["nombre"] = data["nombre"];
    if (resultados.CURP == "CURP bloqueada") {
      resultados.Numero_Despidos = "No disponible";
      resultados.Demandas_Laborales = "No disponible";
      resultados.Demandas_Civiles = "No disponible";
      resultados.Demandas_Familiares = "No disponible";
      resultados.Demandas_Penales = "No disponible";
      resultados.Demandas_Administrativas = "No disponible";
    } else {
      if (resultados["Demandas_Laborales_Arreglo"].length == 0) {
        resultados["Demandas_Laborales_Arreglo"] = [
          { Demandado: "---", Expediente: "---", Instancia: "---" },
        ];
      }
      if (resultados["Demandas_Civiles_Arreglo"].length == 0) {
        resultados["Demandas_Civiles_Arreglo"] = [
          { Actor: "---", Expediente: "---", Instancia: "---", Tipo: "---" },
        ];
      }
      if (resultados["Demandas_Penales_Arreglo"].length == 0) {
        resultados["Demandas_Penales_Arreglo"] = [
          { Actor: "---", Expediente: "---", Instancia: "---", Tipo: "---" },
        ];
      }
      if (resultados["Demandas_Familiares_Arreglo"].length == 0) {
        resultados["Demandas_Familiares_Arreglo"] = [
          { Actor: "---", Expediente: "---", Instancia: "---", Tipo: "---" },
        ];
      }
      if (resultados["Demandas_Administrativas_Arreglo"].length == 0) {
        resultados["Demandas_Administrativas_Arreglo"] = [
          { Actor: "---", Expediente: "---", Instancia: "---", Tipo: "---" },
        ];
      }
    }

    if (isGuest) {
      const subsections_dict_invitado = {
        A: true,
        B: true,
        C: true,
        D: false,
        E: true,
        F: true,
        G: true,
        sub_a1: true,
        sub_a2: true,
        sub_a3: true,
        sub_a4: true,
        sub_a5: true,
        sub_a6: true,
        sub_a7: true,
        sub_a8: true,
        sub_a9: true,
        sub_b1: true,
        sub_b2: true,
        sub_b3: true,
        sub_b4: true,
        sub_b5: true,
        sub_b6: true,
        sub_b7: true,
        sub_b8: true,
        sub_b9: true,
        sub_b10: true,
        sub_c1: true,
        sub_c2: true,
        sub_c3: true,
        sub_c4: true,
        sub_c5: true,
        sub_c6: true,
        sub_c7: true,
        sub_c8: true,
        sub_d1: false,
        sub_d2: false,
        sub_d3: false,
        sub_d4: false,
        sub_d5: false,
        sub_d6: false,
        sub_d7: false,
        sub_d8: false,
        sub_d9: false,
        sub_e1: true,
        sub_e2: true,
        sub_e3: true,
        sub_e4: true,
        sub_e5: true,
        sub_f1: true,
        sub_f2: true,
        sub_f3: true,
        sub_f4: true,
        sub_f5: true,
        sub_f6: true,
        sub_g1: true,
        sub_g2: true,
        sub_g3: false,
        sub_g4: true,
      };
      const userCookiesList = getCookiesList("guestUserInfo");
      const data_ecn = encryptData(userCookiesList[4], 500);
      var dataForm_ = {
        data_ecn: {
          data_ecni: data_ecn["iv"],
          data_ecns: data_ecn["salt"],
          data_ecnc: data_ecn["ct"],
        },
        token: userCookiesList[0],
      };
      const applicar_response = await serverRequest2(
        "post",
        "aplicar_cupon_invitado",
        dataForm_
      );
      if (
        applicar_response.data == "error bd" ||
        applicar_response.data == "undefined"
      ) {
        return "Connection Error";
      } else if (applicar_response.data === false) {
        return "Coupon attempts have been exhausted, please try another";
      }
    }
    return resultados;
  } catch (error) {
    throw error;
  }
};

export const validateCupons = async (email, guestCupon, data, id_vl) => {
  const data_eel = encryptData(email, 500);
  const data_ecn = encryptData(guestCupon, 500);
  var dataForm_ = {
    data_eel: {
      data_eeli: data_eel["iv"],
      data_eels: data_eel["salt"],
      data_eelc: data_eel["ct"],
    },
    data_ecn: {
      data_ecni: data_ecn["iv"],
      data_ecns: data_ecn["salt"],
      data_ecnc: data_ecn["ct"],
    },
  };
  const response = await serverRequest2(
    "post",
    "validar_cupon_invitado",
    dataForm_
  );
  try {
    if (response.data) {
      if (parseInt(response.data[2]) > 0) {
        const results = await getReport(
          guestCupon,
          "SB",
          data,
          true,
          undefined,
          id_vl
        );
        if (results == "Connection Error") {
          throw new Error("Connection Error");
        }
        return results;
      } else {
        throw "Invalid cupons";
      }
    }
  } catch (error) {
    throw error;
  }
};

export const translationHelper = async (resultados) => {
  const formData = new FormData();
  formData.append("input_dict", JSON.stringify(resultados));
  try {
    const t_res = await call_api_function(
      process.env.REACT_APP_BASE_URL2 + "translate_report",
      formData
    );
    if (t_res.data != "error bd" && typeof t_res.data === "object") {
      return t_res.data;
    } else {
      throw "Translation Api failed";
    }
  } catch (error) {
    throw error;
  }
};

function reportTranslate(translationMap, text) {
  if (!(text in translationMap)) {
    if (report_es2en.hasOwnProperty(text)) {
      translationMap[text] = report_es2en[text];
    } else {
      const parts = text.split(" / ").map((part) => {
        const trimmed = part.trim();
        if (report_es2en.hasOwnProperty(trimmed)) {
          return report_es2en[trimmed];
        } else {
          return trimmed
            .split(" ")
            .map((word) => {
              const trimmedWord = word.trim();
              return report_es2en.hasOwnProperty(trimmedWord)
                ? report_es2en[trimmedWord]
                : trimmedWord;
            })
            .join(" ");
        }
      });
      translationMap[text] = parts.join(" / ");
    }
  }
  return translationMap[text];
}

function collectStrings(data, strings, doTranslate) {
  if (typeof data === "string") {
    strings.push(data);
  } else if (Array.isArray(data)) {
    data.forEach((item) => collectStrings(item, strings, doTranslate));
  } else if (typeof data === "object" && data !== null) {
    Object.keys(data).forEach((key) => {
      if (doTranslate(key)) {
        collectStrings(data[key], strings, doTranslate);
      }
    });
  }
}

function doTranslate(text) {
  if (text.startsWith("Demandas_") && text.endsWith("_Arreglo")) return false;
  const noTranslateFields = [
    "_id",
    "userEmail",
    "Nombre",
    "Empleador",
    "Estado",
    "CURP",
    "RFC",
    "colonia",
  ];
  return !noTranslateFields.includes(text);
}

export const translateNestedDict = (
  nestedDict,
  targetLanguage = "en",
  sourceLanguage = "es"
) => {
  const stringsToTranslate = [];
  collectStrings(nestedDict, stringsToTranslate, doTranslate);
  const translationMap = {};
  stringsToTranslate.forEach((string) =>
    reportTranslate(translationMap, string)
  );

  function replaceStrings(data) {
    if (typeof data === "string") {
      return translationMap.hasOwnProperty(data) ? translationMap[data] : data;
    } else if (Array.isArray(data)) {
      return data.map((item) => replaceStrings(item));
    } else if (typeof data === "object" && data !== null) {
      const newData = {};
      Object.keys(data).forEach((key) => {
        newData[key] = doTranslate(key) ? replaceStrings(data[key]) : data[key];
      });
      return newData;
    }
    return data;
  }
  return replaceStrings(nestedDict);
};
