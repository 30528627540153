import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Box, Stack, IconButton, Alert } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import InputRightIcon from "../../@common/Input/InputRightIcon/InputRightIcon";
import personIcon from "../../../assets/images/person.svg";
import DatePickerInput from "../../@common/Input/DatePicker/DatePickerInput";
import DropDownInputSimple from "../../@common/Input/DropDownInputSimple/DropDownInputSimple";
import DropDown from "../../@common/Input/DropDownInputSimple/DropDown";
import LabelInput from "../../@common/Input/LabelInput";
import RadioButtonsGroup from "../../@common/Input/radios";
import Button from "@mui/material/Button";
import consultUpload from "../../../../src/assets/images/consultUpload.svg";
import GenerateQueryModal from "../../@common/Modal/GenerateQueryModal";
import GenerateQueryModalConfirmation from "../../@common/Modal/GenerateQueryModalConfirmation";
import WithBureauQueryConfirmationModal from "../../@common/Modal/WithBureauModal/WithBureauQueryConfirmationModal";
import EmailSuccessfullySent from "../../@common/Modal/EmailSuccessfullySent";
import ThankYouMessage from "../../@common/Modal/ThankYouMessage";
import LogoModalLoader from "../../@common/Modal/WithBureauModal/LogoModalLoader";
import WithBureauQueryModal from "../../@common/Modal/WithBureauModal/WithBureauQueryModal";
import mailImage from "../../../assets/images/mailIcon.png";
import phoneIcon from "../../../assets/images/phoneIcon.png";
import sjcl from "sjcl";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  AesEncrypt,
  getKeysSaltAndIv,
  setKeysDict,
  encryptCurpZeroPayload,
  prepareCurpPayload,
} from "../../../utils/encryptionHelper";
import { options_edo } from "../../../utils/optionsEdo";
import { getCookiesList } from "../../../utils/cookiesHelper";
import {
  timeSleep,
  checkCurpZero,
  checkCupons,
} from "../../../utils/bureauHelper";
import { formatDate } from "../../../utils/dateFormatHelper";
import GuestTermsAndConditionModal from "../../@common/Modal/WithBureauModal/GuestTermsAndConditionModal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { call_api_function } from "../../../utils/verifyAPICalls";
import FormHelperText from "@mui/material/FormHelperText";
import AlertModal from "../../@common/Modal/AlertModal";
import AutoCompleteModal from "../../@common/Modal/AutoCompleteModal";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import "./ToggleButton.css";
import AutocompleteBlue from "../../../assets/images/Autocomplete Arrow Blue.svg";
import Autocompleteorange from "../../../assets/images/Autocomplete Arrow Orange.svg";
import { serverRequest, serverRequest2 } from "../../../utils/requestHelper";
import { encryptData } from "../../../utils/encryptionHelper";
import SwitchesCustomized from "./SwitchButton";
import { DropzoneArea } from "material-ui-dropzone";
import {
  AttachFile,
  Description,
  FlashAuto,
  PictureAsPdf,
  Theaters,
} from "@mui/icons-material";
import pdfImage from "../../../assets/images/pdfImage.png";
import LabelInputLeftIcon from "../../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import {
  validateInput,
  validatePassword,
  validatePhoneNumber,
} from "../../../utils/inputValidation";
import { emailPattern } from "../../../utils/InputPatterns";
import deleteFile from "../../../../src/assets/images/deletefile.svg";
import previewFile from "../../../../src/assets/images/previewFile.svg";
import textDoc from "../../../../src/assets/images/textDoc.png";
import DocumentPreview from "./DocumentPreview";
import Webcam from "react-webcam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CaptureModal from "./CaptureModal";
const sexTypeList = ["Feminine", "Male"];

function WithBureauQueryForm() {
  const { t } = useTranslation();
  const canidateType = [
    { label: t("thanks.Executive Staff"), value: "Executive_Staff" },
    { label: t("thanks.Operational Staff"), value: "Operational_Staff" },
  ];

  const sexTypeList = [
    { label: t("labels.radioLabelFemale"), value: "Feminine" },
    { label: t("labels.radioLabelMale"), value: "Male" },
    { label: t("labels.radioLabelNonBinary"), value: "Non-Binary" },
  ];

  const activateLegalFilterList = [
    { label: t("labels.inputLabelYes"), value: "Yes" },
    { label: t("labels.inputLabelNo"), value: "No" },
  ];

  const multiReportTypeList = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  options_edo[0].name = t("placeholders.ddEdonto");

  var subsections_dict = {
    A: true,
    B: true,
    C: true,
    D: true,
    E: true,
    F: true,
    G: true,
    sub_a1: true,
    sub_a2: true,
    sub_a3: true,
    sub_a4: true,
    sub_a5: true,
    sub_a6: true,
    sub_a7: true,
    sub_a8: true,
    sub_a9: true,
    sub_b1: true,
    sub_b2: true,
    sub_b3: true,
    sub_b4: true,
    sub_b5: true,
    sub_b6: true,
    sub_b7: true,
    sub_b8: true,
    sub_b9: true,
    sub_b10: true,
    sub_c1: true,
    sub_c2: true,
    sub_c3: true,
    sub_c4: true,
    sub_c5: true,
    sub_c6: true,
    sub_c7: true,
    sub_c8: true,
    sub_d1: true,
    sub_d2: true,
    sub_d3: true,
    sub_d4: true,
    sub_d5: true,
    sub_d6: true,
    sub_d7: true,
    sub_d8: true,
    sub_d9: true,
    sub_e1: true,
    sub_e2: true,
    sub_e3: true,
    sub_e4: true,
    sub_e5: true,
    sub_f1: true,
    sub_f2: true,
    sub_f3: true,
    sub_f4: true,
    sub_f5: true,
    sub_f6: true,
    sub_g1: true,
    sub_g2: true,
    sub_g3: true,
    sub_g4: true,
  };

  const [formErrors, setFormErrors] = useState({
    candidate_Email: "",
    mobile_number: "",
    firmaPassword: "",
  });

  // cookie state
  const [cookieList, setCookieList] = useState([]);
  const [emailCookie, setEmailCookie] = useState("");
  // Modals states
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [DataSend, setDataSend] = useState({});
  const [showAlertModal, setShowAlertModal] = useState(false); // Add a state to control the visibility of the alert
  const [loadFunction, setLoadFunction] = useState(true);
  const [showtext, setShowtext] = useState("");
  const [appendData, setapeendData] = useState(false);
  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
    setLoadFunction(true);
  };
  const handleAcceptAlert = () => {
    setLoadFunction(false);
    setShowAlertModal(false);
    const gender =
      sexTypedata === "M"
        ? "Feminine"
        : sexTypedata === "X"
        ? "Non-Binary"
        : "Male";
    setCurp(curpACdata);
    setName(namedata);
    setLastName(last_namedata);
    setMotherLastName(mother_last_namedata);
    setSexType(gender);
    setDateOfBrith(date_of_brithdata);
    setBirthState(birthStatedata);
    updateDisabledFields();
  };

  const updateDisabledFields = (ac_type = "") => {
    if ((ac_type || ACType) == "curp") {
      setDisableCURP(false);
      setDisableName(true);
      setDisableLastName(true);
      setDisableMotherLastName(true);
      setDisableSexType(true);
      setDisableDateOfBirth(true);
      setDisableBirthState(true);
    } else {
      setDisableCURP(true);
      setDisableName(false);
      setDisableLastName(false);
      setDisableMotherLastName(false);
      setDisableSexType(false);
      setDisableDateOfBirth(false);
      setDisableBirthState(false);
    }
  };

  const [ssnValid, setSsnValid] = useState(false);
  const [cedulaValid, setCedulaValid] = useState(false);
  const [infoAccValid, setInfoAccValid] = useState(false);
  const [candidatePassValid, setCandidatePassValid] = useState(false);
  const [certifyValid, setcertifyValid] = useState(false);
  const [revalidValid, setRevalidValid] = useState(false);
  const [candidateEmailValid, setCandidateEmailValid] = useState(false);
  const [candidatePhoneValid, setCandidatePhoneValid] = useState(false);

  //for dcz
  const [dczCurp, setDczCurp] = useState(null);
  const [dczCurpId, setDczCurpId] = useState(null);
  const [dczCurpDoc, setDczCurpDoc] = useState([]);
  const [documentStatus, setDocumentStatus] = useState({});
  const [nbcId, setNbcId] = useState("");

  const [mandatoryOne, setMandatoryOne] = useState(false);
  const [mandatoryTwo, setMandatoryTwo] = useState(false);

  // Form data
  const [name, setName] = useState("");
  const [curp, setCurp] = useState("");
  const [last_name, setLastName] = useState("");
  const [mother_last_name, setMotherLastName] = useState("");
  const [sexType, setSexType] = useState("Feminine");
  const [date_of_brith, setDateOfBrith] = useState(""); // fecha de nacimiento
  const [birthState, setBirthState] = useState("State");
  const [legalFiter, setLegalFiter] = useState("Yes");
  const [folio1, setFolio1] = useState("");
  const [folio2, setFolio2] = useState("");
  const [curpACdata, setCurpACdata] = useState("");
  const [namedata, setNamedata] = useState("");
  const [last_namedata, setLastNamedata] = useState("");
  const [mother_last_namedata, setMotherLastNamedata] = useState("");
  const [sexTypedata, setSexTypedata] = useState("");
  const [CandidateTypedata, setCandidateTypedata] = useState("");
  const [date_of_brithdata, setDateOfBrithdata] = useState(""); // fecha de nacimiento
  const [birthStatedata, setBirthStatedata] = useState("");

  const [ACType, setACType] = useState("");
  const [disableCURP, setDisableCURP] = useState(true);
  const [disableName, setDisableName] = useState(true);
  const [disableLastName, setDisableLastName] = useState(true);
  const [disableMotherLastName, setDisableMotherLastName] = useState(true);
  const [disableSexType, setDisableSexType] = useState(true);
  const [disableDateOfBirth, setDisableDateOfBirth] = useState(true);
  const [disableBirthState, setDisableBirthState] = useState(true);

  const [isNSSValid, setIsNSSValid] = useState(false);
  const [isCedulaValid, setIsCedulaValid] = useState(false);
  const [isFolio1Valid, setIsFolio1Valid] = useState(false);

  const [curpData, setcurpData] = useState("");
  const [estadoData, setestadoData] = useState("");
  const [nssData, setnssData] = useState("");
  const [nss1Data, setnss1Data] = useState("");
  const [cname, setcname] = useState("");
  const [edad, setedad] = useState("");
  const [showHomonyms, setHomonyms] = useState(false);

  const [selectClientdata, setselectClient] = useState("");
  const [Clientdata, setClientdata] = useState("");
  const [zonedata, setzonedata] = useState("");
  const [selectzonedata, setselectzonedata] = useState("");
  const [branchesdata, setbranchesdata] = useState("");
  const [selectbranchesdata, setselectbranchesdata] = useState("");
  // const [multiReportType, setMultiReportType] = useState('With Bureau');
  const [nbdata, setNBData] = useState({});
  const [isAutoComplete, setIsAutoComplete] = useState(false); // auto complete state
  //Optional Form data
  const [sns, setSnS] = useState(""); //nss
  const [id, setId] = useState(""); //cedula

  const [curpFocus, setCurpFocus] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [motherLastNameFocus, setMotherLastNameFocus] = useState(false);
  const [curpChanged, setCurpChanged] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [motherLastNameChanged, setMotherLastNameChanged] = useState(false);
  const [dateOfBrithChanged, setDateOfBrithChanged] = useState(false);
  const [birthStateChanged, setBirthStateChanged] = useState(false);

  //when with bureau data
  const [street, setStreet] = useState(""); //calle
  const [number, setNumber] = useState(""); //number
  const [cologne, setCologne] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("State"); //estada

  const [formValid, setFormValid] = useState(false); // Tracks if the form is ready

  const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
  const [customeReport, setCustomReport] = useState(false);
  const [subsectionDicts, setSubSectionDicts] = useState(subsections_dict);

  const [snsFlag, setSnsFlag] = useState(false);
  const [snsError, setSnsError] = useState("");

  const [postalCodeFlag, setPostalCodeFlag] = useState(false);

  const [idFlag, setIdFlag] = useState(false);
  const [idError, setIdError] = useState("");
  const [infoFlag, setInfoFlag] = useState(false);
  const [infoError, setInfoError] = useState("Field Required");

  const [fileList, setFileList] = useState([]);

  const [showWebcam, setShowWebcam] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [dropError, setDropError] = useState("");
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [infoAccount, setInfoAccount] = useState("");
  const [infoPassword, setInfoPassword] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateNumber, setCandidateNumber] = useState("");
  const [certificateData, setCertificateData] = useState("");
  const [revalidData, setRevalidData] = useState("");
  const [showTextField, setShowTextField] = useState(false);

  const [eFirma, setEFirma] = useState("Yes");
  const [eFirmaPassword, setEFirmaPassword] = useState("");
  const [isPasswordActive, setIsPasswordActive] = useState(true);
  const [firmaPasswordValid, setFirmaPasswordValid] = useState(false);
  const [eFirmaDocuments, setEFirmaDocuments] = useState({
    cer: { exists: false, s3: null },
    key: { exists: false, s3: null },
  });

  const [showGeoLocationField, setShowGeoLocationField] = useState(false);
  const [showFaceIDField, setShowFaceIDField] = useState(false);
  const isNameEmpty = name.trim() === "";
  const isLastNameEmpty = last_name.trim() === "";
  const isFolioFirstEmpty = folio1.trim() === "";
  const isFolioSecondEmpty = folio2.trim() === "";
  const isMotherNameEmpty = mother_last_name.trim() === "";
  const isbirthState =
    birthState.trim() === "State" || birthState.trim() === "Estado";
  const dateOfBirthPattern =
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|20[0-9]{2})$/;
  const isDateOfBirthValid =
    date_of_brith.trim() !== "" && dateOfBirthPattern.test(date_of_brith);
  const isDateBirthEmpty = !isDateOfBirthValid;
  const isStreetEmpty = street.trim() === "";
  const isPostalCodeEmpty = postalCode.trim() === "";
  const isCologneEmpty = cologne.trim() === "";
  const isStateEmpty = state.trim() === "State";
  const isMunicipalityEmpty = municipality.trim() === "";
  const handleCheckboxChange = (event) => {
    setShowTextField(event.target.checked);
    setSnsFlag(false);
    setInfoFlag(false);
    setSsnValid(false);
    setCedulaValid(false);
    setInfoAccValid(false);
    setCandidatePassValid(false);
    setcertifyValid(false);
    setRevalidValid(false);
    setCandidateEmailValid(false);
    setCandidatePhoneValid(false);
    setMandatoryOne(false);
    setMandatoryTwo(false);
    setEFirmaPassword("");
    setFirmaPasswordValid(false);
    setCandidateEmail("");
    setCandidateNumber("");
    setInfoAccount("");
    setInfoPassword("");
    setEFirma("Yes");
    setIsPasswordActive(true);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      firmaPassword: "", // Update only if there's an error
    }));
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleFocus = (inputId) => {
    if (inputId === "curp") {
      setCurpFocus(true);
      setCurpChanged(false);
    }
    if (inputId === "name") {
      setNameFocus(true);
      setNameChanged(false);
    } else if (inputId === "last_name") {
      setLastNameFocus(true);
      setLastNameChanged(false);
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(true);
      setMotherLastNameChanged(false);
    }
  };
  const handleBlur = (inputId) => {
    if (inputId === "curp") {
      setCurpFocus(false);
      if (curpChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "name") {
      setNameFocus(false);
      if (nameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "last_name") {
      setLastNameFocus(false);
      if (lastNameChanged) {
        // fetchAutoCompleteModal();
      }
    } else if (inputId === "mother_last_name") {
      setMotherLastNameFocus(false);
      if (motherLastNameChanged) {
        // fetchAutoCompleteModal();
      }
    }
  };
  const handleFolio1Change = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9,-]/g, "");
    let new_value = "";
    for (let folio of value.split(",")) {
      let new_folio = folio.replace(/-/g, "");
      if (new_folio.length > 8)
        new_folio = new_folio.slice(0, 8) + "-" + new_folio.slice(8);
      if (new_folio.length > 12)
        new_folio = new_folio.slice(0, 13) + "-" + new_folio.slice(13);
      if (new_folio.length > 16)
        new_folio = new_folio.slice(0, 18) + "-" + new_folio.slice(18);
      if (new_folio.length > 20)
        new_folio = new_folio.slice(0, 23) + "-" + new_folio.slice(23);
      new_folio = new_folio.replace(/-+$/, "");
      new_value +=
        (new_value ? "," : "") +
        (folio.includes(new_folio) ? folio : new_folio);
    }
    for (let i = 36; i < new_value.length; i += 37) {
      if (new_value[i] !== ",") {
        new_value = new_value.slice(0, i) + "," + new_value.slice(i);
      }
    }
    setFolio1(new_value);
    if (
      new_value.replace(/,/g, "").length > 0 &&
      new_value.replace(/,/g, "").length % 36 === 0
    ) {
      setIsFolio1Valid(true);
    } else {
      setIsFolio1Valid(false);
    }
  };
  const handleFolio2Change = (event) => {
    let value = event.target.value.replace(/\s/g, "");
    setFolio2(value);
  };

  useEffect(() => {
    if (cookieList.length != 0) {
      loadAutoComplete();
    }
  }, [cookieList]);
  // Effects
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
      setEmailCookie(userCookiesList[3]);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(DataSend).length !== 0) {
      getCurpZero(DataSend);
    }
  }, [DataSend]);
  useEffect(() => {
    if (subsectionDicts !== subsections_dict) {
      //setWithoutBureauModalOpen(true);
    }
  }, [subsectionDicts]);

  // First Modal handler
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  const email = "";
  const phone = "";
  const scl = "1"; // FIXME Sujeto confirmación legal

  //user
  const user_tjt = "NA";
  const user_master = "1";
  const user_customer = "qwert";
  const [multiReportType, setMultiReportType] = useState("Without Bureau");

  const [withoutBureauModalOpen, setWithoutBureauModalOpen] = useState(false);
  const [withBureauOpen, setWithBureauOpen] = useState(false);

  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [reportData, setReportData] = useState({});

  // modal open and close

  const withoutBureauHandler = () => {
    handleOpenLogoModal();
    encryptData1();
  };

  const openThankYouModal = (data) => {
    setReportData(data);
    setIsThankYouModalOpen(true);
  };

  const withBureauHandler = () => {
    handleOpenLogoModal();
    encryptData1();
    // setWithBureauOpen(true)
  };
  var transformedArray = [];
  const clientdata = async () => {
    const endpoint = process.env.REACT_APP_BASE_URL2 + "perfil/clientes";
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };
      const res = await serverRequest2("post", endpoint, enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        transformedArray = res.data.map((option) => {
          return { name: option };
        });
        setClientdata(transformedArray);
      } else {
      }
    } else {
    }
  };
  var zoneArray = [];
  const zoneapidata = async () => {
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };

      const res = await serverRequest("post", "perfil/di_zonas", enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        zoneArray = res.data.map((option) => {
          return { name: option };
        });
        setzonedata(zoneArray);
      } else {
      }
    } else {
    }
  };
  var branchArray = [];
  const branchdata = async () => {
    // Check if cookieList[6] is defined and not empty
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      var enc_id = {
        data_eid: {
          data_eidi: data_eid["iv"],
          data_eids: data_eid["salt"],
          data_eidc: data_eid["ct"],
        },
      };
      const res = await serverRequest("post", "perfil/sucursales", enc_id);
      // Check if res.data is an array before using .map()
      if (Array.isArray(res.data)) {
        // Handle the API response here
        branchArray = res.data.map((option) => {
          return { name: option };
        });
        setbranchesdata(branchArray);
      } else {
      }
    } else {
    }
  };

  useEffect(() => {
    if (cookieList.length != 0) {
      clientdata();
      zoneapidata();
      branchdata();
    }
  }, [cookieList]);
  //fine
  const handleCanidateType = useCallback((e) => {
    setCandidateTypedata(
      CandidateTypedata == e.target.value ? "" : e.target.value
    );
  });
  const legalFilterHandler = useCallback((e) => {
    setLegalFiter(legalFiter == e.target.value ? "" : e.target.value);
  });

  const multiReportTypeHandler = useCallback((e) => {
    setMultiReportType(multiReportType == e.target.value ? "" : e.target.value);
  });

  // Encrypt data for curp_zero, when querying from Panel
  const encryptData1 = () => {
    if (snsFlag || infoFlag) {
      setSnsError(t("thanks.ssnError"));
      setInfoError(t("thanks.infoError"));
      handleCloseLogoModal();
      return; // Ensure the function returns early
    }

    // if (showTextField) {
    //   const errors = {};
    //   validateInput(candidateEmail, emailPattern, "candidate_Email", errors, t);
    //   validatePhoneNumber(candidateNumber, "mobile_number", errors, t);
    //   setFormErrors(errors);
    //   // If there are any errors, do not proceed
    //   if (Object.keys(errors).length > 0) {
    //     handleCloseLogoModal()
    //     return;
    //   }
    // }

    // const consult_data_array =
    var filterLegalNo = "";
    var eFirmaValue = "";
    if (legalFiter === "Yes") {
      filterLegalNo = "Si";
    } else {
      filterLegalNo = "";
    }
    if (eFirma === "Yes") {
      eFirmaValue = "Si";
    } else {
      eFirmaValue = "No";
    }
    var genderNo = "";
    if (sexType === "Feminine") {
      genderNo = "M";
    } else if (sexType === "Non-Binary") {
      genderNo = "X";
    } else {
      genderNo = "H";
    }
    var staffValue = "";
    if (CandidateTypedata === "Executive_Staff") {
      staffValue = "ejecutivo";
    } else if (CandidateTypedata === "Operational_Staff") {
      staffValue = "operativo";
    }
    var arr_date_of_brith = [];
    const formateDateOfBirth = formatDate(date_of_brith);
    arr_date_of_brith = formateDateOfBirth.split("/");
    const day_birth = arr_date_of_brith[0];
    const month_birth = arr_date_of_brith[1] || "";
    const year_birth = arr_date_of_brith[2] || "";
    var userAgent = navigator.userAgent; //The user agent string contains information about the browser and operating system being used by the user to access the web page. It includes details such as the name and version of the browser, as well as the device and operating system being used.

    var keys = getKeysSaltAndIv();
    var subject_name = name + " " + last_name + " " + mother_last_name;
    var keys = getKeysSaltAndIv();
    const formData = {
      name: name,
      curp_zero: curp,
      last_name: last_name,
      mother_last_name: mother_last_name,
      day_birth: day_birth,
      month_birth: month_birth,
      year_birth: year_birth,
      genderNo: genderNo,
      sns: sns,
      id: id,
      scl: scl,
      email: email,
      phone: phone,
      birthState: birthState,
      street: street + " " + number,
      cologne: cologne,
      municipality: municipality,
      postal_code: postalCode,
      state: multiReportType === "Without Bureau" ? "NA" : state,
      filterLegalNo: filterLegalNo,
      geoLocation: showGeoLocationField ? "Si" : "",
      faceId: showFaceIDField ? "Si" : "",
      user_tjt: user_tjt,
      user_master: user_master,
      user_customer: user_customer,
      userAgent: navigator.userAgent,
      multiRepo: multiReportType === "Without Bureau" ? "SB" : "CB",
      cliente: selectClientdata,
      selectzonedata: selectzonedata,
      selectbranchesdata: selectbranchesdata,
      staff: staffValue,
      document: fileList.map((file) => ({
        ...file.response,
      })),
      infoAccount: infoAccount,
      infoPassword: infoPassword,
      certifyData: certificateData,
      revalidFolio: revalidData,
      candidateMail: candidateEmail,
      candidatePhone: candidateNumber,
      e_firma: eFirmaValue,
      contraseña_e_firma: eFirmaPassword,
      _id: nbcId,
    };

    const dS = prepareCurpPayload(formData, cookieList, subsections_dict);
    setDataSend(dS);
  };
  const getCurpZero = async (dataCurpZero) => {
    const cupon = multiReportType == "With Bureau" ? "CB" : "SB";
    const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
    if (checkCuponsResponse === "error") {
      handleCloseLogoModal();
      setShowAlert(true);
      let messageData;
      if (localStorage.getItem("selectedLanguage") == "en") {
        messageData =
          "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
      } else {
        messageData =
          "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
      }
      setShowtext(messageData);
      return;
    }
    const endpoint = process.env.REACT_APP_BASE_URL2 + "curp_zero";
    var continuarCurpZero = false;
    const response = await axios.post(endpoint, dataCurpZero, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.data.mensaje === "Guardado correctamente") {
      continuarCurpZero = true;
      nextStep(response.data._id, continuarCurpZero);
    }
  };

  const nextStep = async (insertedId, continuarCurpZero) => {
    if (continuarCurpZero === true) {
      try {
        await timeSleep();
      } catch (e) {
        setShowAlert(true);
        setShowtext("Desconexión de red. Continúa");
      }
      var i_curpzero = 0;
      var i_flag = false;
      while (i_flag === false) {
        var returnCurpZero = await checkCurpZero(insertedId);
        if (returnCurpZero.pending === false) {
          if (returnCurpZero.final_response.status === "1") {
            // Limpiar inputs
            /* Persona bloqueada */
            if (returnCurpZero.final_response.curps._id == "Bloqueado") {
              setShowAlert(true);
              setShowtext("Bloqueado");
            } else {
              var data = {
                _id: returnCurpZero.final_response.curps._id,
                nombre: returnCurpZero.final_response.cname,
                email: cookieList[3],
                sucursal: returnCurpZero.petition.sujeto.sucursal,
                zona: returnCurpZero.petition.sujeto.zona,
                staff: returnCurpZero.petition.sujeto.staff,
                filtro_legal: returnCurpZero.petition.sujeto.filtro_legal,
                cliente: returnCurpZero.petition.sujeto.cliente,
                estado: returnCurpZero.petition.sujeto.buro.estado,
                colonia: returnCurpZero.petition.sujeto.buro.colonia,
                municipio: returnCurpZero.petition.sujeto.buro.municipio,
              };
              setNBData(data);
              handleCloseLogoModal();
              validarTipoConsulta();
              setDczCurp(returnCurpZero.final_response.curps.curp);
              setDczCurpId(returnCurpZero.final_response.curps._id);
              setDczCurpDoc(returnCurpZero.petition.sujeto.documents);
              setDocumentStatus(returnCurpZero?.documents_status);
            }
          } else if (returnCurpZero.final_response.status === "varios") {
            setcurpData(returnCurpZero["final_response"]["curps"][0]["curp"]);
            setestadoData(
              returnCurpZero["final_response"]["curps"][0]["estado"]
            );
            setnssData(returnCurpZero["final_response"]["curps"][0]["nss"]);
            setnss1Data(returnCurpZero["final_response"]["curps"][1]["nss"]);
            setcname(returnCurpZero["final_response"]["curps"][0]["cname"]);
            setedad(returnCurpZero["final_response"]["curps"][0]["edad"]);
            setHomonyms(true);
            var data = {
              _id: returnCurpZero.final_response.curps[0]._id,
              nombre: returnCurpZero.final_response.cname,
              email: cookieList[3],
            };
            setNBData(data);
            handleCloseLogoModal();
            const sub_dict = resetSubsections("SB");
            setSubSectionDicts(sub_dict);
            validarTipoConsulta();
          } else if (
            returnCurpZero.final_response.error === "error_en_los_datos"
          ) {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
            handleCloseLogoModal();
          } else {
            // Borde naranja en inputs
            setShowAlert(true);
            setShowtext("else");
            handleCloseLogoModal();
          }
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
        } else if (returnCurpZero.pending === true) {
          try {
            await timeSleep();
          } catch (e) {
            setShowAlert(true);
            setShowtext("Desconexión de red. Continúa");
          }
        }
        i_curpzero++;
        if (i_curpzero === 20 && i_flag === false) {
          i_flag = true;
          insertedId = false;
          returnCurpZero = false;
          handleCloseLogoModal();
          setShowAlert(true);
          setShowtext(t("Dashboard.Validation"));
        }
      }
    }
  };

  const validarTipoConsulta = async () => {
    // decide which modal box to open
    const cupon = multiReportType == "With Bureau" ? "CB" : "SB";
    const checkCuponsResponse = await checkCupons(cupon, cookieList[3]);
    if (checkCuponsResponse === "error") {
      handleCloseLogoModal();
      setShowAlert(true);
      let messageData;
      if (localStorage.getItem("selectedLanguage") == "en") {
        messageData =
          "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
      } else {
        messageData =
          "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
      }
      setShowtext(messageData);
    }

    if (cupon == "CB") {
      if (!customeReport) {
        resetSubsections("CB");
      }
      setWithBureauOpen(true);
    } else if (cupon == "SB") {
      if (!customeReport) {
        const sub_dict = resetSubsections("SB");
        setSubSectionDicts(sub_dict);
      }
      setWithoutBureauModalOpen(true);
    }
  };

  const snsHandler = (e) => {
    let input = e.target.value;

    input = input.replace(/[^0-9,]/g, "");
    input = input.replace(/,{2,}/g, ",");
    input = input.replace(/(^,)/g, "");

    for (let i = 11; i < input.length; i += 12) {
      if (input[i] !== ",") {
        input = input.slice(0, i) + "," + input.slice(i);
      }
    }
    input = input.slice(0, 11 * 4 + 3);

    const values = input.split(",").filter(Boolean);

    if (values.length === 0) {
      // If there is no input, set ssnValid to false and clear SNS error
      setSnsFlag(false);
      setSnsError("");
      setSsnValid(false);
    } else if (values.length > 4) {
      setSnsFlag(true);
      setSnsError(t("thanks.ssnError"));
      setSsnValid(false);
    } else {
      const reg = /^[0-9]{11}$/;
      const allValid = values.every((value) => reg.test(value));
      if (allValid) {
        setSnsFlag(false);
        setSnsError("");
        setSnS(values);
        setSsnValid(true);
      } else {
        setSnsFlag(true);
        setSnsError(t("thanks.ssnError"));
        setSsnValid(false);
      }
    }
    // Update the input value to only include valid characters
    e.target.value = input;
  };

  const infonavithandler = (e) => {
    const reg = /^[0-9]{11}$/;
    let input = e.target.value;
    const validReg = reg.test(input);

    if (input.length === 0) {
      setInfoFlag(false);
      setInfoAccValid(false);
      setInfoError("field required"); // Clear the error
    } else if (validReg) {
      setInfoFlag(false);
      setInfoAccValid(true);
      setInfoError(""); // Clear the error
    } else {
      setInfoFlag(true);
      setInfoAccValid(false);
      setInfoError(t("thanks.infoError"));
    }

    setInfoAccount(input); // Update the state with the input value
  };

  const emailhandler = (e) => {
    const input = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email pattern

    // Check if the email matches the pattern
    if (input.length === 0) {
      setCandidateEmailValid(false);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        candidate_Email: "",
      }));
    } else if (!emailPattern.test(input)) {
      setCandidateEmailValid(false);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        candidate_Email: t("consultField.emailvalid"),
      }));
    } else {
      setCandidateEmailValid(true);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        candidate_Email: "", // Clear the error if the email is valid
      }));
    }

    setCandidateEmail(input);
  };

  const cellPhoneHandler = (e) => {
    const input = e.target.value;
    const mobilePattern = /^[0-9]{10}$/; // Adjust the pattern as needed

    if (input.length === 0) {
      setCandidatePhoneValid(false); // Invalid if input is empty
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobile_number: "",
      }));
    } else if (mobilePattern.test(input)) {
      setCandidatePhoneValid(true); // Valid if matches the pattern
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobile_number: "",
      }));
    } else {
      setCandidatePhoneValid(false); // Invalid if doesn't match the pattern
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobile_number: t("consultField.candidatePhoneValid"),
      }));
    }

    setCandidateNumber(input); // Update the phone number state
  };

  const idHandler = (e) => {
    const reg = /^[0-9]*$/; // Regular expression to check for numbers only
    const input = e.target.value.trim(); // Remove leading and trailing spaces
    const validReg = reg.test(input);

    if (input.length === 0) {
      setIdFlag(false);
      setCedulaValid(false);
      setIdError("");
    } else if (validReg) {
      // If input is valid (only numbers), check for minimum length
      if (input.length >= 6) {
        // If input has at least 6 characters, set cedulaValid to true
        setIdFlag(false);
        setCedulaValid(true);
        setIdError("");
      } else {
        // If input has less than 6 characters, show error
        setIdFlag(true);
        setCedulaValid(false);
        setIdError(t("thanks.enternumber")); // Custom error message for minimum length
      }
    } else {
      // If input is invalid (not numbers), show error
      setIdFlag(true);
      setCedulaValid(false);
      setIdError(t("thanks.enternumber"));
    }
    setId(input); // Update the input state
  };

  const candidatePasshandler = (e) => {
    const input = e.target.value.replace(/\s/g, "");
    if (input.length === 0) {
      setCandidatePassValid(false);
    } else {
      setCandidatePassValid(true);
    }
    setInfoPassword(input); // Update the password state
  };

  const certifyDatahandler = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9,-]/g, "");
    let new_value = "";
    for (let folio of value.split(",")) {
      let new_folio = folio.replace(/-/g, "");
      if (new_folio.length > 8)
        new_folio = new_folio.slice(0, 8) + "-" + new_folio.slice(8);
      if (new_folio.length > 12)
        new_folio = new_folio.slice(0, 13) + "-" + new_folio.slice(13);
      if (new_folio.length > 16)
        new_folio = new_folio.slice(0, 18) + "-" + new_folio.slice(18);
      if (new_folio.length > 20)
        new_folio = new_folio.slice(0, 23) + "-" + new_folio.slice(23);
      new_folio = new_folio.replace(/-+$/, "");
      new_value +=
        (new_value ? "," : "") +
        (folio.includes(new_folio) ? folio : new_folio);
    }
    for (let i = 36; i < new_value.length; i += 37) {
      if (new_value[i] !== ",") {
        new_value = new_value.slice(0, i) + "," + new_value.slice(i);
      }
    }
    setCertificateData(new_value);
    if (
      new_value.replace(/,/g, "").length > 0 &&
      new_value.replace(/,/g, "").length % 36 === 0
    ) {
      setcertifyValid(true);
    } else {
      setcertifyValid(false);
    }
  };
  const revalidDatahandler = (e) => {
    let value = e.target.value.replace(/\s/g, "");
    setRevalidData(value);
    setRevalidValid(true);
  };

  const eFirmaHandler = (event) => {
    const value = event.target.value;
    setEFirma(value);

    if (value === "Yes") {
      setIsPasswordActive(true); // Activate password input
    } else {
      setIsPasswordActive(false); // Deactivate password input
      setEFirmaPassword("");
      setFirmaPasswordValid(false);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firmaPassword: "", // Update only if there's an error
      }));
    }
  };

  const EFirmaPasshandler = (e) => {
    const input = e.target.value.replace(/\s/g, "");
    setEFirmaPassword(input);

    // Validate password: only check if it has 8 or more characters
    const isValid = input.length >= 1;

    // Update password validity state
    setFirmaPasswordValid(isValid);

    // Update form errors
    // setFormErrors((prevErrors) => ({
    //   ...prevErrors,
    //   firmaPassword: isValid ? "" : t("UserSignup.passwordInputRedText"), // Add or clear the error
    // }));
  };

  const resetSubsections = (tipoCupon) => {
    var sub_dict = subsections_dict;

    if (tipoCupon == "CB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
    } else if (tipoCupon == "SB") {
      Object.keys(sub_dict).forEach(function (key) {
        sub_dict[key] = true;
      });
      sub_dict["D"] =
        sub_dict["sub_d1"] =
        sub_dict["sub_d2"] =
        sub_dict["sub_d3"] =
        sub_dict["sub_d4"] =
        sub_dict["sub_d5"] =
        sub_dict["sub_d6"] =
        sub_dict["sub_d7"] =
        sub_dict["sub_d8"] =
        sub_dict["sub_d9"] =
        sub_dict["sub_g3"] =
          false;
    }

    return sub_dict;
  };
  const loadAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_load",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data) {
      setIsAutoComplete(true);
    }
  };
  const saveAutoComplete = async () => {
    let formData = new FormData();
    formData.append("email", cookieList[3]);
    formData.append("active", isAutoComplete);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL2 + "api/autocomplete_save",
      formData,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
      { timeout: 10000 }
    );
    if (response.data === "Done") {
      loadAutoComplete();
    }
  };
  const fetchAutoCompleteModal = async () => {
    try {
      if (loadFunction) {
        if (
          (curp && curp.length === 18) ||
          (name !== "" &&
            last_name !== "" &&
            sexType !== "" &&
            birthState !== "State" &&
            dateOfBirthPattern.test(date_of_brith) &&
            birthState !== "" &&
            date_of_brith !== "")
        ) {
          let formData = new FormData();
          formData.append("curp", curp);
          formData.append("name", name);
          formData.append("lname", last_name);
          formData.append("slname", mother_last_name);
          formData.append("birth_state", birthState);
          formData.append("date_of_birth", date_of_brith);
          formData.append(
            "gender",
            sexType === "Feminine" ? "M" : sexType === "Non-Binary" ? "X" : "H"
          );
          formData.append("empresa", cookieList[13]);
          formData.append("folio1", folio1);
          formData.append("folio2", folio2);
          const response = await axios.post(
            process.env.REACT_APP_BASE_URL2 + "api/autocomplete",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 10000,
            }
          );
          let valid_data;
          if (
            response.data.curp_based &&
            response.data.curp_based != "No encontrado"
          ) {
            setACType("curp");
            if (response.data.curp_based.match < 8) {
              valid_data = response.data.curp_based;
            }
          } else {
            if (isAutoComplete) {
              if (response.data.count_based.name) {
                setACType("count");
                if (response.data.count_based.match < 8) {
                  valid_data = response.data.count_based;
                } else {
                  setLoadFunction(false);
                  setCurp(response.data.count_based.curp);
                  updateDisabledFields("count");
                }
              }
            } else {
              if (response.data.match_based.name) {
                setACType("match");
                if (response.data.match_based.match == 7) {
                  valid_data = response.data.match_based;
                }
              }
            }
          }
          if (valid_data) {
            setCurpACdata(valid_data.curp);
            setNamedata(valid_data.name);
            setLastNamedata(valid_data.lname);
            setMotherLastNamedata(valid_data.slname);
            setSexTypedata(valid_data.gender);
            setDateOfBrithdata(valid_data.date_of_birth);
            setBirthStatedata(valid_data.birth_state);
            setShowAlertModal(true);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      (curp && curp.length === 18) ||
      (!nameFocus &&
        !lastNameFocus &&
        !motherLastNameFocus &&
        name !== "" &&
        last_name !== "" &&
        sexType !== "" &&
        birthState !== "State" &&
        dateOfBirthPattern.test(date_of_brith) &&
        birthState !== "" &&
        date_of_brith !== "")
    ) {
      fetchAutoCompleteModal();
    }
  }, [
    curp,
    nameFocus,
    lastNameFocus,
    motherLastNameFocus,
    sexType,
    date_of_brith,
    birthState,
    isAutoComplete,
  ]);

  useEffect(() => {
    if (isAutoComplete) {
      setLoadFunction(true);
    }
  }, [isAutoComplete]);
  const [error, setError] = useState("");

  const [optionsEdo, setOptionsEdo] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeColor, setChangeColor] = useState(false);
  const [changeColor2, setChangeColor2] = useState(false);

  const autoFill = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL2}get_address_auto?d_codigo=${postalCode}`;
    try {
      const response = await axios.get(endpoint);
      const data = response.data;
      if (Array.isArray(data) && data.length === 1) {
        const item = data[0];
        autofillFields(
          `${item.d_codigo}, ${item.d_asenta}, ${item.D_mnpio}, ${item.d_estado}`
        );
      } else if (Array.isArray(data) && data.length > 1) {
        setOptionsEdo(data); // Include a default option
        setShowDropdown(true);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedData = optionsEdo[selectedIndex];
    setSelectedOption(selectedData);
    autofillFields(selectedIndex);
    setShowDropdown(false);
  };

  const autofillFields = (addressData) => {
    // Split the addressData string into individual parts
    const [d_codigo, d_asenta, D_mnpio, d_estado] = addressData.split(", ");

    // Set each part into separate states
    setCologne(d_asenta);
    setMunicipality(D_mnpio);
    setState(d_estado);
    setChangeColor2(true);
  };

  const handleDocumentUpload = (documentName, s3Link) => {
    setEFirmaDocuments((prevState) => ({
      ...prevState,
      [documentName]: { exists: true, s3: s3Link },
    }));
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setDropError(""); // Clear any previous errors
      const allowedExtensions = [
        ".png",
        ".jpeg",
        ".jpg",
        ".pdf",
        ".cer",
        ".key",
      ];
      const newFiles = [];

      for (const file of acceptedFiles) {
        const fileExtension = `.${file.name.split(".").pop().toLowerCase()}`;

        if (
          allowedExtensions.includes(fileExtension) || // Validate by extension
          file.type === "application/x-pem-file" // Fallback for .cer/.key MIME types
        ) {
          try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("email", emailCookie);
            formData.append("nbc_id", nbcId);
            handleOpenLogoModal();

            // Upload file to the API
            const response = await axios.post(
              process.env.REACT_APP_BASE_URL2 + "upload_document",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response.data) {
              handleCloseLogoModal();

              // Check if the document already exists based on document_name
              const documentName = response?.data?.document_name;
              const isDuplicate = fileList.some(
                (existingFile) =>
                  existingFile.response.document_name === documentName
              );

              if (response?.data?.document_name === "cer") {
                handleDocumentUpload("cer", response.data.s3_url);
              } else if (response?.data?.document_name === "key") {
                handleDocumentUpload("key", response.data.s3_url);
              }

              if (isDuplicate) {
                setShowAlert(true);
                setShowtext(t("consultField.alreadyUploadError"));
                return;
              }

              if (response?.data?.error === null) {
                const uploadedFile = {
                  ...file,
                  preview: URL.createObjectURL(file),
                  type: file.type,
                  name: file.name,
                  response: response.data,
                };

                setNbcId(response?.data?.nbc_id);
                newFiles.push(uploadedFile);
              } else if (response?.data?.error === "we already have a upload") {
                setShowAlert(true);
                setShowtext(t("consultField.alreadyUploadError"));
              } else {
                setDropError(t("consultField.invalidDoc"));
              }
            }
          } catch (error) {
            handleCloseLogoModal();
            console.error("Error uploading file:", error);
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
          }
        } else {
          setDropError(`${t("consultField.fileTypeError")}: ${file.name}`);
        }
      }

      if (fileList.length + newFiles.length > 10) {
        setDropError(t("consultField.maxFileError"));
      } else {
        setFileList((prevFiles) => [...prevFiles, ...newFiles]);
      }
    },
    [fileList, t, emailCookie]
  );

  // const handleDelete = (index) => {
  //   setFileList((prevFiles) => prevFiles.filter((_, i) => i !== index));
  // };

  const handleDelete = (index) => {
    setFileList((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);

      // If a deleted file corresponds to "cer" or "key", update eFirmaDocuments
      const deletedFile = prevFiles[index];
      if (
        deletedFile?.response?.document_name === "cer" ||
        deletedFile?.response?.document_name === "key"
      ) {
        setEFirmaDocuments((prevState) => ({
          ...prevState,
          [deletedFile?.response?.document_name]: { exists: false, s3: "" },
        }));
      }

      return updatedFiles;
    });
  };

  const handlePreview = (file) => {
    setViewDoc(true);
    setSelectedFile(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      "image/png, image/jpeg, application/pdf ,.cer ,.key ,application/x-pem-file",
    maxSize: 4194304,
    // maxFiles: 1, // Allow only one file at a time
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
    onDropRejected: () => setIsDragActive(false),
    onDropRejected: (fileRejections) => {
      setIsDragActive(false);
      fileRejections.forEach((rejection) => {
        // if (rejection.errors.some((e) => e.code === "too-many-files")) {
        //   setDropError(t("consultField.onlyOneFileError")); // Handle multiple file rejection
        // }
        if (rejection.errors.some((e) => e.code === "file-too-large")) {
          setDropError(
            `${t("consultField.fileSizeError")}: ${rejection.file.name}`
          );
        }
      });
    },
  });

  const checkValid = () => {
    // Resetting validity states for `ssn` and `cedula` based on dependent pairs

    // Infonavit account & password pair
    if (
      (infoAccValid && !candidatePassValid) ||
      (!infoAccValid && candidatePassValid)
    ) {
      setSsnValid(false);
      setCedulaValid(false);
      setCandidateEmailValid(false);
      setCandidatePhoneValid(false);
      setcertifyValid(false);
      setRevalidValid(false);
      setMandatoryOne(true);
    }

    // Candidate email & phone pair
    else if (
      (candidateEmailValid && !candidatePhoneValid) ||
      (!candidateEmailValid && candidatePhoneValid)
    ) {
      setSsnValid(false);
      setCedulaValid(false);
      setInfoAccValid(false);
      setCandidatePassValid(false);
      setcertifyValid(false);
      setRevalidValid(false);
      setMandatoryTwo(true);
    }
    // If all conditions are met for pairs, set all fields to valid
    else {
      if (sns) {
        setSsnValid(true);
      }
      if (id) {
        setCedulaValid(true);
      }
      if (infoAccount) {
        setInfoAccValid(true);
      }
      if (infoPassword) {
        setCandidatePassValid(true);
      }
      if (candidateEmail) {
        setCandidateEmailValid(true);
      }
      if (candidateNumber) {
        setCandidatePhoneValid(true);
      }
      if (certificateData) {
        setcertifyValid(true);
      }
      if (revalidData) {
        setRevalidValid(true);
      }
      if (!infoAccount && !infoPassword) {
        setMandatoryOne(false);
      }
      if (!candidateEmail && !candidateNumber) {
        setMandatoryTwo(false);
      }
    }
  };

  useEffect(() => {
    // Form is valid if:
    // - Only NSS is valid and no Cedula is provided
    // - Both NSS and Cedula are valid
    // - Only Cedula is valid and no NSS is provided
    const isNssOnlyValid = ssnValid && !cedulaValid && !idFlag && !snsError;
    const isBothValid = ssnValid && cedulaValid && !snsError && !idError;
    const isCedulaOnlyValid = cedulaValid && !ssnValid && !snsFlag && !idError;

    // Form is invalid if there are errors
    if (snsError || idError) {
      setFormValid(false);
      return;
    }

    // Set form validity
    if (isNssOnlyValid || isBothValid || isCedulaOnlyValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [ssnValid, cedulaValid, snsError, idError, snsFlag, idFlag]);

  useEffect(() => {
    checkValid();
  }, [
    infoAccValid,
    candidatePassValid,
    candidateEmailValid,
    candidatePhoneValid,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        // p: 4,
        filter: isDragActive ? "blur(1px)" : "",
        border: isDragActive ? "2px solid #D9E0FD" : "",
        borderRadius: isDragActive ? "12px" : "",
      }}
      {...getRootProps()}
    >
      {showAlert && (
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <AlertModal text={showtext} onClose={handleCloseAlert} />
        </Box>
      )}

      {showAlertModal && (
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <AutoCompleteModal
            curp={curpACdata}
            name={namedata}
            last_name={last_namedata}
            mother_last_name={mother_last_namedata}
            date_of_brith={date_of_brithdata}
            sexType={sexTypedata}
            birthState={birthStatedata}
            onClose={handleCloseAlertModal}
            onAccept={handleAcceptAlert}
          />
        </Box>
      )}

      {/* <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={2}> */}
      <Grid
        container
        spacing={2}
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{
          pl: 4,
          pr: 4,
          pt: 4,
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box>
            <SwitchesCustomized
              isAutoComplete={isAutoComplete}
              toggleButton={(switchState) => {
                setLoadFunction(true);
                setIsAutoComplete(switchState);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box pb={2} mt={-4}>
            <InputRightIcon
              disabled={
                (name ||
                  last_name ||
                  mother_last_name ||
                  date_of_brith ||
                  birthState != "State") &&
                disableCURP
              }
              mailIcon={personIcon} // Assuming personIcon is defined somewhere
              type="text"
              label={t("labels.inputLabelCurp")}
              name="curp"
              id="Curp"
              inputValue={curp}
              placeholder={t("labels.inputLabelCurp")}
              onChange={(event) => {
                setLoadFunction(true);

                setCurp(
                  event.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
                );
                setCurpChanged(true);
                setName("");
                setLastName("");
                setMotherLastName("");
                setSexType("Feminine");
                setDateOfBrith("");
                setBirthState("State");

                setDisableName(true);
                setDisableLastName(true);
                setDisableMotherLastName(true);
                setDisableSexType(true);
                setDisableDateOfBirth(true);
                setDisableBirthState(true);
              }}
              onBlur={() => {
                handleBlur("curp");
              }}
              onFocus={() => {
                handleFocus("curp");
              }}
              maxLength={18} // Add maxLength attribute to restrict input to 18 characters
              style={{ width: "100%" }} // Set a fixed width or percentage width for the input field
            />
          </Box>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Box mt={{ xs: 2, sm: 0 }} style={{ color: "red" }}>
              {error}
            </Box>
          </Grid>
        )}
      </Grid>

      {/* </Stack> */}

      <Grid
        container
        spacing={2}
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{
          pl: 4,
          pr: 4,
        }}
      >
        {zonedata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              {zonedata.length > 0 ? (
                <DropDown
                  options_data={zonedata}
                  type="text"
                  name="zone"
                  subLabel=""
                  id="zone"
                  label={t("thanks.Select zone")}
                  placeholder={t("thanks.Select zone")}
                  inputValue={selectzonedata}
                  value="" // Set to an empty string initially
                  onChange={(event) => setselectzonedata(event.target.value)}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {branchesdata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0 }}>
              {branchesdata.length > 0 ? (
                <DropDown
                  options_data={branchesdata}
                  type="text"
                  name="branch"
                  subLabel=""
                  id="branch"
                  label={t("thanks.Select branch")}
                  placeholder={t("thanks.Select branch")}
                  inputValue={selectbranchesdata}
                  value="" // Set to an empty string initially
                  onChange={(event) =>
                    setselectbranchesdata(event.target.value)
                  }
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {Clientdata.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 0.8 }}>
              {Clientdata.length > 0 ? (
                <DropDown
                  options_data={Clientdata}
                  type="text"
                  name="Client"
                  subLabel=""
                  id="Client"
                  label={t("thanks.Select Client")}
                  placeholder={t("thanks.Select Client")}
                  inputValue={selectClientdata}
                  value="" // Set to an empty string initially
                  onChange={(event) => setselectClient(event.target.value)}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        {cookieList[13] === "ICONN" ? (
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: { xs: 0, sm: 2 } }}>
              {cookieList[13] === "ICONN" ? (
                <RadioButtonsGroup
                  topLabelName={t("thanks.Candidate Type")}
                  topLabel={true}
                  GroupingList={canidateType}
                  value={CandidateTypedata}
                  onChange={handleCanidateType}
                  smGrid={6}
                  xs={6}
                  sx_group={{
                    mt: "8px",
                  }}
                />
              ) : null}
            </Box>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
          }}
        >
          <Box>
            <InputRightIcon
              disabled={curp && disableName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelName")}
              name="name"
              id="Name"
              inputValue={name}
              placeholder={t("placeholders.name")}
              onChange={(event) => {
                setLoadFunction(true);

                setName(event.target.value);
                setNameChanged(true);
                setCurp("");

                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("name");
              }}
              onFocus={() => {
                handleFocus("name");
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
          }}
        >
          <Box>
            <InputRightIcon
              disabled={curp && disableLastName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelLastName")}
              name="lname"
              id="lname"
              placeholder={t("placeholders.lastname")}
              inputValue={last_name}
              onChange={(event) => {
                setLoadFunction(true);

                setLastName(event.target.value);
                setLastNameChanged(true);
                setCurp("");

                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("last_name");
              }}
              onFocus={() => {
                handleFocus("last_name");
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
          }}
        >
          <Box>
            <InputRightIcon
              disabled={curp && disableMotherLastName}
              mailIcon={personIcon}
              type="text"
              label={t("labels.inputLabelMotherName")}
              name="motherlastname"
              id="motherlastname"
              placeholder={t("placeholders.mothername")}
              inputValue={mother_last_name}
              onChange={(event) => {
                setLoadFunction(true);

                setMotherLastName(event.target.value);
                setMotherLastNameChanged(true);
                setCurp("");

                setDisableCURP(true);
              }}
              onBlur={() => {
                handleBlur("mother_last_name");
              }}
              onFocus={() => {
                handleFocus("mother_last_name");
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <RadioButtonsGroup
              disabled={curp && disableSexType}
              topLabelName={t("labels.topLabelSex")}
              topLabel={true}
              GroupingList={sexTypeList}
              value={sexType}
              onChange={(event) => {
                setLoadFunction(true);

                setSexType(
                  sexType == event.target.value ? "" : event.target.value
                );
                setCurp("");

                setDisableCURP(true);
              }}
              smGrid={4}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
          }}
        >
          <Box sx={{ mt: 0 }}>
            <DatePickerInput
              disabled={curp && disableDateOfBirth}
              selectedDate={date_of_brith}
              onDateChange={(value) => {
                setLoadFunction(true);

                setDateOfBrith(value);
                setDateOfBrithChanged(true);
                setCurp("");

                setDisableCURP(true);
              }}
              mandatory={true}
              FilledOut={curp && curp.length === 18}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingTop: 0,
          }}
        >
          <Box sx={{ mt: 0 }}>
            <DropDownInputSimple
              disabled={curp && disableBirthState}
              options_data={options_edo}
              type="text"
              name="companyname"
              subLabel={t("sublabels.mandatory")}
              id="companyname"
              label={t("labels.inputLabelEdoNto")}
              inputValue={birthState}
              onChange={(event) => {
                setLoadFunction(true);

                setBirthState(event.target.value);
                setBirthStateChanged(true);
                setChangeColor(true);
                setCurp("");

                setDisableCURP(true);
              }}
              // style={{ backgroundColor: 'rgba(255, 140, 0, 0.1)' }} // Apply color for mandatory field
              mandatory={true}
              FilledOut={curp && curp.length === 18}
              selectedColor={changeColor}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={showTextField ? 12 : 6}
          sx={{ display: !showTextField ? "flex" : "", alignItems: "center" }}
        >
          <Box sx={{ mt: { xs: 0, sm: showTextField ? 1 : 0 } }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTextField}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("labels.CandidateDocumentation(Optional)")}
            />
          </Box>
          {showTextField && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={2}>
                <LabelInputLeftIcon
                  mailIcon={mailImage}
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  placeholder={t("placeholders.EnterCandidateEmail")}
                  onChange={emailhandler}
                  // error={formErrors.candidate_Email}
                  // mandatory = {mandatoryTwo}
                />
                {formErrors.candidate_Email && (
                  <Typography variant="body2" color="error">
                    {formErrors.candidate_Email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <LabelInputLeftIcon
                  mailIcon={phoneIcon}
                  type="number"
                  label=""
                  name="mnumber"
                  id="mnumber"
                  placeholder={t("placeholders.EnterCandidatePhoneNumber")}
                  onChange={cellPhoneHandler}
                  // error={formErrors.mobile_number}
                  maxLength={10}
                  // mandatory = {mandatoryTwo}
                />
                {formErrors.mobile_number && (
                  <Typography variant="body2" color="error">
                    {formErrors.mobile_number}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <Box sx={{ mt: { xs: 0, sm: 0 } }}>
                  <LabelInput
                    type="text"
                    subLabel=""
                    label=""
                    name="SNS"
                    id="SNS"
                    placeholder={t("placeholders.sns")}
                    onChange={snsHandler}
                  />
                </Box>
                {snsFlag && (
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {snsError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <Box sx={{ mt: 0 }}>
                  <LabelInput
                    type="number"
                    subLabel=""
                    label=""
                    name="CedulaID"
                    id="CedulaID"
                    placeholder={t("placeholders.id")}
                    maxLength={8}
                    onChange={idHandler}
                  />
                </Box>
                {idFlag && (
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {idError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <RadioButtonsGroup
                    topLabelName={"e.firma"}
                    topLabel={true}
                    GroupingList={[
                      { label: t("labels.inputLabelYes"), value: "Yes" },
                      { label: t("labels.inputLabelNo"), value: "No" },
                    ]}
                    value={eFirma}
                    onChange={eFirmaHandler}
                    smGrid={6}
                    xs={6}
                    sx_group={{
                      mt: "8px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} mt={2}>
                <PasswordShowHideIcon
                  type="text"
                  label=""
                  name="firmaPassword"
                  id="firmaPassword"
                  placeholder={t("consultField.firmaPassword")}
                  onChange={EFirmaPasshandler}
                  inputValue={eFirmaPassword}
                  disabled={!isPasswordActive}
                />
                {formErrors.firmaPassword && (
                  <Typography variant="body2" color="error">
                    {formErrors.firmaPassword}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} mt={2}>
                <LabelInput
                  type="number"
                  subLabel=""
                  label=""
                  name="Infonavit"
                  id="Infonavit"
                  placeholder={t("placeholders.enterInfonavit")}
                  minLength={11}
                  maxLength={11}
                  onChange={infonavithandler}
                  // Mandatory={mandatoryOne}
                />
                {infoFlag && (
                  <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                    {infoError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <PasswordShowHideIcon
                  type="text"
                  label=""
                  name="InfonavitPassword"
                  id="InfonavitPassword"
                  placeholder={t("placeholders.EnterPassword")}
                  onChange={candidatePasshandler}
                  inputValue={infoPassword}
                  // mandatory = {mandatoryOne}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label=""
                  name="FolioCertify"
                  id="FolioCertify"
                  placeholder={t("placeholders.EnterCertificateFOLIO")}
                  inputValue={certificateData}
                  onChange={certifyDatahandler}
                  // isValid={certifyValid}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label=""
                  name="FolioRevalidation"
                  id="FolioRevalidation"
                  placeholder={t("placeholders.EnterRevalidationFOLIO")}
                  inputValue={revalidData}
                  onChange={revalidDatahandler}
                  // isValid={revalidValid}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showGeoLocationField}
                  onChange={(event) => {
                    setShowGeoLocationField(event.target.checked);
                  }}
                />
              }
              label={t("labels.inputLabelGeoLocOptional")}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showFaceIDField}
                  onChange={(event) => {
                    setShowFaceIDField(event.target.checked);
                  }}
                />
              }
              label={t("labels.inputLabelFaceIDOptional")}
            />
          </Box>
        </Grid>

        {!showTextField && (
          <Grid item xs={12} sm={6}>
            <Box></Box>
          </Grid>
        )}

        <Grid item xs={12} sm={multiReportType == "With Bureau" ? 6 : 6}>
          <Box sx={{ mt: { xs: 0, sm: 2 } }}>
            <RadioButtonsGroup
              topLabelName={t("labels.inputActiveLegalFilter")}
              topLabel={true}
              GroupingList={activateLegalFilterList}
              value={legalFiter}
              onChange={legalFilterHandler}
              smGrid={5}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={multiReportType == "With Bureau" ? 6 : 6}>
          <Box sx={{ mt: { xs: 0, sm: 2 } }}>
            <RadioButtonsGroup
              topLabelName={t("labels.inputMultiReportType")}
              topLabel={true}
              GroupingList={multiReportTypeList}
              value={multiReportType}
              onChange={multiReportTypeHandler}
              smGrid={6}
              xs={6}
              sx_group={{
                mt: "8px",
              }}
            />
          </Box>
        </Grid>
        {multiReportType == "With Bureau" && (
          <>
            <Grid item xs={12} sm={6}>
              <Box>
                <div>
                  {showDropdown ? (
                    <DropDownInputSimple
                      options_data={optionsEdo}
                      label={t("labels.inputLabelPostalCode")}
                      inputValue={selectedOption ? selectedOption.value : ""}
                      onChange={(event) => handleDropdownChange(event)}
                      useCustomFormat={true}
                      mandatory={true}
                      showDefaultOption={true}
                    />
                  ) : (
                    <>
                      <LabelInput
                        type="text"
                        subLabel=""
                        label={t("labels.inputLabelPostalCode")}
                        name="code"
                        id="code"
                        placeholder={t("placeholders.postalcode")}
                        inputValue={postalCode}
                        onChange={(event) => {
                          setPostalCode(event.target.value);
                          if (event.target.value.length == 5) {
                            setPostalCodeFlag(false);
                          } else {
                            setPostalCodeFlag(true);
                          }
                        }}
                        onKeyUp={autoFill}
                        minLength={5}
                        maxLength={5}
                        isValid={!isPostalCodeEmpty && postalCode.length == 5}
                        Mandatory={true}
                      />
                      {postalCodeFlag && (
                        <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                          {t("thanks.postalCodeError")}
                        </FormHelperText>
                      )}
                    </>
                  )}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 0 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelStreet")}
                  name="street"
                  id="street"
                  placeholder={t("placeholders.street")}
                  inputValue={street}
                  onChange={(event) => setStreet(event.target.value)}
                  Mandatory={true}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 0 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelNumber")}
                  name="number"
                  id="number"
                  placeholder={t("placeholders.number")}
                  // inputValue={number}
                  onChange={(event) => setNumber(event.target.value)}
                  maxLength={10}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelCologne")}
                  name="cologne"
                  id="cologne"
                  placeholder={t("placeholders.cologne")}
                  inputValue={cologne}
                  onChange={(event) => setCologne(event.target.value)}
                  Mandatory={true}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: { xs: 0, sm: 2 } }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label={t("labels.inputLabelMavorsOffice")}
                  name="office"
                  id="office"
                  placeholder={t("placeholders.mavorsoffice")}
                  inputValue={municipality}
                  onChange={(event) => setMunicipality(event.target.value)}
                  Mandatory={true}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: { xs: 0, sm: 2 } }}>
                <DropDownInputSimple
                  options_data={options_edo}
                  type="text"
                  name="condition"
                  subLabel=""
                  id="condition"
                  label={t("labels.inputLabelCondition")}
                  onChange={(event) => {
                    setState(event.target.value);
                    setChangeColor2(true);
                  }}
                  inputValue={state}
                  mandatory={true}
                  selectedColor={changeColor2}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} sx={{ pl: 4, pr: 4 }}>
        <Typography
          sx={{
            fontSize: 16,
            fontFamily: "Gilroy-Medium",
            color: "rgba(33, 33, 33, 0.6) !important",
            marginBottom: "10px ",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {t("consultField.uploadDocuments")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: { sm: "24px", xs: "18px" },
            flexWrap: "wrap",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {fileList.map((file, index) => (
            <Box
              sx={{
                position: "relative",
                boxShadow: "4px 6px 6px #FF8D001A",
                border: "1px solid #FFF8F0",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "6px",
                marginTop: "10px",
                marginBottom: "5px",
                width: { sm: "85px", xs: "68px" },
              }}
            >
              {file.type === "application/pdf" ? (
                <Box
                  component="img"
                  src={pdfImage} // Replace with your PDF icon
                  alt={`preview-${index}`}
                  sx={{
                    width: "100%",
                    height: { sm: "65px", xs: "65px" },
                    // borderRadius: "12px",
                  }}
                />
              ) : file.response.document_name === "key" ||
                file.response.document_name === "cer" ? (
                <Box
                  component="img"
                  src={textDoc} // Replace with your PDF icon
                  alt={`preview-${index}`}
                  sx={{
                    width: "100%",
                    height: { sm: "65px", xs: "65px" },
                    // borderRadius: "12px",
                  }}
                />
              ) : (
                <Box
                  component="img"
                  src={file.preview}
                  alt={`preview-${index}`}
                  sx={{
                    width: "100%",
                    height: { sm: "6  5px", xs: "65px" },
                    borderRadius: "12px",
                  }}
                />
              )}
              <Typography
                variant="caption"
                sx={{
                  wordBreak: "break-all",
                  color: "rgba(33, 33, 33, 0.4)",
                  fontSize: { sm: 12, xs: 10 },
                  fontFamily: "Gilroy-Medium",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                {file.name}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "-5px",
                }}
              >
                <Box
                  component="img"
                  src={deleteFile} // Replace with your PDF icon
                  alt={`preview-${index}`}
                  sx={{
                    width: { sm: "18px", xs: "16px" },
                    height: { sm: "18px", xs: "16px" },
                    display: "block",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(index)}
                />
                <Box
                  component="img"
                  src={previewFile} // Replace with your PDF icon
                  alt="preview"
                  sx={{
                    width: { sm: "18px", xs: "16px" },
                    height: { sm: "18px", xs: "16px" },
                    display: "block",
                    marginTop: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePreview(file)}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            mt: { xs: 2, sm: 2 },
            borderRadius: "12px",
            padding: { xs: "30px 20px", sm: "40px", lg: "50px" },
            textAlign: "center",
            backgroundColor: isDragActive
              ? "rgba(65, 101, 246, 0.06)"
              : "#FFF8F0", // Change background color when dragging
            transition: "background-color 0.3s ease", // Smooth transition
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
          // {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Box
            component="img"
            src={consultUpload}
            alt="Upload"
            sx={{ width: 48, height: 48 }}
          />
          <Typography
            sx={{
              fontSize: { sm: 18, xs: 17 },
              fontFamily: "Gilroy-Medium",
              color: "rgba(28, 33, 45, 0.7)",
              marginTop: "5px",
            }}
          >
            {t("consultField.dragFile")}
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: 16, xs: 14 },
              fontFamily: "Gilroy-Medium",
              color: "rgba(33, 33, 33, 0.4)",
            }}
          >
            {t("consultField.supportFile")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              textAlign: "center",
              borderRadius: "6px",
              py: { sm: 1.2, xs: 1 },
              px: { sm: 5, xs: 3 },
              color: "#fff",
              textDecoration: "none",
              fontSize: { sm: 18, xs: 16 },
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize",
              margin: "10px",
            }}
          >
            {t("consultField.chooseFile")}
          </Button>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Gilroy-Medium",
              color: "rgba(33, 33, 33, 0.7)",
              display: { md: "block", lg: "none" },
            }}
          >
            {t("consultField.O")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<CameraAltIcon />}
            onClick={(event) => {
              event.stopPropagation();
              setShowWebcam(true);
            }}
            sx={{
              display: { md: "flex", lg: "none" },
              margin: "5px 0 15px 0",
              textAlign: "center",
              borderRadius: "6px",
              py: { sm: 1.2, xs: 1 },
              px: { sm: 5, xs: 3 },
              color: "#fff",
              textDecoration: "none",
              fontSize: { sm: 18, xs: 16 },
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize",
              "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root":
                {
                  fontSize: "24px !important",
                },
            }}
          >
            {t("consultField.capture")}
          </Button>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "Gilroy-Medium",
              color: "rgba(33, 33, 33, 0.4)",
            }}
          >
            {t("consultField.maxFile")}
          </Typography>
        </Box>
        {dropError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {dropError}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            marginTop: 2,
            gap: "12px",
            flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap" },
            flexDirection: { xs: "column", sm: "column", lg: "row" },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "Gilroy-Medium",
              color: "rgba(33, 33, 33, 0.7)",
              width: "350px ",
            }}
          >
            {t("consultField.listOfDocuments")}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontFamily: "Gilroy-Medium",
              color: "rgba(33, 33, 33, 0.4)",
            }}
          >
            {t("consultField.documentList")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{ pl: 4, pr: 4, pb: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: 4,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              textAlign: "center",
              borderRadius: "6px",
              py: 1.5,
              px: 8,
              color: "#fff",
              textDecoration: "none",
              fontSize: 18,
              fontFamily: "Gilroy-Medium",
              textTransform: "capitalize",
            }}
            disabled={
              !(curp && curp.length === 18) ||
              !(showTextField
                ? formValid ||
                  (infoAccValid && candidatePassValid) ||
                  certifyValid ||
                  revalidValid ||
                  (candidateEmailValid && candidatePhoneValid) ||
                  (eFirma === "Yes" &&
                    eFirmaDocuments.cer.exists &&
                    eFirmaDocuments.key.exists &&
                    firmaPasswordValid) ||
                  eFirma === "No"
                : true)
                ? multiReportType === "With Bureau"
                  ? isNameEmpty ||
                    isLastNameEmpty ||
                    isDateBirthEmpty ||
                    isStreetEmpty ||
                    isbirthState ||
                    isCologneEmpty ||
                    isMunicipalityEmpty ||
                    isStateEmpty ||
                    isPostalCodeEmpty ||
                    postalCode.length !== 5 ||
                    !(showTextField
                      ? // Independent fields (either ssnValid or cedulaValid alone is enough)
                        formValid ||
                        // Dependent pairs (both values must be filled)
                        (infoAccValid && candidatePassValid) || // Infonavit account & Password
                        certifyValid ||
                        revalidValid || // Certification & Revalidation Folio
                        (candidateEmailValid && candidatePhoneValid) ||
                        (eFirma === "Yes" &&
                          eFirmaDocuments.cer.exists &&
                          eFirmaDocuments.key.exists &&
                          firmaPasswordValid) ||
                        eFirma === "No"
                      : true)
                  : isNameEmpty ||
                    isLastNameEmpty ||
                    isDateBirthEmpty ||
                    isbirthState ||
                    !(showTextField
                      ? // Independent fields (either ssnValid or cedulaValid alone is enough)
                        formValid ||
                        // Dependent pairs (both values must be filled)
                        (infoAccValid && candidatePassValid) || // Infonavit account & Password
                        certifyValid ||
                        revalidValid || // Certification & Revalidation Folio
                        (candidateEmailValid && candidatePhoneValid) || // Candidate Email & Phone
                        (eFirma === "Yes" &&
                          eFirmaDocuments.cer.exists &&
                          eFirmaDocuments.key.exists &&
                          firmaPasswordValid) ||
                        eFirma === "No"
                      : true)
                : // !(showTextField ? sns.length == 11 : true))
                multiReportType === "With Bureau"
                ? isStreetEmpty ||
                  isCologneEmpty ||
                  isMunicipalityEmpty ||
                  isStateEmpty ||
                  isPostalCodeEmpty ||
                  postalCode.length !== 5
                : // !(showTextField ? sns.length == 11 : true))
                  false
            }
            onClick={() => {
              if (curp && curp.length < 18) {
                setError(t("labels.inputLabelCurpError"));
              } else {
                setError(false); // Clear error state if CURP length is not less than 18
                if (multiReportType === "With Bureau") {
                  withBureauHandler();
                } else {
                  withoutBureauHandler();
                }
                saveAutoComplete();
              }
            }}
          >
            {t("buttons.consult")}
          </Button>

          {/************************** With Bureau All Modal imported *************************/}
          <GuestTermsAndConditionModal
            withoutBureauModalOpen={withoutBureauModalOpen}
            setWithoutBureauModalOpen={setWithoutBureauModalOpen}
            tagLineShow={true}
            subjectCheckboxShow={true}
            emailBoxButtonShow={true}
            emailLabel={t("guestTermsCondition.emailLabel")}
            dataCurpZero={DataSend}
            nbData={nbdata}
            onConfirmation={openThankYouModal}
            setEmailSentModalOpen={setEmailSentModalOpen}
            subsections_dicts={subsectionDicts}
            showHomonyms={showHomonyms}
            nss1Data={nss1Data}
            nssData={nssData}
            estadoData={estadoData}
            curpData={curpData}
            cname={cname}
            edad={edad}
            dczCurp={dczCurp}
            dczCurpId={dczCurpId}
            dczCurpDoc={dczCurpDoc}
            documentStatus={documentStatus}
          />

          {/************************** Without Bureau All Modal imported *************************/}
          <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />
          <WithBureauQueryConfirmationModal
            withBureauOpen={withBureauOpen}
            setWithBureauOpen={setWithBureauOpen}
            onConfirmation={openThankYouModal}
            tagLineShow={true}
            subjectCheckboxShow={true}
            emailBoxButtonShow={true}
            emailLabel={t("guestTermsCondition.emailLabelpin")}
            mobileLabel={t("guestTermsCondition.mobileLabel")}
            dataCurpZero={DataSend}
            nbData={nbdata}
            subsections_dicts={subsectionDicts}
            setEmailSentModalOpen={setEmailSentModalOpen}
            dczCurp={dczCurp}
            dczCurpId={dczCurpId}
            dczCurpDoc={dczCurpDoc}
            documentStatus={documentStatus}
          />
          <EmailSuccessfullySent
            isOpen={emailSentModalOpen}
            setEmailSentModalOpen={setEmailSentModalOpen}
            setWithoutBureauModalOpen={setWithoutBureauModalOpen}
            callbackFunction={() => window.location.reload()}
          />
          <ThankYouMessage
            isOpen={isThankYouModalOpen}
            reportInfo={reportData}
            subsetions_dicts={subsectionDicts}
          />
          <DocumentPreview
            viewDoc={viewDoc}
            setViewDoc={setViewDoc}
            file={selectedFile}
          />
          <CaptureModal
            showWebcam={showWebcam}
            setShowWebcam={setShowWebcam}
            setFileList={setFileList}
            fileList={fileList}
            setDropError={setDropError}
            nbcId={nbcId}
          />
        </Box>
      </Grid>
    </Box>
  );
}

export default WithBureauQueryForm;
