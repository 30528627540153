import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default function ResentEmailModal(props) {
  const { t } = useTranslation();
  const { sentLink } = props;
  const { email } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const ResetHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    setOpen(sentLink);
  }, [sentLink]);

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "Gilroy-Bold",
              color: "#1C212D",
              textAlign: "center",
            }}
          >
            {t("ForgotPassword.popUps.mainPopUp.title")}
          </Typography>
          <Typography
            sx={{
              mt: 2,
              color: "#1C212D",
              fontSize: 16,
              lineHeight: "22px",
              fontFamily: "Gilroy-SemiBold",
              textAlign: "center",
            }}
          >
            {t("ForgotPassword.popUps.mainPopUp.text")}{" "}
            <span style={{ color: "#4165F6" }}>{email}</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={ResetHandler}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
              }}
              variant="contained"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
