import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import calendar from "../../../assets/images/calendarimg.png";
import lineImg from "../../../assets/images/line-header-sec.png";
import couponImg from "../../../assets/images/coupon-header-icon.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ellips1 from "../../../assets/images/ellipse-1.png";
import ellips2 from "../../../assets/images/ellipse-2.png";
import ellips3 from "../../../assets/images/ellipse-3.png";
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import dashImg from "../../../assets/images/dash-icon.svg"
import Container from '@mui/material/Container';
import logoImage from "../../../assets/images/logo-2.png";
import GuestNavigation from "./GuestNavigation";
import { getCookiesList, removeCookie } from '../../../utils/cookiesHelper';
import Cookies from 'js-cookie';
function SimpleLogoHeader() {
  const navigate = useNavigate();
  const [cookieList, setCookieList] = useState([])
  const userCookiesList = getCookiesList('userInfo')
  useEffect(() => {
    }, [cookieList]);
  const pathHanler =()=>{
    if (userCookiesList.length > 0) {
      navigate("/Dashboard");
   }
   else{
    navigate("/");
   }
  }
  return (
    <Box sx={{position:"fixed",top:0,backgroundColor:"#ffffff",width:"100%",boxShadow:4,zIndex:99}}>
    <Container maxWidth="lg">
    <Box sx={{ p: 2, display: "flex", flexDirection: "row"}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width:"100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: 'pointer'
          }}
        >
          <img src={logoImage} width="164px" alt="calendar" onClick={pathHanler}/>
        </Box>
      </Box>
    </Box>
    </Container>
    </Box>
  );
}

export default SimpleLogoHeader;
