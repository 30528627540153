import { useState } from "react";
import underLine from "../../../src/assets/images/under-line.png";
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import smsDoc from "../../assets/images/smsDoc.svg";
import whatsappDoc from "../../assets/images/whatsappDoc.svg";
import mailDoc from "../../assets/images/mailDoc.svg";
import downloadIcn from "../../assets/images/downloadSm.svg";
import eyeIcn from "../../assets/images/eyeSm.svg";
import { useLocation } from "react-router-dom";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader";
import EmailSuccessfullySent from "../@common/Modal/EmailSuccessfullySent";
import { call_api_function } from "../../utils/verifyAPICalls";
import AlertModal from "../@common/Modal/AlertModal";
import lineImg from "../../assets/images/line-header-sec.png";

const Documents = () => {
  const { t } = useTranslation();
  const location = useLocation();

  console.log("location", location?.state);
  const documents = location?.state?.documents;
  const userEmail = location?.state?.userEmail;
  const candidateEmail = location?.state?.item?.email;
  const vlid = location?.state?.item?._id;
  const curp = location?.state?.item?.curp;
  const name = location?.state?.item?.name;
  const telefono = location?.state?.item?.telefono;

  const [isLoading, setIsLoading] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [msgType, setMsgType] = useState("");

  const actionButtons = [
    {
      src: whatsappDoc,
      alt: "Send via WhatsApp",
      type: "whatsapp",
      tephone: telefono,
    },
    { src: mailDoc, alt: "Send via Mail", type: "mail", tephone: telefono },
    { src: smsDoc, alt: "Send via SMS", type: "sms", tephone: telefono },
  ];

  const actionButtonStyles = {
    p: 0,
    minWidth: "20px",
    backgroundColor: "primary.main",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "rgba(248, 115, 121, 1)", // Change to a darker shade on hover
    },
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const sendLegalEmail = async (button) => {
    if (button.type !== "mail") {
      return;
    }
    setIsLoading(true);
    var dataLegal = new FormData();
    dataLegal.append("usrMail", userEmail);
    dataLegal.append("mail", candidateEmail);
    dataLegal.append("caso", "caso2");
    dataLegal.append("phono", "no tel");
    dataLegal.append("_id", vlid);
    dataLegal.append("is_panel", "true");

    try {
      const verifyResponse = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "legal-Person-auth",
        dataLegal
      );

      if (verifyResponse.data) {
        // Some disabling of the button and navigate to "/"
        setIsLoading(false);
        setShowEmail(false);
        setEmailSentModalOpen(true);
      } else if (verifyResponse.data === "error bd") {
        setIsLoading(false);
        setShowAlert(true);
        setShowtext("no Connection");
        setShowEmail(false);
      } else {
        setIsLoading(false);
        setShowAlert(true);
        setShowtext("error");
        setShowEmail(false);
      }
    } catch (error) {
      if (error.message === "timeout of 30000ms exceeded") {
        setShowAlert(true);
        setShowtext("Alcanzaste el tiempo", "límite de espera");
      } else {
        setShowAlert(true);
        setShowtext("Comprueba", "tu conexión");
      }
      setIsLoading(false);
      setShowEmail(false);
    }
  };

  const sendReminderSMS = async () => {
    setIsLoading(true);

    const params = {
      phono: telefono,
      // full_name: name,
      _id: vlid,
    };

    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "send_reminder_sms",
        params
      );

      if (response.data.message) {
        setIsLoading(false);
        setShowAlert(true);
        setEmailSentModalOpen(true);
        setMsgType("sms");
      } else {
        setIsLoading(false);
        setShowAlert(true);
        setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      }
    } catch (error) {
      setIsLoading(false);
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };

  const sendWhatsapp = async () => {
    setIsLoading(true);

    const params = {
      phono: telefono,
      // full_name: name,
      _id: vlid,
    };

    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "send_whatsapp",
        params
      );

      if (response.data.message) {
        setIsLoading(false);
        setShowAlert(true);
        setEmailSentModalOpen(true);
        setMsgType("sms");
      } else {
        setIsLoading(false);
        setShowAlert(true);
        setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      }
    } catch (error) {
      setIsLoading(false);
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };

  return (
    <>
      <LogoModalLoader isOpenLogoModal={isLoading} />
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <EmailSuccessfullySent
        isOpen={emailSentModalOpen}
        setEmailSentModalOpen={setEmailSentModalOpen}
        path="/authorizationpanel"
        msgType={msgType}
      />

      <Grid item xs={12} sm={12}>
        <Box mt={2} mb={2} textAlign="center">
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: { xs: "22px", sm: "24px" },
              lineHeight: "35px",
              color: "#1C212D",
            }}
          >
            {t("expansionField.AllDocuments")}
          </Typography>
          <Box mt={1}>
            <img src={underLine} alt="underline" />
          </Box>
        </Box>
      </Grid>

      <Box
        sx={{
          backgroundColor: "#ffffff",
          px: 4,
          py: 2,
          fontSize: "7px !important",
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "row", sm: "row" },
          flexWrap: { xs: "wrap", sm: "nowrap" },
          boxShadow: 4,
          rowGap: { xs: 1, sm: 0 },
          columnGap: { xs: 3, sm: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
            width: { xs: "45%", sm: "auto" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              fontFamily: "Gilroy-SemiBold",
              color: "neutral.900",
            }}
          >
            NAME
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              fontFamily: "Gilroy-Medium",
              color: "text.opacity",
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            mx: { xs: 0.6, md: 4 },
            mt: { xs: 1.6, md: 1.2 },
          }}
        >
          <img src={lineImg} height={50} alt="line" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
            width: { xs: "45%", sm: "auto" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              fontFamily: "Gilroy-SemiBold",
              color: "neutral.900",
            }}
          >
            CURP
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              fontFamily: "Gilroy-Medium",
              color: "text.opacity",
            }}
          >
            {curp}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#ffffff", px: 4, py: 2 }}>
        {documents && documents.length > 0 && (
          <Box>
            {documents?.map((doc, index) => (
              <ListItem
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D0D2D4",
                  marginBottom: "8px",
                  borderRadius: "6px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <ListItemText>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: 18, xs: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "neutral.900",
                        flex: 1,
                        width: "calc(100% - 95px)",
                      }}
                    >
                      {`${index + 1}. ${t(
                        `consultField.${doc?.document_name}`
                      )}`}
                    </Typography>
                    <Box
                      sx={{
                        width: "80px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {/* <Button sx={{ ...actionButtonStyles, mr: 1 }}>
                        <img src={eyeIcn} alt="View Document" />
                      </Button> */}
                      <Button sx={actionButtonStyles}>
                        <a
                          href={doc.s3_upload_url}
                          download
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img src={downloadIcn} alt="Download Document" />
                        </a>
                      </Button>
                    </Box>
                  </Box>
                </ListItemText>
              </ListItem>
            ))}
          </Box>
        )}

        <Box
          sx={{
            border: "1px solid #D0D2D4",
            padding: { sm: "30px", xs: "24px" },
            borderRadius: "6px",
            textAlign: "center",
            // height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: 22, xs: 18 },
              fontFamily: "Gilroy-SemiBold",
              color: "neutral.1000",
              mb: 2,
            }}
          >
            {t("expansionField.sendRemainder")}
          </Typography>

          {(telefono === "no tel" || telefono === "") && (
            <Typography
              sx={{
                fontSize: { sm: 18, xs: 14 },
                fontFamily: "Gilroy-Regular",
                color: "neutral.700",
                mb: 3,
              }}
            >
              {t("expansionField.phoneReminder")}
            </Typography>
          )}

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            {actionButtons.map((button, index) => (
              <Button
                key={index}
                sx={{
                  border: 0,
                  background: "transparent",
                  p: 0,
                  minWidth: "58px",
                  borderRadius: "50%",
                  border: "1px solid #D0D2D4",
                  padding: "15px",
                  "&:disabled": {
                    background: "#E0E0E0", // Gray background when disabled
                    cursor: "not-allowed",
                    pointerEvents: "all",
                  },
                }}
                disabled={
                  ["sms", "whatsapp"].includes(button.type) &&
                  (telefono === "no tel" || telefono === "")
                }
                onClick={() => {
                  if (button.type === "sms") {
                    sendReminderSMS();
                  } else if (button.type === "whatsapp") {
                    sendWhatsapp();
                  } else {
                    sendLegalEmail(button);
                  }
                }}
              >
                <img src={button.src} alt={button.alt} />
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Documents;
