import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import sucessImage from "../../assets/images/successfull-email.png";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import successIcon from "../../assets/images/downloadSucessIcon.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function VerifyAccountModal(props) {
  const { t } = useTranslation();
  const {
    text = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    showAlert,
    onClose,
    resendOtpViaEmail,
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const handleClose = () => setOpen(false);
  const sendHandler = () => {
    alert("sendHandler");
  };
  // const handleOpen = () => {
  //   setOpen(true);
  // }

  return (
    <Fragment>
      <Button
        onClick={resendOtpViaEmail}
        variant="outlined"
        sx={{
          py: 1,
          fontSize: "18px",
          fontFamily: "Gilroy-Medium",
          color: "primary.main",
          borderColor: "primary.main",
          width: 270,
        }}
      >
        {t("thanks.recieve")}
      </Button>
      <Modal
        open={showAlert}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CancelIcon sx={{ fontSize: 60, color: "red" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h6"}
              sx={{
                color: "neutral.900",
                fontSize: 20,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              Invalid OPT
            </Typography>

            <Typography
              sx={{
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                color: "rgba(27, 32, 44, 0.6)",
                textAlign: "center",
                mt: 2,
                mb: 2,
              }}
            >
              {text}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 11 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
              }}
              variant="contained"
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
