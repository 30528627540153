import ReactInputVerificationCode from "react-input-verification-code";
import "./SixDigitCode.css";

export default function SixDigitCode({ onCodeChange, otp }) {
  const handleInputChange = (value) => {
    onCodeChange(value);
  };

  const isValidVerificationCode = (value) => {
    // perform verification logic
    return value.length === 6 && /^\d+$/.test(value);
  };

  return (
    <div className="custom-styles">
      <ReactInputVerificationCode
        length={6}
        type="number"
        inputStyle={{
          borderRadius: "20px",
        }}
        value={otp} // Ensure that the value prop is set to an empty string
        onChange={handleInputChange}
        placeholder="x"
      />
    </div>
  );
}
