import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import calendar from "../../../assets/images/calendarimg.png";
import lineImg from "../../../assets/images/line-header-sec.png";
import couponImg from "../../../assets/images/coupon-header-icon.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ellips1 from "../../../assets/images/ellipse-1.png";
import ellips2 from "../../../assets/images/ellipse-2.png";
import ellips3 from "../../../assets/images/ellipse-3.png";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import dashImg from "../../../assets/images/dash-icon.svg";
import Container from "@mui/material/Container";
import logoImage from "../../../assets/images/logo-2.png";
import GuestNavigation from "./GuestNavigation";
import { checkGuestUserCupons } from "../../../utils/bureauHelper";
import { useTranslation } from "react-i18next";
import imgEng from "../../../assets/images/Eng.svg";
import imgspa from "../../../assets/images/Spanish.svg";

function PrivacyAndTermsHeader() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("selectedLanguage");

  const [language, setLanguage] = useState(storedLanguage);
  const lngs = {
    en: { nativeName: t("name.English"), code: "en" },
    es: { nativeName: t("name.Spanish"), code: "es" },
  };

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        backgroundColor: "#ffffff",
        width: "100%",
        boxShadow: 4,
        zIndex: 99,
        p: 2,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Box
          component="img"
          src={logoImage}
          sx={{ cursor: "pointer" }}
          alt="Logo"
          onClick={() => navigate("/")}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", lg: "flex-end" },
              alignItems: "center",
              // textAlign: "right",
            }}
          >
            <FormControl
              sx={{
                mt: 0,
                minWidth: 80,
              }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={language}
                displayEmpty
                onChange={handleChange}
                sx={{
                  padding: "7px 10px",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiSelect-select": {
                    display: "flex !important",
                    alignItems: "center !important",
                  },
                  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      display: "flex",
                      alignItems: "center",
                    },
                }}
              >
                {Object.keys(lngs).map((lng) => (
                  <MenuItem key={lngs[lng].code} value={lngs[lng].code}>
                    <img
                      src={lng === "en" ? imgEng : imgspa}
                      alt={lngs[lng].nativeName}
                      width="30"
                      height="25"
                      style={{ marginRight: "10px" }}
                    />
                    {lng === "en" ? lngs[lng].nativeName : "Español"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default PrivacyAndTermsHeader;
