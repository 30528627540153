import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import InnerTable from "./InnerTable";
import InnerTableThree from "./InnerTableThree";
import InnerTableFourth from "./InnerTableFourth";
import InnerTableSecond from "./InnerTableSecond";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function EmploymentHistory() {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "70vh", overflowY: "auto" }}>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ borderRadius: 0 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#4165F6" }}>
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                align="left"
                sx={{
                  width: "40%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
              >
                {t("landingpage.leftContent.tabs.subtab.B")}
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Report Format")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Concept")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  py: 2,
                  border: 0,
                  color: "#ffffff",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "2vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t("landingpage.leftContent.tabs.subheading.Result")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRadius: 0, mt: 2 }}>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.I"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.Report Format.Employee / 19 months"
                    )}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Estatus Laboral Ininterrumpido y Duración"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Estatus Laboral Ininterrumpido y Duración"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.II"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    HSBC Mexico, SA /{" "}
                    <Box
                      component={"span"}
                      sx={{
                        color: "warning.main",
                        fontSize: { xs: "1.5vw", sm: 14 },
                        fontFamily: "Gilroy-Medium",
                        ml: "4px",
                      }}
                    >
                      {" "}
                      1 {t("see.month")}
                    </Box>{" "}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Último Empleador y Duración"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Último Empleador y Duración"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.III"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  $29,016
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Último Salario Cotizado"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Último Salario Cotizado"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.IV"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  884 {t("see.week")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Vida Laborable Total"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Último Salario Cotizado"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.V"
                    )}
                  </Typography>
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  800 {t("see.week")} /{" "}
                  <Box
                    component={"span"}
                    sx={{
                      color: "warning.main",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      ml: "4px",
                    }}
                  >
                    {" "}
                    90%
                  </Box>{" "}
                </Typography>

                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                    mt: 2,
                  }}
                >
                  700 {t("see.week")} /{" "}
                  <Box
                    component={"span"}
                    sx={{
                      color: "warning.main",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      ml: "4px",
                    }}
                  >
                    {" "}
                    79%
                  </Box>{" "}
                </Typography>

                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                    mt: 2,
                  }}
                >
                  100 {t("see.week")} /{" "}
                  <Box
                    component={"span"}
                    sx={{
                      color: "warning.main",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      ml: "4px",
                    }}
                  >
                    {" "}
                    11%
                  </Box>{" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Total Semanas Cotizadas"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Total Semanas Cotizadas"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VI"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    4 / 31 {t("see.month")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTable />
                </Box>
              </TableCell>

              {/* <TableCell  sx={{width:"30%",border: "5px solid #ffffff !important",borderRadius:0,backgroundColor:"rgba(0, 127, 255, 0.1)"}} align="left">
                            <Box sx={{display:"flex",flexDirection:"row"}}>
                                <Typography sx={{color:"#000000",fontSize:14,fontFamily:"Gilroy-Medium"}}>$8,189 </Typography>
                          
                            </Box>
                        </TableCell> */}
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Total Semanas Cotizadas"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Total Empleadores y Duración"
                  )}
                </ul>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VII"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    52.5%
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableThree />
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {" "}
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Progresión Salarial"
                )}{" "}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Progresión Salarial"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VIII"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    1
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableSecond />
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Periodo Inactividad"
                )}{" "}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Periodo Inactividad"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "65%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.IX"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "35%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    7
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", mt: { xs: 1, sm: 4 } }}>
                  <InnerTableFourth />
                </Box>

                {/* <Box sx={{display:"flex",mt:4}}>
                          <InnerTable tableData={data[section.inner_table['data']]} dataType={section.inner_table['type']}/>
                        </Box> */}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Semanas Descontadas"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Semanas Descontadas"
                  )}
                </ul>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                borderRadius: 0,
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRadius: 0,
                },
              }}
            >
              <TableCell
                // colSpan={2}
                sx={{
                  width: "40%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="left"
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      fontSize: { xs: "1.5vw", sm: 14 },
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    {t(
                      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.X"
                    )}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell
                sx={{
                  width: "30%",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  backgroundColor: "rgba(0, 127, 255, 0.1)",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "1.5vw", sm: 14 },
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {t(
                    "landingpage.leftContent.tabs.A | Socioeconomic Study.Report Format.yes"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  border: "5px solid #ffffff !important",
                  borderRadius: 0,
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                {t(
                  "landingpage.leftContent.tabs.B | Employment History.Concept.Problemas Empleador Actual"
                )}
              </TableCell>

              <TableCell
                sx={{
                  width: "15%",
                  backgroundColor: "rgba(0, 127, 255, 0.2)",
                  borderRadius: 0,
                  border: "5px solid #ffffff !important",
                  p: { xs: "5px", sm: "16px" },
                  fontSize: { xs: "1.5vw", sm: "0.875rem" },
                }}
                align="center"
              >
                <ul>
                  {t(
                    "landingpage.leftContent.tabs.B | Employment History.Result.Problemas Empleador Actual"
                  )}
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
