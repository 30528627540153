import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import UserTypeImg from "../../../../src/assets/images/editAccount.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem } from "@mui/material";
import Fade from "@mui/material/Fade";
import axios from "axios";
import ThankYouMessage from "./ThankYouMessage";
import LogoModalLoader from "./WithBureauModal/LogoModalLoader";
import AlertModal from "./AlertModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const UserTypeModal = (props) => {
  const { userTypeModal, setUserTypeModal, userEmail, userType } = props;
  const [cookieList, setCookieList] = useState([]);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("Recruiter");
  const [dropdownSelected, setSelectedDropdown] = useState(
    "Dashboard.Recruiter"
  );
  const open = Boolean(anchorEl);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    splitAndSetCookies();
  }, []);

  const splitAndSetCookies = () => {
    const userInfoCookie = Cookies.get("userInfo");
    if (userInfoCookie != null && typeof userInfoCookie != "object") {
      setCookieList(userInfoCookie.split(","));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuItem) => {
    if (menuItem) {
      setView(menuItem);
    }
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleclosed = () => {
    setUserTypeModal(false);
  };

  const handleUserType = async () => {
    if (userType == view) {
      setUserTypeModal(false);
      return;
    }
    handleOpenLogoModal();
    const formData = new FormData();
    formData.append("email", cookieList[3]);
    formData.append("user_type", view);
    formData.append("user_email", userEmail);

    const endpoint = process.env.REACT_APP_BASE_URL2 + "perfil/update_type";
    try {
      const response = await axios.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.error === "") {
        handleCloseLogoModal();
        setUserTypeModal(false);
        setIsThankYouModalOpen(true);
      } else {
        handleCloseLogoModal();
        setUserTypeModal(false);
        setShowAlert(true);
        setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      }
    } catch (error) {
      handleCloseLogoModal();
      setUserTypeModal(false);
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };

  return (
    <>
      <ThankYouMessage
        isOpen={isThankYouModalOpen}
        setIsOpen={setIsThankYouModalOpen}
        refreshPage={true}
      />
      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />

      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <Modal
        open={userTypeModal} // Open the modal whenever it is rendered
        onClose={handleclosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={UserTypeImg}
              width="95px"
              height="95px"
              alt="email icon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h6"}
              sx={{
                color: "neutral.900",
                fontSize: 20,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("AdministratorUsers.actionsTextActions5")}{" "}
              <Box component={"span"} sx={{ color: "primary.main" }}>
                {t("AdministratorUsers.userAccount")}
              </Box>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "300px",
              m: "auto",
              // mt: 4,
            }}
          >
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                margin: "24px 0",
                // border: "1px solid #4165f6",
                color: "#4165F6",
                fontFamily: "Gilroy-Medium",
                fontSize: { xs: "20px", sm: "20px" },
                textTransform: "capitalize",
                padding: "3px 3px 0px 15px ",
                backgroundColor: open ? "rgba(65, 101, 246, 0.06)" : "inherit",
                "&:hover": {
                  backgroundColor: "rgba(65, 101, 246, 0.06)",
                },
              }}
            >
              {t(dropdownSelected)}
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: { xs: 26, sm: 28 },
                }}
              />
            </Button>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              sx={{
                marginTop: "8px",
                "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: { xs: "192px", sm: "215px" },
                  paddingTop: { xs: "0", sm: "8px" },
                  paddingBottom: { xs: "0", sm: "8px" },
                },
                " & .css-19ifamm-MuiButtonBase-root-MuiMenuItem-root": {
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                },
                "& .css-19ifamm-MuiButtonBase-root-MuiMenuItem-root:hover ": {
                  backgroundColor: "rgba(65, 101, 246, 0.06)",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose("Recruiter");
                  setSelectedDropdown("Dashboard.Recruiter");
                }}
              >
                {t("Dashboard.Recruiter")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("General");
                  setSelectedDropdown("Dashboard.General");
                }}
              >
                {t("Dashboard.General")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("E_Module");
                  setSelectedDropdown("Dashboard.E Module");
                }}
              >
                {t("Dashboard.E Module")}
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={handleUserType}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("buttons.Accept")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={handleclosed}
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {" "}
              {t("buttons.back")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserTypeModal;
