import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import mailImage from "../../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../../@common/Input/LabelInputLeftIcon";
import FormCheckboxes from "../../@common/Input/chexboxes";
import sucessImage from "../../../assets/images/thankimage.png";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function ThankYouMessage(props) {
  const { text = "", guestUser = false, isOpen, setIsOpen, path } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    if (path) {
      navigate(path);
      return;
    }
    if (props.refreshPage == true) {
      window.location.reload();
    }
  };
  const { t } = useTranslation();
  const sendHandler = () => {
    setIsOpen(false);
    if (path) {
      navigate(path);
      return;
    }
    window.close();
    if (props.refreshPage == true) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      {/* <Button
      sx={{
        backgroundColor:"primary.main",
        textAlign:"center",
        borderWidth:1,
        borderColor:"primary.main",
        borderStyle:"solid",
        borderRadius:"6px",
        py:1.5,
        px:8,
        color:"#fff",
        textDecoration:"none",
        fontSize:18,
        fontFamily:"Gilroy-Medium",
        textTransform:"capitalize"
     }}
       onClick={handleOpen} variant="contained">Send</Button> */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={sucessImage}
              width="108px"
              height="108px"
              alt="email icon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h6"}
              sx={{
                color: "neutral.900",
                fontSize: 20,
                fontFamily: "Gilroy-SemiBold",
                textAlign: "center",
              }}
            >
              {t("thanks.thank")}
              <Box component={"span"} sx={{ color: "primary.main" }}>
                {" "}
                {t("thanks.pr")}{" "}
              </Box>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={sendHandler}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
