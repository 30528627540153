import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import Fade from "@mui/material/Fade";
import underLine from "../../assets/images/under-line.png";
import { Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import ESubModule from "./ESubModule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const Emodule = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);

  useEffect(() => {
    const checkIfWeekend = () => {
      const today = new Date().getDay();
      setIsWeekend(today === 6 || today === 0);
      setOpen(today === 6 || today === 0);
    };

    checkIfWeekend();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  console.log("data");

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box mt={2} mb={2}>
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: { xs: "22px", sm: "24px" },
              lineHeight: "35px",
              textAlign: "center",
              color: "#1C212D",
            }}
          >
            {t("PageName.Make a query")}
          </Typography>
          <Box sx={{ textAlign: "center", mt: -1 }}>
            <img src={underLine} alt="under line" />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              textAlign: "center",
              color: "rgba(27, 32, 44, 0.6)",
              mt: 1,
            }}
          >
            {t("Emodule.description")}
          </Typography>
        </Box>
      </Grid>

      {isWeekend ? (
        <Dialog
          sx={{ p: 0 }}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent
            sx={{
              border: 0,
              p: 0,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            dividers={true}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <Box sx={{ my: 2, mx: 4 }}>
                  <Typography
                    sx={{
                      fontSize: 40,
                      color: "#1C212D",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {"Warning"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 24,
                      color: "rgba(27, 32, 44, 0.6)",
                      textAlign: "center",
                      mt: 2,
                    }}
                  >
                    {t("Emodule.weekendMessage")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                  <Button
                    sx={{ width: 150 }}
                    variant="contained"
                    onClick={handleClose}
                  >
                    {t("GuestDashboard.closes")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}

      <ESubModule />
    </>
  );
};

export default Emodule;
