import { Box, Button, Typography } from "@mui/material";
import BGCardImg from "../../../assets/images/coupons_cards2.svg";
import ContactFormModal from "../Modal/ContactFormModal";

import { useTranslation } from "react-i18next";
function CouponsCard(props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundImage: `url(${BGCardImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "flex",
        width: "100%",
        height: 160,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: 32,
            color: "neutral.900",
            fontFamily: "Gilroy-SemiBold",
            textAlign: "center",
            mt: 3,
            width: "100%",
          }}
          component={"h1"}
        >
          {props.cou}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Gilroy-Medium",
            textAlign: "center",
          }}
        >
          {t("PageName.Coupons")}
        </Typography>
        {/* <hr style={{
            border:"dashed 1px rgba(27, 32, 44, 0.2)",
            width:"72%",
            margin:"6px auto"
            }} /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {/* <Button 
           sx={{
            backgroundColor:"#D9E0FD",
            textAlign:"center",
            borderWidth:1,
            borderColor:"#D9E0FD",
            borderStyle:"solid",
            borderRadius:"6px",
            py:0.5,
            px:2,
            color:"primary.main",
            textDecoration:"none",
            fontSize:12,
            fontFamily:"Gilroy-Medium",
            textTransform:"capitalize"
         }}
        variant="contained">Request</Button> */}

          {/************************ Modal Import **********************/}
          <ContactFormModal
            couponType={props.couponType}
            numberOfCoupons={props.cou}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CouponsCard;
