import { Box, Typography } from "@mui/material";
import imgReport from "../../../assets/images/avatar.svg";
import lineImg from "../../../assets/images/line-header-sec.png";
import { useTranslation } from "react-i18next";

const EreportHeader = ({
    Date,
    companyRfc,
    companyName,
    companyAddress,
    years
}) => {

    const { t } = useTranslation();

    return (
        <Box
            sx={{
                backgroundColor: "#ffffff",
                px: 2,
                py: 2,
                fontSize: "7px !important",
                display: "flex",
                alignItems: "start",
                flexDirection: { xs: "row", sm: "row" },
                flexWrap: { xs: "wrap", sm: "nowrap" },
                boxShadow: 4,
                rowGap: { xs: 1, sm: 0 },
                columnGap: { xs: 3, sm: 0 },
                overflowX: "scroll"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    width: { xs: "45%", sm: "auto" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-SemiBold",
                        color: "neutral.900",
                    }}
                >
                    {t("thanks.Date")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "text.opacity",
                    }}
                >
                    {Date ? Date : ""}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", sm: "flex" },
                    mx: { xs: 0.6, md: 4 },
                    mt: { xs: 1.6, md: 1.2 },
                }}
            >
                <img src={lineImg} height={50} alt="line" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    width: { xs: "45%", sm: "30%" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-SemiBold",
                        color: "neutral.900",
                    }}
                >
                    {t("Emodule.Company Name Only")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "text.opacity",
                    }}
                >
                    {companyName ? companyName : ""}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", sm: "flex" },
                    mx: { xs: 0.6, md: 4 },
                    mt: { xs: 1.6, md: 1.2 },
                }}
            >
                <img src={lineImg} height={50} alt="line" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    width: { xs: "45%", sm: "auto" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-SemiBold",
                        color: "neutral.900",
                    }}
                >
                    {t("thanks.Years")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "text.opacity",
                    }}
                >
                    {years ? years : ""}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", sm: "flex" },
                    mx: { xs: 0.6, md: 4 },
                    mt: { xs: 1.6, md: 1.2 },
                }}
            >
                <img src={lineImg} height={50} alt="line" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    width: { xs: "45%", sm: "auto" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-SemiBold",
                        color: "neutral.900",
                    }}
                >
                    {t("Emodule.Address")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "text.opacity",
                    }}
                >
                    {companyAddress ? companyAddress : ""}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", sm: "flex" },
                    mx: { xs: 0.6, md: 4 },
                    mt: { xs: 1.6, md: 1.2 },
                }}
            >
                <img src={lineImg} height={50} alt="line" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    width: { xs: "45%", sm: "auto" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-SemiBold",
                        color: "neutral.900",
                    }}
                >
                    R.F.C.
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: 12, md: 16 },
                        fontFamily: "Gilroy-Medium",
                        color: "text.opacity",
                    }}
                >
                    {companyRfc ? companyRfc : ""}
                </Typography>
            </Box>

        </Box>
    )
}

export default EreportHeader