import { useTranslation } from "react-i18next";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import mailImage from "../../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../../@common/Input/LabelInputLeftIcon";
import FormCheckboxes from "../../@common/Input/chexboxes";
import sucessImage from "../../../assets/images/thankimage.png";
import personIcon from "../../../assets/images/person.svg";
import profilePic from "../../../assets/images/profile-pic.svg";
import { styled } from "@mui/material/styles";
import cameraIcons from "../../../assets/images/p_cameraIcon.svg";
import mailIcon from "../../../assets/images/mailIcon.png";
import phoneIcon from "../../../assets/images/phoneIcon.png";
import Cookies from "js-cookie";
import sjcl from "sjcl";
import axios from "axios";
import { encryptData } from "../../../utils/encryptionHelper";
import { serverRequest } from "../../../utils/requestHelper";
import { call_api_function } from "../../../utils/verifyAPICalls";
import { useEffect } from "react";
// ** Third Party Imports
import { useDropzone } from "react-dropzone";
import AlertModal from "../../@common/Modal/AlertModal";
import defaultimg from "../../../assets/images/avatar.svg";
// Styled component for the upload image inside the dropzone area
const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(15.75),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 160,
  },
}));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const subjectList = ["The present subject is found"];

export default function ProfileModalSecond(props) {
  const { t } = useTranslation();
  const { text = "" } = props;
  const navigate = useNavigate();
  const cookies = Cookies.get("userInfo").split(",");
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(cookies[3] ? cookies[3] : "");
  const [phone, setPhone] = useState(cookies[5] ? cookies[5] : "");
  const [name, setName] = useState(cookies[11] ? cookies[11] : "");
  const [image, setimage] = useState("");
  // ** State
  const [files, setFiles] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/png, image/jpeg, image/jpg", // Accept specific image formats
    onDrop: (acceptedFiles) => {
      // Validate file type here
      const validExtensions = ["image/png", "image/jpeg", "image/jpg"];
      const invalidFiles = acceptedFiles.filter(
        (file) => !validExtensions.includes(file.type)
      );

      if (invalidFiles.length > 0) {
        setShowAlert(true);
        setShowtext(t("BulkUpload.imageextension"));
      } else {
        setFiles(acceptedFiles.map((file) => Object.assign(file)));
      }
    },
  });

  const img = files.map((file) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ borderRadius: "8px" }}
        width={"143px"}
        height={"143px"}
        key={file.name}
        alt={file.name}
        className="single-file-image"
        src={URL.createObjectURL(file)}
      />
      <Box
        sx={{
          position: "absolute",
          mb: "-140px",
          mr: "-140px",
        }}
      >
        <img
          src={cameraIcons}
          width={"43px"}
          height={"43px"}
          alt="email icon"
        />
      </Box>
    </Box>
  ));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setOpen(false);
    props.setProfileModalOpen(false);
    setFiles([]);
  };

  const sendHandler = () => {
    updateUserProfile();
    updatePicture();
  };
  const updatePicture = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", email);

      if (files.length > 0) {
        const file = files[0]; // Get the first (and only) file from the array

        formdata.append("image", file);
        formdata.append("imageName", file.name);
      }

      const res = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "upload-user-image",
        formdata
      );
      if (res.status == 200) {
        getProfile();
      }
    } catch (e) {
      setShowAlert(true);
      setShowtext("error", e);
    }
  };

  const updateUserProfile = async () => {
    const data_eel = encryptData(cookies[3], 500);
    const data_ene = encryptData(name, 500);
    const data_eto = encryptData(phone, 500);

    let dataForm_ = {
      data_eel: {
        data_eeli: data_eel["iv"],
        data_eels: data_eel["salt"],
        data_eelc: data_eel["ct"],
      },
      data_ene: {
        data_enei: data_ene["iv"],
        data_enes: data_ene["salt"],
        data_enec: data_ene["ct"],
      },
      data_eto: {
        data_etoi: data_eto["iv"],
        data_etos: data_eto["salt"],
        data_etoc: data_eto["ct"],
      },
    };
    try {
      const response = await serverRequest(
        "post",
        "perfil/actualizar_nt",
        dataForm_
      );
      if (response.data == "actualizado") {
        // window.location.reload();
        cookies[5] = phone;
        cookies[11] = name;
        Cookies.set("userInfo", cookies.join(","));
        handleClose();
      } else {
        alert("An unexpected error occurred. Please report it");
        handleClose();
      }
    } catch (e) {
      alert("error", e);
    }
  };
  const getProfile = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", email);
      const res = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get-user-image",
        formdata
      );
      setimage(res.data);
      props.onImageUpdate(res.data);
    } catch (e) {
      setShowAlert(true);
      setShowtext("error", e);
    }
  };
  useEffect(() => {
    getProfile();
  }, [email]);



  return (
    <Fragment>
      <Modal
        open={props.profileModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            {...getRootProps({ className: "dropzone" })}
            sx={files.length ? { height: 140 } : {}}
          >
            <input {...getInputProps()} />
            {files.length ? (
              img
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {image ? (
                    <img
                      width={"143px"}
                      height={"143px"}
                      src={image}
                      alt="Main Image"
                    />
                  ) : (
                    <img
                      width={"143px"}
                      height={"143px"}
                      src={defaultimg}
                      alt="Placeholder Image"
                    />
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      mb: "-140px",
                      mr: "-140px",
                    }}
                  >
                    <img
                      src={cameraIcons}
                      width={"43px"}
                      height={"43px"}
                      alt="email icon"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Box>
              <LabelInputLeftIcon
                mailIcon={personIcon}
                type="text"
                label={t("labels.inputLabelName")}
                name="name"
                inputValue={name}
                id="Name"
                placeholder={t("placeholders.name")}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <LabelInputLeftIcon
                mailIcon={mailIcon}
                type="text"
                label={t("SuperAdminSignUp.inputs.inputLabelEmail")}
                name="email"
                inputValue={email}
                id="email"
                placeholder={t("placeholders.email")}
                disabled
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <LabelInputLeftIcon
                mailIcon={phoneIcon}
                type="text"
                label={t("labels.inputLabelNumber")}
                name="number"
                inputValue={phone}
                id="number"
                placeholder={t("placeholders.number")}                
               /> 
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={sendHandler}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("buttons.update")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                color: "rgba(27, 32, 44, 0.6)",
                fontSize: 16,
                fontFamily: "Gilroy-Medium",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {t("buttons.back")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {showAlert && (
        <AlertModal text={showtext} onClose={() => setShowAlert(false)} />
      )}
    </Fragment>
  );
}
