import { Box, Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { dataTypesConfig } from '../../../utils/EReportDataConfigAndFunction';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { PictureAsPdf } from '@mui/icons-material';
import axios from 'axios';

const StyledTableCell = styled(TableCell)(({ theme }) =>
({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
  },

  backgroundColor: "transparent",
  borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
})
);

const StyledTableRow = styled(TableRow)(({ theme }) =>
({
  borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
})
);

const ReportInnerTable = (props) => {
  const { t } = useTranslation();

  const {
    tableData
    = [
      {
        FECHASDEPRIMERAPUBLICACION: "---",
        ENTIDADFEDERATIVA: "---",
        FECHADEPUBLICACIÓN: "---",
        FECHADECANCELACIÓN: "---",
        MONTO: "---",
        SUPUESTO: "---",
        LEGAL: "---",
        HOMONIMOS: "---",
        Services: "---",
        Current: "---",
        Validity: "---",
        RegistrationDate: "---",
        Folio: "---",
        Registration: "---",
        RegistrationNotice: "---",
        Status: "---",
        FORMA_PRECODIFICADA_PDF: "---",
        Supposed: "---",
        Amount: "---",
        CancellationDate: "---",
        publicationdate: "---",
        Entity: "---",
        Date: "---",
        Situation: "---",
        DateAlleged: "---",
        DistortedDate: "---",
        DefinitiveDate: "---",
        FavorableSentenceDate: "---",
        Partner: "---",
        Nationality: "---",
        Actions: "---",
        Worth: "---",
        Legal: "---",
        PEP: "---",
        Homonyms: "---",
        Instance: "---",
        Guy: "---",
        Actor: "---",
        Defendant: "---",
        Proceedings: "---",
        Probability: "---",
        Year: "---",
        ComplaintsReceived: "---",
        ComplaintsConcluded: "---",
        Reasons: "---",
        Tablebasedondata: "---",
        Act: "---",
        Modality: "---",
        NCIDocumentNo: "---",
        EntryRegistration: "---",
        Summary: "--",
        PDF: "---",
        Guy: "---",
        Name: "---",
        No: "---",
        Tradename: "---",
        CompanyType: "---",
        TypeofFacilities: "---",
        Location: "---",
        Contact: "---",
        Activities: "---",
        ProductsorServices: "---",
        Percentage: "---",
        ActivityCode: "---",
        Doesitmatter: "---",
        Countries: "---"
      },
    ]
    ,
    dataType,
    isDownload = true,
    hideButton,
  } = props;
  const [showAllRows, setShowAllRows] = useState(isDownload);
  const { columnHeaders, createRow } = dataTypesConfig[dataType];
  const [summaries, setSummaries] = useState([]);

  // const getValueFromNestedObject = (obj, path) => {
  //   return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '---';
  // };

  const getValueFromNestedObject = (obj, path) => {
    const value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
    // Return '0' if the value is 0, otherwise return the value or '---'
    return value === 0 ? 0 : value || '---';
  };

  const rows = tableData.map((item) => {
    const config = dataTypesConfig[dataType];
    const createParams = config.createParams.map((item2) => {
      let formatedParam = '';

      if (item2.includes("+ ' ' +")) {
        const numero = getValueFromNestedObject(item, 'fedatario.numero');
        const municipio = getValueFromNestedObject(item, 'fedatario.municipio');
        const estado = getValueFromNestedObject(item, 'fedatario.estado')
        formatedParam = `${numero} ${municipio}, ${estado}`;
        return formatedParam;
      }

      if (item2.includes("/")) {
        const params = item2.split('/');
        for (let param of params) {
          formatedParam = formatedParam ? item[param] ? formatedParam + "/" + String(item[param]) : formatedParam : String(item[param]);
        }
      }

      else if (item2.includes("+")) { // Check if item2 includes '+'
        const params = item2.split('+');
        let extractedValues = [];

        for (let param of params) {
          const value = getValueFromNestedObject(item, param.trim());

          // Only add non-empty values to the array
          if (value && value !== '---') {
            extractedValues.push(value);  // Push valid value into the array
          }
        }

        // Join the valid extracted values with a comma and space
        formatedParam = extractedValues.join(', ');
      }
      else if (item2.includes("-")) { // Check if item2 includes '+'
        const params = item2.split('-');
        let extractedValues = [];

        for (let param of params) {
          const value = getValueFromNestedObject(item, param.trim());

          // Only add non-empty values to the array
          if (value && value !== '---') {
            extractedValues.push(value);  // Push valid value into the array
          }
        }

        // Join the valid extracted values with a comma and space
        formatedParam = extractedValues.join('-');
      }

      else {
        const params = item2.split(',');
        for (let param of params) {
          if (param === "") {
            formatedParam = item
          }
          else {
            // formatedParam = formatedParam + " " + String(item[param] || '---');


            // Get value from nested object using dynamic path for table G
            formatedParam = formatedParam + " " + getValueFromNestedObject(item, param.trim());
          }
        }
      }

      // Trim the formatedParam to remove leading/trailing spaces
      formatedParam = formatedParam.trim();

      // Ensure '---' only appears once
      if (formatedParam === '' || formatedParam === 'undefined' || formatedParam.split('---').length - 1 > 1) {
        formatedParam = '---'; // Set to '---' if it's empty or if it contains multiple '---'
      } else {
        // Replace any consecutive '---' with a single '---'
        formatedParam = formatedParam.replace(/---\s*---/g, '---');
      }

      return formatedParam;
      // return item[item2];
    });
    return createRow(...createParams);
  });

  const displayedRows = showAllRows ? rows : rows.slice(0, 2);
  const handleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  const getResidenciaColoniaText = (item, htmlString, columnName) => {

    if (columnName === "PDF") {
      // Get the PDF URL
      const pdfUrl = getValueFromNestedObject(item, "PDF"); // Adjust according to your data structure
      if (pdfUrl && pdfUrl !== '---') {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const link = document.createElement('a');
              link.href = pdfUrl;
              link.download = 'report.pdf';
              link.click();
            }}
            sx={{
              cursor: 'pointer',
              border: "none !important",
              padding: " 12px 20px !important"
            }}
          >
            <PictureAsPdf sx={{ fontSize: 20 }} />
          </Button>
        );
      }
    }
    if (columnName === "PEP" || columnName === "Legal") {
      if (htmlString) {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Box
                sx={{
                  width: { xs: 10, md: 25 },
                  height: { xs: 10, md: 25 },
                  border: "1px solid #ececed",
                  backgroundColor: htmlString,
                  display: "flex",
                  borderRadius: 50,
                }}
              ></Box>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Box
                sx={{
                  width: { xs: 10, md: 25 },
                  height: { xs: 10, md: 25 },
                  border: "1px solid #ececed",
                  backgroundColor: "white",
                  display: "flex",
                  borderRadius: 50,
                }}
              ></Box>
            </Box>
          </>
        );
      }
    }

    // Parse the HTML string using DOMParser
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");

    // Get the text content from the parsed HTML
    let textContent = parsedHTML.documentElement.textContent;

    // Replace specific HTML tags with an empty string
    textContent = textContent.replace(
      /<div class="textoParaJustificar">.*?<\/div>/gi,
      ""
    );
    textContent = textContent.replace(
      /<div class="textoAzul">.*?<\/div>/gi,
      ""
    );
    // Replace "ww•" with an empty string
    textContent = textContent.replace(/ww•/gi, "");

    if (columnName === "Actor" || columnName === "Defendant") {
      // Split the string by <strong> and </strong> tags
      const parts = htmlString.split(/<\/?strong>/);
      return (
        <span>
          {parts.map((part, index) => 
            index % 2 === 0 ? part : <strong key={index}>{part}</strong>
          )}
        </span>
      );
    }

    return textContent.trim();
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table
            sx={{
              borderRadius: 2,
              border: "0px solid rgba(167, 169, 169, 0.16)",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {columnHeaders &&
                  columnHeaders.map((header, index) => (
                    // two column static width 50%
                    <StyledTableCell
                      sx={{
                        p: { xs: "5px", md: "10px 16px" },
                        fontSize: { xs: "1vw", md: "0.875rem" },
                        // width: columnHeaders.length === 2 ? "50%" : "inherit",
                        width: columnHeaders.length === 2 ? "50%" : "200px",
                        maxWidth: "200px"

                      }}
                      align={index === 0 ? "left" : "center"}
                      component="th"
                      key={index}
                    >
                      {t(`${header}`)}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColorColor: "transparent" }}>
              {displayedRows.map((row, index) => (
                <StyledTableRow
                  sx={{ backgroundColor: "transparent" }}
                  key={index}
                >
                  {Object.entries(row).map(([key, value], index) => (
                    <StyledTableCell
                      sx={{
                        p: { xs: "5px", md: "10px 16px" },
                        fontSize: { xs: value.length > 100 ? "0.8vw" : "1vw", md: value.length > 100 ? "0.675rem" : "0.875rem" },
                        // width:  Object.values(row).length === 2 ? "50%" : "inherit",
                        width: "200px",
                        maxWidth: "200px"
                      }}
                      align={index === 0 ? "left" : "center"}
                      key={index}
                    >
                      {getResidenciaColoniaText(row, value, key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
          {tableData.length > 2 && (
            <Button
              sx={{
                backgroundColor: "#E5E5E5",
                height: "30px",
                width: "30px",
                borderRadius: 50,
                minWidth: "28px",
                p: 0,
              }}
              onClick={handleShowAllRows}
            >
              {showAllRows ? (
                <KeyboardArrowUpIcon sx={{ color: "#000000" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#000000" }} />
              )}
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ReportInnerTable