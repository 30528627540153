import { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const textStyle = {
  textAlign: "center",
  fontSize: 16,
  fontFamily: "Gilroy-SemiBold",
  color: "neutral.900",
};

const Countdown = ({ timeToWait }) => {
  const [secondsLeft, setSecondsLeft] = useState(timeToWait);

  // Update secondsLeft whenever timeToWait changes
  useEffect(() => {
    setSecondsLeft(timeToWait);
  }, [timeToWait]);

  useEffect(() => {
    if (secondsLeft <= 0) return;

    const interval = setInterval(() => {
      setSecondsLeft((prev) => (prev <= 1 ? 0 : prev - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsLeft]);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  return (
    <Typography sx={{ ...textStyle, textAlign: "left" }} variant="body2">
      Siguiente ping de GPS{" "}
      <strong style={{ fontFamily: "Gilroy-Medium", color: "green" }}>
        {formattedTime}
      </strong>
    </Typography>
  );
};

const TrackingPopup = ({ open, timeToWait }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography
          sx={{ textAlign: "center", borderBottom: "1px solid #E0E0E0", pb: 1 }}
          variant="h6"
        >
          {t("labels.locationTrackingActive")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ ...textStyle, textAlign: "left" }}>
          {t("labels.locationTrackingMessage")}
        </Typography>
        <Countdown timeToWait={timeToWait} />
      </DialogContent>
    </Dialog>
  );
};

export default TrackingPopup;
