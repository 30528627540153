// ** React Imports
import { Fragment, useState, useEffect } from "react";
import AlertModal from "./AlertModal";

// ** Next Import
import { Link } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

// ** Third Party Imports
import { useDropzone } from "react-dropzone";

import Modal from "@mui/material/Modal";
import upload from "../../../assets/images/UploadIcon.svg";
import fileUpload from "../../../assets/images/fileuploadIcon.svg";
import checkIcon from "../../../assets/images/checkIcon.svg";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const FileUploadModal = (props) => {
  // ** State
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const { onFileSelection } = props;
  const { hideContentHandler } = props;
  const { hideContent } = props;
  const { openAlert } = props;
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    hideContentHandler(false);
    handleRemoveAllFiles();
  };

  const importHandler = () => {
    if (files.length > 0) {
      onFileSelection(files);
    } else {
      openAlert(true, t("BulkUpload.popUpUploadButtonPopUpFileExtension"));
    }
  };

  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
    multiple: false,
    maxFiles: 1,
  });

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file)}
        />
      );
    } else {
      return <UploadIcon />;
    }
  };

  const handleRemoveFile = (file) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };
  const fileList = files.map((file) => (
    //   <ListItem key={file.name}>
    //     <Box sx={{width:"100%"}}>
    //       <div className='file-preview'>{renderFilePreview(file)}</div>
    //         <Typography className='file-name'>{file.name}</Typography>
    //         <Typography className='file-size' variant='body2'>
    //         {Math.round(file.size / 100) / 10 > 1000
    // ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
    // : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
    //         </Typography>
    //     </Box>
    //     <IconButton onClick={() => handleRemoveFile(file)}>
    //       <CloseIcon />
    //     </IconButton>
    //   </ListItem>
    <Box
      key={file.name}
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "56px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "60%",
        }}
        component={"span"}
      >
        {file.name}
      </Box>
      <Box
        onClick={handleRemoveAllFiles}
        sx={{
          color: "primary.light",
          ml: 0.5,
          cursor: "pointer",
          textDecoration: "underline",
        }}
        component={"span"}
      >
        {t("BulkUpload.popUpDeleteTextLinkUploadButton")}
      </Box>
    </Box>
  ));

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        sx={{
          backgroundColor: "#fff",
          color: "#ffffff",
          borderRadius: "10px",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#4165F6",
          width: { xs: "100%", sm: "228px", height: "202px" },
        }}
        variant="text"
      >
        <Box sx={{ mx: "auto", width: "90%", py: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width={72}
              height={72}
              src={fileUpload}
              alt="file upload icon"
            />
          </Box>
          <Typography
            sx={{
              fontSize: 18,
              fontFamily: "Gilroy-Medium",
              color: "neutral.900",
              textAlign: "center",
              mt: 3,
            }}
          >
            {localStorage.getItem("selectedLanguage") === "en" ? (
              <Typography
                sx={{
                  visibility: "hidden",
                }}
              >
                hidden text
              </Typography>
            ) : (
              ""
            )}
            {t("BulkUpload.textUploadButton")}
          </Typography>
        </Box>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!hideContent ? (
            <>
              <Box
                sx={{
                  border: "2px dashed rgba(93, 89, 98, 0.22)",
                  borderRadius: "4px",
                }}
              >
                {files.length ? (
                  <Fragment>
                    <List sx={{ width: "100%" }}>{fileList}</List>
                  </Fragment>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        my: 6,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          color="textSecondary"
                          sx={{
                            "& a": {
                              color: "primary.main",
                              textDecoration: "none",
                            },
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {t("BulkUpload.popUpTextUploadButton")}
                          <br />
                          {t("BulkUpload.o")}
                          <br />
                          <Link
                            style={{
                              marginTop: "20px",
                              textDecoration: "underline",
                            }}
                            href="/"
                            onClick={(e) => e.preventDefault()}
                          >
                            {t("BulkUpload.popUpUploadButtonPopUpNotFile")}
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  onClick={importHandler}
                  sx={{
                    backgroundColor: "primary.main",
                    py: 1,
                    px: { xs: 4, sm: 8 },
                    fontSize: "18px",
                    fontFamily: "Gilroy-Medium",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                >
                  {t("buttons.Import")}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mt: 4,
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleClose}
                  style={{
                    color: "rgba(27, 32, 44, 0.6)",
                    fontSize: 16,
                    fontFamily: "Gilroy-Medium",
                    textDecoration: "underline",
                    textTransform: "capitalize",
                  }}
                  variant="text"
                >
                  {t("buttons.back")}
                </Button>
              </Box>
            </>
          ) : null}

          {hideContent && (
            <Box>
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={checkIcon}
                  width="68px"
                  height="68px"
                  alt="email icon"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  flexDirection: "column",
                }}
              >
                <Typography
                  component={"h6"}
                  sx={{
                    color: "neutral.900",
                    fontSize: 20,
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "center",
                  }}
                >
                  {t("BulkUpload.popUpUploadButtonPopUpSuccessTitle")}{" "}
                  <Box component={"span"} sx={{ color: "primary.main" }}>
                    {t("BulkUpload.processed successfully")}!
                  </Box>
                </Typography>

                <Typography
                  sx={{
                    fontSize: 16,
                    fontFamily: "Gilroy-Medium",
                    color: "rgba(27, 32, 44, 0.6)",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  {t("BulkUpload.popUpUploadButtonPopUpSuccessSubtitle")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "primary.main",
                    py: 1,
                    px: { xs: 4, sm: 8 },
                    fontSize: "18px",
                    fontFamily: "Gilroy-Medium",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                >
                  {" "}
                  {t("BulkUpload.popUpUploadButtonPopUpSuccessButton")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default FileUploadModal;
