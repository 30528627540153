import { useState } from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "moment/locale/es";

const SearchButton = styled.button`
  background-color: #ff8d28;
  border-radius: 8px;
  height: 56px;
  width: 56px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 0 0 0 10px;
  }
`;

const QueryInputContainer = styled.div`
  display: flex;
  flex: auto;
  padding: 0 10px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const UpdateButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex: auto;
    justify-content: center;
    padding: 10px 0 0;
  }
`;

function AuthorizationPanelSearchBar(props) {
  const { t } = useTranslation();
  const { setSearchQuery, fetchPanelList, setCurrentPage, setShowMore } = props;
  moment.locale(t("language"));
  const [searchName, setSearchName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const runSearchQuery = async () => {
    setCurrentPage(1);
    setShowMore(false);
    setSearchQuery({
      query_name: searchName,
      from_date: startDate ? startDate.format("DD/MM/YYYY") : "",
      to_date: endDate ? endDate.format("DD/MM/YYYY") : "",
    });
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <DateRangePicker
          startDate={startDate}
          startDateId="start_date_id"
          endDate={endDate}
          endDateId="end_date_id"
          startDatePlaceholderText={t("authorizationpanel.summary.StartDate")}
          endDatePlaceholderText={t("authorizationpanel.summary.EndDate")}
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          isOutsideRange={() => false}
          showClearDates={true}
          numberOfMonths={1}
          displayFormat="DD/MM/YYYY"
        />
      </div>
      <QueryInputContainer>
        <input
          type="text"
          placeholder={t("authorizationpanel.summary.searchBar")}
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          style={styles.input}
        />
        <SearchButton
          onClick={runSearchQuery}
          aria-label="toggle search visibility"
        >
          <SearchIcon style={{ color: "#fff" }} />
        </SearchButton>
      </QueryInputContainer>
      <UpdateButtonContainer>
        <Button
          onClick={fetchPanelList}
          variant="text"
          sx={{
            backgroundColor: "#FF8D28",
            borderRadius: "8px",
            minHeight: "56px",
            width: 90,
            color: "#fff",
            padding: "0 56px",
          }}
        >
          {t("see.update")}
        </Button>
      </UpdateButtonContainer>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "white",
    width: "100%",
    flexWrap: "wrap",
  },
  input: {
    fontSize: "1.2rem",
    flexGrow: 1,
    border: "none",
    outline: "none",
    padding: "10px",
    minWidth: "200px",
    border: "1px solid #00000055",
  },
  button: {
    backgroundColor: "#FF8D28",
    borderRadius: "8px",
    height: "56px",
    width: "56px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    margin: "0 10px",
  },
  "@media (max-width: 768px)": {
    container: {
      flexDirection: "column",
      alignItems: "stretch",
    },
    button: {
      width: "100%",
      margin: "0 0 0 10px",
    },
  },
};

export default AuthorizationPanelSearchBar;
