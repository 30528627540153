import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import crossIcon from "../../../src/assets/images/deletefile.svg";
import underLine from "../../../src/assets/images/under-line.png";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../../src/assets/images/Iconly-Bold-Delete.svg";
import editIcon from "../../../src/assets/images/editIcon.svg";
import AddNewCommentModal from "./AddNewCommentModal";
import SuccessfullModal from "../@common/Modal/SuccessfullModal";
import AlertModal from "../@common/Modal/AlertModal";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader";
import axios from "axios";

const style = {
  "*::-webkit-scrollbar": {
    width: "7px !important", // Adjust as needed
    borderRadius: "3px !important",
  },

  "*::-webkit-scrollbar-track": {
    backgroundColor: "#F3F3F3 !important", // Adjust as needed
  },

  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#D4D4D4 !important", // Adjust as needed
    borderRadius: "3px !important", // Rounded corners
  },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "95%",
  // overflowX: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const AllCommentsModal = ({
  allCommentOpen,
  setAllCommentOpen,
  commentData,
  setAddCommentOpen,
  setEditComment,
  setIsSuccessModalOpen,
  userEmail,
}) => {
  const { t } = useTranslation();
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");

  const handleClose = () => {
    setAllCommentOpen(false);
  };
  const handleOpenAddComment = () => {
    setAllCommentOpen(false);
    setAddCommentOpen(true);
  };

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleDelete = async (commentId) => {
    handleOpenLogoModal();
    const endpoint = `${process.env.REACT_APP_BASE_URL2}delete_comment/${commentId}/${userEmail}`;

    try {
      const response = await axios.delete(endpoint);
      handleCloseLogoModal();
      if (response?.data?.message) {
        handleClose();
        setIsSuccessModalOpen(true);
      } else {
        setShowtext(response.data.error);
        setShowAlert(true);
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
      setShowAlert(true);
    }
  };

  const handleEditComment = (commentValue) => {
    handleOpenAddComment();
    setEditComment(commentValue);
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />

      <Modal
        open={allCommentOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
              height: "100%",
            }}
          >
            <IconButton onClick={handleClose}>
              <img src={crossIcon} alt="deleteIcon" />
            </IconButton>
          </Box>
          <Grid item xs={12} sm={12} sx={{ marginBottom: "6px" }}>
            <Box
              sx={{
                mt: { xs: 2, sm: 1 },
                paddingBottom: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: { xs: "22px", sm: "24px" },
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {`${t("expansionField.Comments")} (${
                  commentData.filter(
                    (value) =>
                      !value.is_private ||
                      (value.is_private && value.user_email === userEmail)
                  ).length
                })`}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              height: "100%",
              maxHeight: "60vh",
              overflowY: "auto",
              paddingRight: 1,
            }}
          >
            {commentData &&
              commentData.length > 0 &&
              commentData
                .filter(
                  (value) =>
                    !value.is_private ||
                    (value.is_private && value.user_email === userEmail)
                ) // Show only public comments or private comments made by the user
                .map((value, idx) => (
                  <ListItem
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D0D2D4",
                      marginBottom: "8px",
                      borderRadius: "6px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <ListItemText>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          //   gap:"30px",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { sm: 18, xs: 16 },
                            fontFamily: "Gilroy-Medium",
                            color: "neutral.900",
                            width: "calc(100% - 95px)",
                          }}
                        >
                          {`${idx + 1}.  ${value?.comment}`}
                        </Typography>
                        {value?.user_email === userEmail && (
                          <Box sx={{ width: "70px" }}>
                            <Button
                              sx={{ p: 0, minWidth: "20px", mr: 2 }}
                              onClick={(e) => {
                                handleEditComment(value);
                              }}
                            >
                              <img src={editIcon} alt="editIcon" />
                            </Button>
                            <Button
                              sx={{ p: 0, minWidth: "20px" }}
                              onClick={() => {
                                handleDelete(value?._id);
                              }}
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </ListItemText>
                  </ListItem>
                ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              mt: 2,
              pr: 1,
            }}
          >
            <Button
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: 3,
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
              onClick={handleOpenAddComment}
            >
              {t("expansionField.AddNewComment")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AllCommentsModal;
