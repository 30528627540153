import "./GeoLocation.css";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AlertModal from "../@common/Modal/AlertModal";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import MapModal from "../Maps/MapModal";

const GeoLocation = ({}) => {
  const search_params = new URLSearchParams(window.location.search);
  const email = search_params.get("email");
  const phone = search_params.get("phone");
  const vlid = search_params.get("vlid");
  const geo_location = search_params.get("geo_location");
  const faceid = search_params.get("faceid");

  const navigate = useNavigate();
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [closedModal, setClosedModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [taskStatus, setTaskStatus] = useState(false);

  const handleClosed = () => {
    setClosedModal(true);
    setShowtext(false);
    window.open("https://searchlook.com.mx", "_self");
  };

  return (
    <>
      {showAlert && (
        <AlertModal
          closedModal={closedModal}
          thanks={taskStatus}
          closedModalFunction={handleClosed}
          text={showtext}
          onClose={handleClosed}
        />
      )}
      <CustomSpinner visible={spinnerVisibility} />
      <MapModal vlid={vlid} />
    </>
  );
};

export default GeoLocation;
