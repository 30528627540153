import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import underLine from "../../assets/images/under-line.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import PasswordShowHideIcon from "../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import google from "../../assets/images/google.png";
import microSoft from "../../assets/images/microsoft.png";
import Footer from "../../Footer/Footer";
import bgImage from "../../assets/images/coverphoto.png";
import AnimationLoginScreen from "../@common/AnimationLoginScreen/AnimationLoginScreen";
import mailImage from "../../assets/images/mailIcon.png";
import mailSend from "../../assets/images/emailSend.png";
import ResentEmailModal from "./ResentEmailModal";
import sjcl from "sjcl";
import axios from "axios";
import { serverRequest } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { emailPattern } from "../../utils/InputPatterns";
import { validateInput } from "../../utils/inputValidation";
import { useTranslation } from "react-i18next";
import AuthLeftScreen from "../@common/AuthLeftScreen";

function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [sentLink, setSentLink] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const [formErrors, setFormErrors] = useState({
    email: "",
  });
  const handleClick = (e) => {
    const errors = {};
    validateInput(email, emailPattern, "email", errors, t);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      reset_password();
    }
  };

  const reset_password = async () => {
    var data = [];
    data.push(email);
    var dataForm = {
      data_eel: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, data[0], {
        iter: 500,
      }),
    };

    var dataFormJsonEll = JSON.parse(dataForm["data_eel"]);
    var dataForm_ = {
      data_eel: {
        data_eeli: dataFormJsonEll["iv"],
        data_eels: dataFormJsonEll["salt"],
        data_eelc: dataFormJsonEll["ct"],
      },
    };
    const response = await serverRequest(
      "post",
      "enviar_restablecer",
      dataForm_
    );
    // Email to reset password is sent
    if (response.data === true) {
      setSentLink(true);
    }
    // NOTE When the user does not exist or is not registered
    else if (response.data == false) {
      setShowAlert(true);
      setShowtext(t("thanks.userdoes"));
    }
    // NOTE when the account is not validated
    else if (response.data == "no validado") {
      setShowAlert(true);
      setShowtext("Account is not validated");
    }
    // OTE could not save token and email in the database
    else if (
      response.data == "error bd" ||
      response.data == "error servidor correo" ||
      typeof response.data == "object"
    ) {
      setShowAlert(true);
      setShowtext("OTE could not save token and email in the database");
    }
  };

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <Box
        sx={{
          background: "#fff",
        }}
        id="particles-js"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                minHeight: "100vh",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    background: "#ffffff",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: 1,
                    p: { xs: 2, sm: 4 },
                    width: { xs: "90%", sm: "500px" },
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        lineHeight: "35px",
                        textAlign: "center",
                        color: "#1C212D",
                      }}
                    >
                      {t("ForgotPassword.header.title")}
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: -1 }}>
                      <img src={underLine} alt="under line" />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={mailSend}
                      alt="mail send image"
                      width={103}
                      height={85}
                    />
                  </Box>
                  <Box sx={{ width: "100%", mx: "auto", mt: 2 }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Poppins-Medium",
                        fontSize: 16,
                        lineHeight: "25px",
                      }}
                    >
                      {t("ForgotPassword.header.subtitle")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      m: "auto",
                      mt: 2,
                      flexDirection: "column",
                    }}
                  >
                    <LabelInputLeftIcon
                      mailIcon={mailImage}
                      type="text"
                      label={t("ForgotPassword.inputs.inputLabelEmail")}
                      name="emailAddress"
                      id="emailAddress"
                      placeholder={t("ForgotPassword.inputs.inputTextEmail")}
                      onChange={(e) => setEmail(e.target.value)}
                      error={formErrors.email}
                    />
                    {formErrors.email && (
                      <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {formErrors.email}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={handleClick}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 6 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize !important",
                      }}
                      variant="contained"
                    >
                      {t("ForgotPassword.buttons.buttonTextResetPass")}
                    </Button>
                    <ResentEmailModal sentLink={sentLink} email={email} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flex: "row",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#000000",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                      }}
                    >
                      {t("ForgotPassword.buttons.returnToLoginLink")}{" "}
                      <Link
                        to="/Login"
                        style={{
                          color: "#4165F6",
                          textDecoration: "none",
                          marginLeft: "5px",
                        }}
                      >
                        {t("ForgotPassword.buttons.Login")}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthLeftScreen
              text={t("Login.buttons.signUpLink")}
              url="/Signup"
            />
          </Grid>
        </Grid>
      </Box>
      <ResentEmailModal />
    </>
  );
}

export default ForgotPassword;
