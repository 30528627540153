
// ** React Imports
import { Fragment, useState } from 'react'

// ** Next Import
import { Link } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'

import AlertModal from "../../Modal/AlertModal"
import Modal from '@mui/material/Modal';
import upload from "../../../../assets/images/UploadIcon.svg"
import { useTranslation } from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  maxWidth:500,
  bgcolor: 'background.paper',
  border:0,
  boxShadow: 24,
  p: 4,
  borderRadius:1,
};

const FileUploadInput = ({ onFileSelection }) => {
  // ** State
  const { t } = useTranslation();
  const [files, setFiles] = useState([])
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const importHandler = () => {
  if (files.length > 0) {
    const selectedFile = files[0];
    if (selectedFile.type === 'application/pdf') {
      // Proceed with further processing for PDF file
      onFileSelection(files);
      handleClose();
    } else {
      setShowAlert(true); 
      setShowtext(t("BulkUpload.pdf"));
    }
  }
  else{
    setShowAlert(true); 
    setShowtext(t("BulkUpload.pdf"));
  }
};
const handleCloseAlert = () => {
  setShowAlert(false);
};
  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file)))
    },
    accept: "application/pdf" // Specify the accepted file type as PDF
  })

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
    } else {
      return <UploadIcon />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const fileList = files.map(file => (
  //   <ListItem key={file.name}>
  //     <Box sx={{width:"100%"}}>
  //       {/* <div className='file-preview'>{renderFilePreview(file)}</div> */}
  //         {/* <Typography className='file-name'>{file.name}</Typography> */}
  //         {/* <Typography className='file-size' variant='body2'>
  //         {Math.round(file.size / 100) / 10 > 1000
  // ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
  // : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
  //         </Typography> */}
  //     </Box>
  //     {/* <IconButton onClick={() => handleRemoveFile(file)}>
  //       <CloseIcon />
  //     </IconButton> */}
  //   </ListItem>
     <Box key={file.name} sx={{display:"flex", justifyContent:"center",border:"solid 1px #E6E8F0",borderRadius:1,height:"56px",alignItems:"center"}}>
     <Box sx={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",width:"60%", display: "inline",ml:0.5}} component={"span"}>{file.name}</Box><Box onClick={handleOpen} sx={{color:"primary.light",ml:1,cursor:"pointer",textDecoration:"underline",width:"60%"}} component={"span"}>{t("LaborReferencePage.Changefile")}</Box>
  </Box>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <Fragment>
       {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
       <div>
       <Box sx={{ color: "text.opacity", fontSize: 16, fontFamily: "Gilroy-SemiBold", }} component={"label"}>{t("LaborReferencePage.Attachfile")}</Box>
       {files.length ? (
       <Fragment>
       <List sx={{width:"100%"}}>{fileList}</List>
     </Fragment>
            ) 
            : 
            <Box sx={{display:"flex", justifyContent:"center",border:"solid 1px #E6E8F0",borderRadius:1,height:"56px",alignItems:"center",mt:1,cursor:"pointer"}} onClick={handleOpen}>
            <img width={"20px"} src={upload} alt="Upload file" /> <Box sx={{ml:2}} component={"span"}>{t("LaborReferencePage.Uploadfile")} - </Box><Box sx={{color:"primary.light",ml:0.5}} component={"span"}>{t("LaborReferencePage.selectfile")}</Box>
          </Box>
            }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box sx={{border:"2px dashed rgba(93, 89, 98, 0.22)",borderRadius:"4px"}}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Box sx={{ display: 'flex', alignItems: 'center',width:"100%",my:6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "center",width:"100%" }}>
            <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' },textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center" }}>
            {t("LaborReferencePage.Draganddrop")}<br />
            {t("LaborReferencePage.or")}<br />
              <Link style={{marginTop:"20px",textDecoration:"underline"}} href='/' onClick={e => e.preventDefault()}>
              {t("LaborReferencePage.selectfile")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </div>

      {/* {files.length ? (
        <Fragment>
          <List sx={{width:"100%"}}>{fileList}</List>
        </Fragment>
      ) : null} */}
      
      </Box>
      <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
          <Button onClick={importHandler} sx={{backgroundColor:"primary.main",py:1,px:{xs:4,sm:8},fontSize:"18px",  fontFamily: "Gilroy-Medium",textTransform:"capitalize" }} variant="contained">   {t("buttons.Import")}</Button>
          </Box>
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4,mb:1}}>
            <Button onClick={handleClose} style={{color:"rgba(27, 32, 44, 0.6)",fontSize:16,fontFamily:"Gilroy-Medium",textDecoration:"underline",textTransform:"capitalize"}}  variant="text">{t("buttons.back")}</Button>
          </Box>
      </Box>
      </Modal>
      </div>
    </Fragment>
  )
}

export default FileUploadInput
