import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
function AccordainHeader(props) {
  const { t } = useTranslation();
  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                py: 0,
                border: 0,
                color: "#ffffff",
                p: { xs: "0px 6px 0px 0px", md: "0px 12px" },
                fontSize: {
                  xs: "2vw",
                  md: "0.875rem",
                },
              }}
            >
              {props.tableRowTitle}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                width: props.tableRowTitle !== "H" ? "30%" : null,
                py: 0,
                border: 0,
                color: "#ffffff",
                p: { xs: "0px 5px", md: "0px 12px" },
                fontSize: { xs: "2vw", md: "0.875rem" },
                lineHeight: { xs: "2.5vw", md: "1.5rem" },
              }}
            >
              {props.tableRowheaderTitle}
            </TableCell>
            {props.tableRowTitle !== "H" && (
              <>
                <TableCell
                  sx={{
                    width: "30%",
                    py: 0,
                    border: 0,
                    color: "#ffffff",
                    p: { xs: "0px 5px", md: "0px 12px" },
                    fontSize: { xs: "2vw", md: "0.875rem" },
                  }}
                  align="center"
                >
                  {t("landingpage.leftContent.tabs.subheading.Report Format")}
                </TableCell>
                <TableCell
                  sx={{
                    width: "20%",
                    py: 0,
                    border: 0,
                    color: "#ffffff",
                    p: { xs: "0px 5px", md: "0px 12px" },
                    fontSize: { xs: "2vw", md: "0.875rem" },
                  }}
                  align="center"
                >
                  {t("landingpage.leftContent.tabs.subheading.Concept")}
                </TableCell>
                <TableCell
                  sx={{
                    width: "20%",
                    py: 0,
                    border: 0,
                    color: "#ffffff",
                    p: { xs: "0px 5px", md: "0px 12px" },
                    fontSize: { xs: "2vw", md: "0.875rem" },
                  }}
                  align="center"
                >
                  {t("landingpage.leftContent.tabs.subheading.Result")}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
      </Table>
    </>
  );
}

export default AccordainHeader;
