import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
const customStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  maxWidth: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

export default function AutoCompleteModal(props) {
  const { onClose, thanks, curp, name, last_name, mother_last_name, sexType, date_of_brith, birthState ,onAccept} = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
             <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                textAlign: "center",
                mt: 2,
              }}
            >
              <span>
                <strong>{t("Dashboard.useData")}</strong>
                <br></br>
                {t("Dashboard.Matches")} <br></br> 
                {t("Dashboard.LookingFor")}</span>
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mt: 2,
                marginLeft:'20px',
              }}
            >
             <span style={{ fontWeight: "bold" }}>CURP: </span>{curp}
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mt: 2,
                marginLeft:'20px',
              }}
            >
             <span style={{ fontWeight: "bold" }}>{t("Dashboard.Name")} </span>{name} {last_name} {mother_last_name}
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mt: 2,
                marginLeft:'20px',
              }}
            >
              <span style={{ fontWeight: "bold" }}>{t("Dashboard.DOB")} </span>{date_of_brith}
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mt: 2,
                marginLeft:'20px',
              }}
            >
             <span style={{ fontWeight: "bold" }}>{t("Dashboard.Sex")} </span>{sexType}
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                mt: 2,
                marginLeft:'20px',
              }}
            >
            <span style={{ fontWeight: "bold" }}> {t("Dashboard.Edo")}</span> {birthState}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
            <Button onClick={onClose} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 8 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize", mx: 2 }} variant="contained">{t("Dashboard.Back")}</Button>
            <Button onClick={onAccept} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 8 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize", mx: 2 }} variant="contained">{t("Dashboard.Accept")}</Button>
          </Box>

        </Box>
      </Modal>
    </Fragment>
  );
}
