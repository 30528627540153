import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useState } from "react";
import { List, ListItem, Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import NoticePrivacy from "../components/NoticePrivacy";
import TermsConditions from "../components/TermsConditions";
import { useTranslation } from "react-i18next";
function Footer(props) {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenUploadModal = () => {
    setOpenUploadModal(!openUploadModal);
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(!openTermsModal);
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          position: "fixed",
          right: 20,
          zIndex: 9,
          bottom: 160,
          zIndex: 99,
        }}
      >
        <Link
          href="/aidVideo"
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            height: 42,
            width: 42,
            borderRadius: 50,
            alignItems: "center",
            boxShadow: 10,
          }}
        >
          <QuestionMarkIcon sx={{ color: "primary.light" }} />
        </Link>
      </Box>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.6)",
          // position: {
          //   xs: "relative",
          //   sm: "relative",
          //   md: "relative",
          //   lg: "absolute",
          // },
          // zIndex: 2,
          // bottom: { xs: "0", sm: "0", md: "0", lg: props.bottomSection },
          width: "100%",
          mt: { xs: 10, sm: 10, md: 10, lg: 0 },
          pb: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2}></Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "22px",
                  fontFamily: "Poppins-SemiBold",
                }}
              >
                {t("landingpage.rightContent.footer.title")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
              <List
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  p: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: { xs: "column", md: "row" },
                    p: 0,
                  }}
                >
                  <ListItem
                    sx={{
                      px: 1,
                      textAlign: "center !important",
                      borderRight: {
                        xs: "solid 0px rgba(255, 255, 255, 0.8)",
                        sm: "solid 2px rgba(255, 255, 255, 0.8)",
                      },
                      py: 0,
                    }}
                  >
                    <Box
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                        fontFamily: "Poppins-Medium",
                        textDecoration: "none",
                        width: { xs: "100%", sm: "150px" },
                      }}
                    >
                      {t("landingpage.rightContent.footer.subtitle.Searchlook")}
                    </Box>
                  </ListItem>
                  <ListItem
                    sx={{
                      px: 1,
                      textAlign: "center !important",
                      borderRight: {
                        xs: "solid 0px rgba(255, 255, 255, 0.8)",
                        sm: "solid 2px rgba(255, 255, 255, 0.8)",
                      },
                      py: 0,
                    }}
                  >
                    <Box
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                        fontFamily: "Poppins-Medium",
                        textDecoration: "none",
                        width: { xs: "100%", sm: "158px" },
                      }}
                    >
                      {/* {t(
                        "landingpage.rightContent.footer.subtitle.Mississippi River 49"
                      )} */}
                      Río Missisipi 49
                    </Box>
                  </ListItem>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: { xs: "column", md: "row" },
                    p: 0,
                  }}
                >
                  <ListItem
                    sx={{
                      px: 1,
                      textAlign: "center !important",
                      borderRight: {
                        xs: "solid 0px rgba(255, 255, 255, 0.8)",
                        sm: "solid 2px rgba(255, 255, 255, 0.8)",
                      },
                      py: 0,
                    }}
                  >
                    <Link
                      onClick={() => handleOpenUploadModal()}
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                        fontFamily: "Poppins-Medium",
                        textDecoration: "none",
                        width: { xs: "100%", sm: "140px" },
                        cursor: "pointer",
                      }}
                    >
                      {t(
                        "landingpage.rightContent.footer.subtitle.Privacy Notice"
                      )}
                    </Link>
                  </ListItem>
                  <ListItem sx={{ px: 1, py: 0, ml: 1 }}>
                    <Link
                      onClick={() => handleOpenTermsModal()}
                      sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                        fontFamily: "Poppins-Medium",
                        textDecoration: "none",
                        width: { xs: "100%", sm: "230px" },
                        cursor: "pointer",
                      }}
                    >
                      {t(
                        "landingpage.rightContent.footer.subtitle.Terms and Conditions"
                      )}
                    </Link>
                  </ListItem>
                </Box>
              </List>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Link
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 50,
                    px: 2,
                    py: 0.5,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  href="tel:55 3332 6533"
                >
                  <CallIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />
                  {t("landingpage.rightContent.footer.contactData.tel")}
                </Link>
                <Link
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    textDecoration: "none",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 50,
                    px: 2,
                    py: 0.5,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  href="mailto:contact@searchlook.mx"
                >
                  <MailIcon sx={{ mt: 0.5, mr: 1 }} fontSize="20px" />{" "}
                  {t("landingpage.rightContent.footer.contactData.email")}
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2}></Grid>
        </Grid>
      </Box>
      <NoticePrivacy
        open={openUploadModal}
        handleClose={handleOpenUploadModal}
      />
      <TermsConditions
        openTerms={openTermsModal}
        handleTermsClose={handleOpenTermsModal}
      />
    </Fragment>
  );
}

export default Footer;
