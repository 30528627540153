import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import calendar from "../../../assets/images/calendarimg.png";
import lineImg from "../../../assets/images/line-header-sec.png";
import couponImg from "../../../assets/images/coupon-header-icon.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ellips1 from "../../../assets/images/ellipse-1.png";
import ellips2 from "../../../assets/images/ellipse-2.png";
import ellips3 from "../../../assets/images/ellipse-3.png";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import dashImg from "../../../assets/images/dash-icon.svg";
import Cookies from "js-cookie";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { call_api_function } from "../../../utils/verifyAPICalls";
import { serverRequest2 } from "../../../utils/requestHelper";

import "./Header.css";
import imgEng from "../../../assets/images/Eng.svg";
import imgspa from "../../../assets/images/Spanish.svg";
import NotificationDropdown from "./NotificationDropdown";
import errorImage from "../../../assets/images/error-img.svg";
import warningImg from "../../../assets/images/warning-img.svg";
import successImg from "../../../assets/images/success-img.svg";
import GlobalNotification from "./NotificationModal/GlobalNotification";
import { getCookiesList } from "../../../utils/cookiesHelper";
import emoduleCoupon from "../../../../src/assets/images/EmoduleCoupon.svg"
import consultCoupon from "../../../../src/assets/images/consultCoupon.svg"

function Header() {
  const [notifications, setNotifications] = useState([]);
  const [showWarning, setshowWarning] = useState(false);
  const [showSuccess, setshowsuccess] = useState(false);
  const [showError, setshowerror] = useState(false);
  const [descriptionWarning, setdescriptionWarning] = useState("");
  const [descriptionSuccess, setdescriptionSuccess] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [titleWarning, settitleWarning] = useState("");
  const [titleSuccess, settitleSuccess] = useState("");
  const [titleError, settitleError] = useState("");
  const [cookieList, setCookieList] = useState([]);
  const storedLanguage = localStorage.getItem("selectedLanguage");

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);


  useEffect(() => {
    const fetchInitialNotifications = async () => {
      try {
        const response = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + "get_global_notifications"
        );
        const formdata = new FormData();

        formdata.append("input_dict", JSON.stringify(response.data));

        formdata.append(
          "source_language",
          storedLanguage === "en" ? "es" : "en"
        );

        formdata.append(
          "target_language",
          storedLanguage === "en" ? "en" : "es"
        );
        const res = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + "translate_report_data",
          formdata
        );

        // Check each notification in the response array
        res.data.forEach((notification) => {
          if (notification.type === "yellow" && notification.activate) {
            setshowWarning(true);
            setdescriptionWarning(notification.content);
            settitleWarning(notification.header); // Set description based on the 'YELLOW' notification
          } else if (notification.type === "green" && notification.activate) {
            setshowsuccess(true);
            setdescriptionSuccess(notification.content);
            settitleSuccess(notification.header);
          } else if (notification.type === "red" && notification.activate) {
            setshowerror(true);
            setdescriptionError(notification.content);
            settitleError(notification.header);
          }
        });
      } catch (error) {
        console.error("Error fetching initial notifications:", error);
      }
    };

    fetchInitialNotifications();
  }, [storedLanguage]);

  // The state variables showWarning, showsuccess, and showerror will be updated based on the conditions in the response data.
  // Empty dependency array to run the effect only once
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [noOfCoupons, setNoOfCoupons] = useState({
    CB: { disponible: 0, total: 0 },
    SB: { disponible: 0, total: 0 },
    Companies: { disponible: 0, total: 0 },
  });
  const userInfo = Cookies.get("userInfo");
  const userEmail = userInfo.split(",")[3];
  const user_id = userInfo.split(",")[6];
  // Get the selected language from local storage
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  // Function to format the date based on the language
  function formatLocalizedDate(date, language) {
    if (window.innerWidth <= 768) {
      if (language === "en") {
        return date.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      } else if (language === "es") {
        const formattedDate = date.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

        // Remove the "de" before the month
        return formattedDate.replace(/ de /g, " ");
      } else {
        // Default to English if the language is not specified or invalid
        return date.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      }
    } else {
      if (language === "en") {
        return date.toLocaleDateString("en-US", {
          weekday: "long",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      } else if (language === "es") {
        const formattedDate = date.toLocaleDateString("es-ES", {
          weekday: "long",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

        // Remove the "de" before the month
        return formattedDate.replace(/ de /g, " ");
      } else {
        // Default to English if the language is not specified or invalid
        return date.toLocaleDateString("en-US", {
          weekday: "long",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      }
    }
  }

  const currentDate = new Date();
  const formattedDate = formatLocalizedDate(currentDate, selectedLanguage);

  const [images, setImages] = useState([]);
  // State to hold the selected language

  const [language, setLanguage] = useState(storedLanguage);
  const lngs = {
    en: { nativeName: t("name.English"), code: "en" },
    es: { nativeName: t("name.Spanish"), code: "es" },
  };

  const UserCoupons = async () => {
    const formdata = new FormData();
    formdata.append("email", userEmail);
    const response = await call_api_function(
      process.env.REACT_APP_BASE_URL2 + "perfil/checar_cupones",
      formdata
    );
    //const response = await serverRequest2("post",'perfil/checar_cupones',userEmail,{},30000)
    if ((response.status === 200)) {
      setNoOfCoupons(response.data);
    } else {
      setNoOfCoupons({
        CB: { disponible: 0, total: 0 },
        SB: { disponible: 0, total: 0 },
        Companies: { disponible: 0, total: 0 },
      });
    }
  };
  useEffect(() => {
    if (userEmail) {
      UserCoupons(userEmail);
    }
  }, [userEmail]);

  const pathHanler = () => {
    navigate("/authorizationpanel");
  };
  const pathhandlerCoupan = () => {
    navigate("/Coupons");
  };
  // Function to handle language change
  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };
  const getProfileimages = async () => {
    try {
      const formdata = new FormData();
      formdata.append("user_mail", userEmail);
      const res = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "company-user-images",
        formdata
      );

      if (res.data) {
        if (res.data === "User not found") {
          // Handle the case when user is not found
          // For example, you might set a default value for the images state
          setImages([]);
        } else {
          // User data is found, update the images state with fetched data
          setImages(res.data);
        }
      } else {
        // Handle the case when res.data is null
        // For example, you might set a default value for the images state
        setImages([]);
      }
    } catch (e) {
      // alert("error", e);
    }
  };

  useEffect(() => {
    getProfileimages();
  }, [userEmail]);
  return (
    /*  */
    <Box
      sx={{
        p: { sm: 2, xs: 1 },
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {showWarning && (
        <GlobalNotification
          image={warningImg}
          title={titleWarning}
          description={descriptionWarning} // Pass the specific description state
          btnColor="warning"
          bgColor="warning.main"
        />
      )}
      {showError && (
        <GlobalNotification
          image={errorImage}
          title={titleError}
          description={descriptionError}
          btnColor="error"
          bgColor="error.main"
        />
      )}
      {showSuccess && (
        <GlobalNotification
          image={successImg}
          title={titleSuccess}
          description={descriptionSuccess}
          btnColor="success"
          bgColor="success.main"
        />
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            backgroundColor: "rgba(0, 127, 255, 0.1)",
            alignItems: "center",
            justifyContent: "center",
            px: "12px",
            py: "10px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={calendar} width={20} height={22} alt="calendar" />
          </Box>
          <Box sx={{ ml: 1.5 }}>
            <Typography
              sx={{
                fontSize: { xs: 12, sm: 14, lg: 18 },
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
              }}
            >
              {formattedDate}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            mx: { lg: ((cookieList[19] === "General") || (cookieList[18] === "General")) ? 2 : 6, xs: 6 },
            mt: 1.2,
          }}
        >
          <img src={lineImg} height={40} alt="line" />
        </Box>

        {
          ((cookieList[19] === "E_Module") || (cookieList[18] === "E_Module")) ?
            (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "rgba(255, 140, 0, 0.1)",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "8px",
                  py: "10px",
                  height: "100%",
                  borderRadius: "8px",
                  cursor: "pointer",
                  columnGap: 1,
                  marginLeft: "10px"
                }}
                onClick={pathhandlerCoupan}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={emoduleCoupon} width={35} height={35} alt="calendar" />
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: 1,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 14 },
                          fontFamily: "Gilroy-SemiBold",
                          color: "#FF8D00",
                          textAlign: "center",
                        }}
                      >
                        {t("Dashboard.Companies")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 10, sm: 14 },
                          fontFamily: "Gilroy-SemiBold",
                          color: "#1C212D",
                          textAlign: "center",
                        }}
                        id="Companies"
                      >
                        {noOfCoupons?.Companies?.disponible}/{noOfCoupons?.Companies?.total}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) :
            ((cookieList[19] === "Recruiter") || (cookieList[18] === "Recruiter")) ?
              (

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "rgba(255, 140, 0, 0.1)",
                    alignItems: "center",
                    justifyContent: "center",
                    px: "8px",
                    py: "10px",
                    height: "100%",
                    borderRadius: "8px",
                    cursor: "pointer",
                    columnGap: { sm: 1, xs: "5px" },
                  }}
                  onClick={pathhandlerCoupan}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={consultCoupon} width={35} height={35} alt="calendar" />
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        columnGap: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, sm: 14 },
                            fontFamily: "Gilroy-SemiBold",
                            color: "#FF8D00",
                            textAlign: "center",
                          }}
                        >
                          {t("Dashboard-main.header.coupons.CBwithBuro")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, sm: 14 },
                            fontFamily: "Gilroy-SemiBold",
                            color: "#1C212D",
                            textAlign: "center",
                          }}
                          id="CB"
                        >
                          {noOfCoupons.CB?.disponible}/{noOfCoupons.CB?.total}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={lineImg} height={35} alt="line" />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, sm: 14 },
                            fontFamily: "Gilroy-SemiBold",
                            color: "#FF8D00",
                            textAlign: "center",
                          }}
                        >
                          {t("Dashboard-main.header.coupons.SBwithoutBuro")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, sm: 14 },
                            fontFamily: "Gilroy-SemiBold",
                            color: "#1C212D",
                            textAlign: "center",
                          }}
                          id="SB"
                        >
                          {noOfCoupons.SB?.disponible}/{noOfCoupons.SB?.total}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) :
              (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "rgba(255, 140, 0, 0.1)",
                      alignItems: "center",
                      justifyContent: "center",
                      px: "8px",
                      py: "10px",
                      height: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                      columnGap: 1,
                    }}
                    onClick={pathhandlerCoupan}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={consultCoupon}
                        alt="calendar"
                        sx={{
                          width: { xs: 20, sm: 35 },
                          height: { xs: 20, sm: 35 }
                        }}
                      />

                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          columnGap: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: { xs: 9, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#FF8D00",
                              textAlign: "center",
                            }}
                          >
                            {t("Dashboard-main.header.coupons.CBwithBuro")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: 9, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#1C212D",
                              textAlign: "center",
                            }}
                            id="CB"
                          >
                            {noOfCoupons.CB?.disponible}/{noOfCoupons.CB?.total}
                          </Typography>
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img src={lineImg} height={35} alt="line" />
                        </Box>

                        <Box>
                          <Typography
                            sx={{
                              fontSize: { xs: 8, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#FF8D00",
                              textAlign: "center",
                            }}
                          >
                            {t("Dashboard-main.header.coupons.SBwithoutBuro")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: 8, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#1C212D",
                              textAlign: "center",
                            }}
                            id="SB"
                          >
                            {noOfCoupons.SB?.disponible}/{noOfCoupons.SB?.total}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "rgba(255, 140, 0, 0.1)",
                      alignItems: "center",
                      justifyContent: "center",
                      px: "8px",
                      py: "10px",
                      height: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                      columnGap: 1,
                      marginLeft: { sm: "10px", xs: "6px" }
                    }}
                    onClick={pathhandlerCoupan}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={emoduleCoupon}
                        alt="calendar"
                        sx={{
                          width: { xs: 20, sm: 35 },
                          height: { xs: 20, sm: 35 }
                        }}
                      />

                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          columnGap: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: { xs: 9, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#FF8D00",
                              textAlign: "center",
                            }}
                          >
                            {t("Dashboard.Companies")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: 9, sm: 14 },
                              fontFamily: "Gilroy-SemiBold",
                              color: "#1C212D",
                              textAlign: "center",
                            }}
                            id="Companies"
                          >
                            {noOfCoupons?.Companies?.disponible}/{noOfCoupons?.Companies?.total}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )
        }

        <Box
          sx={{
            mx: { lg: ((cookieList[19] === "General") || (cookieList[18] === "General")) ? 2 : 6, xs: 6 },
            mt: 1.2,
            display: { xs: "none", md: "flex" },
          }}
        >
          <img src={lineImg} height={40} alt="line" />
        </Box>
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AvatarGroup total={3}>
            {images && Array.isArray(images) && images.length > 0
              ? images.slice(0, 3).map((image, index) => (
                <div
                  key={index}
                  className={`avatar-container ${image.image_field ? "no-hover" : ""
                    }`}
                >
                  <Avatar
                    alt={`Ellips${index}`}
                    src={
                      image.image_field
                        ? `${image.image_field}?${image.nombre}`
                        : ""
                    }
                    className="avatar"
                  >
                    {image.image_field
                      ? null
                      : image.nombre.length > 1
                        ? `${image.nombre.slice(0, 1)}...`
                        : image.nombre}
                  </Avatar>
                  <div className="full-name">{image.nombre}</div>
                </div>
              ))
              : // Display empty avatars when there are no images
              [...Array(3)].map((_, index) => (
                <div key={index} className="avatar-container">
                  <Avatar className="avatar"></Avatar>
                </div>
              ))}
          </AvatarGroup>

          <Link>
            <Box
              sx={{
                borderStyle: "dotted",
                borderWidth: 1,
                borderColor: "neutral.900",
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                height: 40,
                display: "flex",
                borderRadius: 50,
                ml: 1,
              }}
            >
              <AddIcon />
            </Box>
          </Link>
        </Box>
        {/* <Box sx={{ display: { xs: "none", sm: "none", lg: "flex" }, justifyContent: "center", alignItems: "center" }}>
        <TextField
          sx={{
            "& .MuiOutlinedInput-input": {
              px: "11px",
              py: "14px"
            }
          }}
          fullWidth
          id="outlined-basic"
          label=""
          placeholder="Search"
          variant="outlined"
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: "neutral.600", fontSize: 30, mr: 1 }} />
          }}

            />
        </Box> */}
      </Box>
      <NotificationDropdown />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", lg: "flex-end" },
            alignItems: "center",
            // textAlign: "right",
          }}
        >
          <FormControl
            sx={{
              mt: 0,
              minWidth: 80,
              display: { xs: "none", lg: "block" },
            }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={language}
              displayEmpty
              onChange={handleChange}
              sx={{
                padding: "7px 10px",
                display: "flex",
                alignItems: "center",
                "& .MuiSelect-select": {
                  display: "flex !important",
                  alignItems: "center !important",
                },
                "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {Object.keys(lngs).map((lng) => (

                <MenuItem key={lngs[lng].code} value={lngs[lng].code}>
                  <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">
                    <img
                      src={lng === "en" ? imgEng : imgspa}
                      alt={lngs[lng].nativeName}
                      width="30"
                      height="25"
                      style={{ marginRight: "10px" }}
                    />
                  </Tooltip>
                  {lng === "en" ? lngs[lng].nativeName : "Español"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              mt: 0,
              minWidth: 50,
              display: { xs: "block", lg: "none" },
            }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={language}
              displayEmpty
              onChange={handleChange}
              sx={{
                padding: "4px 0px !important",
                display: "flex",
                alignItems: "center",
                "& .MuiSelect-select": {
                  display: "flex !important",
                  alignItems: "center !important",
                },
                "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {Object.keys(lngs).map((lng) => (
                <MenuItem
                  key={lngs[lng].code}
                  value={lngs[lng].code}
                  sx={{ justifyContent: "center" }}
                >
                  <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">
                    <img
                      src={lng === "en" ? imgEng : imgspa}
                      alt={lngs[lng].nativeName}
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {/* <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"center",textAlign:"right"}}>
        <Button onClick={pathHanler} size="large" sx={{backgroundColor:"warning.main",fontSize:{xs:12,sm:14,lg:18},fontFamily: "Gilroy-Medium",textTransform:"capitalize"}} variant="contained" startIcon={<img src={dashImg} width={19} height={19} alt="dashboard"/>}>
            {t("buttons.panel")}
        </Button>

      </Box> */}
    </Box >
  );
}

export default Header;
