// ** React Imports
import { Fragment, useState } from "react";
// ** MUI Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import errorImage from "../../../../assets/images/error-img.svg";
import { Box, Grid, Typography, Divider } from "@mui/material";

const GlobalNotification = (props, { popperPlacement }) => {
  // ** States
  const [open, setOpen] = useState(true);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  const { t } = useTranslation();
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    image,
    title = "Error!",
    description,
    btnColor = "error",
    bgColor = "error.main",
  } = props;

  return (
    <Fragment>
      <Dialog
        sx={{ p: 0 }}
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent
          sx={{
            border: 0,
            p: 0,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          dividers={true}
        >
          <Box
            sx={{
              backgroundColor: bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2,
            }}
          >
            <img height={56} src={image} alt="image not here" />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Box sx={{ my: 2, mx: 4 }}>
                <Typography
                  sx={{
                    fontSize: 40,
                    color: "#1C212D",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 24,
                    color: "rgba(27, 32, 44, 0.6)",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Box sx={{ textAlign: "center", mb: 4 }}>
                <Button
                  sx={{ width: 150 }}
                  color={btnColor}
                  variant="contained"
                  onClick={handleClose}
                >
                  {t("GuestDashboard.closes")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default GlobalNotification;
