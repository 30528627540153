import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const EreportSubInnerTable = (props) => {
    const { section, data } = props;
    const { t } = useTranslation();
    const StyledTableCell = styled(TableCell)(({ theme }) =>
    ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "transparent",
            color: theme.palette.common.black,
        },

        backgroundColor: "transparent",
        borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
    })
    );
    const StyledTableRow = styled(TableRow)(({ theme }) =>
    ({
        borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
    })
    );
    return (
        <>
            <TableCell
                colSpan={2}
                sx={{
                    width: "60%",
                    borderBottom: "5px solid #ffffff !important",
                    borderRadius: 0,
                    fontSize: { xs: "1vw", md: "0.875rem" },
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                        sx={{
                            color: "primary.main",
                            width: "50%",
                            fontSize: { xs: "1vw", md: 14 },
                            fontFamily: "Gilroy-Medium",
                        }}
                    >
                        {t(`${section.title}`)}
                    </Typography>

                    <Typography
                        sx={{
                            color: (!data.reportData[section.parent_key]?.[section.child_key]?.[section.format]) ? "#000000" : "primary.main",
                            width: "50%",
                            fontSize: { xs: "1vw", md: 12 },
                            fontFamily: "Gilroy-Medium",
                            textAlign: "center",
                        }}
                    >
                        {
                            (!data.reportData[section.parent_key]?.[section.child_key]?.[section.format]) ?
                                "No"
                                : data.reportData[section.parent_key]?.[section.child_key]?.[section.format]?.length || "NO"
                        }

                    </Typography>
                </Box>
                {
                    section.format === "CONSEJO DE ADMINISTRACIÓN" ?
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TableContainer sx={{ borderRadius: 1 }}>
                                {
                                    data.reportData[section.parent_key]?.[section.child_key]?.[section.format]?.length > 0 &&
                                    <Table
                                        sx={{
                                            borderRadius: 2,
                                            border: "0px solid rgba(167, 169, 169, 0.16)",
                                        }}
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    sx={{
                                                        p: { xs: "5px", md: "10px 16px" },
                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                        width: "inherit",
                                                    }}
                                                    align="left"
                                                    component="th"
                                                >
                                                    {t("Emodule.table_headers.Name")}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{
                                                        p: { xs: "5px", md: "10px 16px" },
                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                        width: "inherit",
                                                    }}
                                                    align="center"
                                                    component="th"
                                                >
                                                    {t("Emodule.table_headers.Guy")}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{
                                                        p: { xs: "5px", md: "10px 16px" },
                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                        width: "inherit",
                                                    }}
                                                    align="center"
                                                    component="th"
                                                >
                                                    {t("Emodule.table_headers.Date")}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{
                                                        p: { xs: "5px", md: "10px 16px" },
                                                        fontSize: { xs: "1vw", md: "0.875rem" },
                                                        width: "inherit",
                                                    }}
                                                    align="center"
                                                    component="th"
                                                >
                                                    {t("Emodule.table_headers.Powers")}
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={{ backgroundColorColor: "transparent" }}>
                                            {
                                                data.reportData[section.parent_key]?.[section.child_key]?.[section.format]?.length > 0 &&
                                                data.reportData[section.parent_key]?.[section.child_key]?.[section.format].map((value, idx) => (
                                                    <StyledTableRow
                                                        sx={{ backgroundColor: "transparent" }}
                                                        key={idx}
                                                    >
                                                        <StyledTableCell
                                                            sx={{
                                                                p: { xs: "5px", md: "10px 16px" },
                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                width: "inherit",
                                                            }}
                                                            align="left"
                                                        >
                                                            {value?.nombre || "---"}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{
                                                                p: { xs: "5px", md: "10px 16px" },
                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                width: "inherit",
                                                            }}
                                                            align="left"
                                                        >
                                                            {value?.tipo || "---"}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{
                                                                p: { xs: "5px", md: "10px 16px" },
                                                                fontSize: { xs: "1vw", md: "0.875rem" },
                                                                width: "inherit",
                                                            }}
                                                            align="left"
                                                        >
                                                            {value?.actualizado || "---"}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{
                                                                p: { xs: "5px", md: "10px 16px" },
                                                                fontSize: { xs: value?.poderes?.length > 3 ? "0.8vw" : "1vw", md: value?.poderes?.length ? "0.675rem" : "0.875rem" },
                                                                width: "inherit",
                                                            }}
                                                            align="left"
                                                        >
                                                            {
                                                                <>
                                                                    {value?.poderes?.length > 0 &&
                                                                        value?.poderes?.map((item, idx) => (
                                                                            <Typography key={idx}
                                                                                sx={{
                                                                                    fontSize: { xs: value?.poderes?.length > 3 ? "0.8vw" : "1vw", md: value?.poderes?.length ? "0.675rem" : "0.875rem" },
                                                                                    color: "inherit",
                                                                                    mt:1
                                                                                }}
                                                                            >{item.PODER}</Typography>
                                                                        ))
                                                                    }
                                                                </>
                                                            }
                                                        </StyledTableCell>
                                                    </StyledTableRow>

                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                }

                            </TableContainer>
                        </Box>
                        : section.format === "FUNCIONARIOS" ?
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <TableContainer sx={{ borderRadius: 1 }}>
                                    {
                                        data.reportData[section.parent_key]?.[section.child_key]?.[section.format]?.length > 0 &&
                                        <Table
                                            sx={{
                                                borderRadius: 2,
                                                border: "0px solid rgba(167, 169, 169, 0.16)",
                                            }}
                                            aria-label="customized table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell
                                                        sx={{
                                                            p: { xs: "5px", md: "10px 16px" },
                                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                                            width: "inherit",
                                                        }}
                                                        align="left"
                                                        component="th"
                                                    >
                                                        {t("Emodule.table_headers.Name")}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{
                                                            p: { xs: "5px", md: "10px 16px" },
                                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                                            width: "inherit",
                                                        }}
                                                        align="center"
                                                        component="th"
                                                    >
                                                        {t("Emodule.table_headers.Guy")}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{
                                                            p: { xs: "5px", md: "10px 16px" },
                                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                                            width: "inherit",
                                                        }}
                                                        align="center"
                                                        component="th"
                                                    >
                                                        {t("Emodule.table_headers.Date")}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{
                                                            p: { xs: "5px", md: "10px 16px" },
                                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                                            width: "inherit",
                                                        }}
                                                        align="center"
                                                        component="th"
                                                    >
                                                        {t("Emodule.table_headers.residence")}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        sx={{
                                                            p: { xs: "5px", md: "10px 16px" },
                                                            fontSize: { xs: "1vw", md: "0.875rem" },
                                                            width: "inherit",
                                                        }}
                                                        align="center"
                                                        component="th"
                                                    >
                                                        {t("Emodule.table_headers.Powers")}
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{ backgroundColorColor: "transparent" }}>
                                                {
                                                    data.reportData[section.parent_key]?.[section.child_key]?.[section.format]?.length > 0 &&
                                                    data.reportData[section.parent_key]?.[section.child_key]?.[section.format].map((value, idx) => (
                                                        <StyledTableRow
                                                            sx={{ backgroundColor: "transparent" }}
                                                            key={idx}
                                                        >
                                                            <StyledTableCell
                                                                sx={{
                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                    width: "inherit",
                                                                }}
                                                                align="left"
                                                            >
                                                                {value?.nombre || "---"}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                sx={{
                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                    width: "inherit",
                                                                }}
                                                                align="left"
                                                            >
                                                                {value?.tipo || "---"}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                sx={{
                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                    fontSize: { xs: "1vw", md: "0.875rem" },
                                                                    width: "inherit",
                                                                }}
                                                                align="left"
                                                            >
                                                                {value?.actualizado || "---"}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                sx={{
                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                    fontSize: { xs: value?.domicilo.length > 100 ? "0.8vw" : "1vw", md: value?.domicilo.length > 100 ? "0.675rem" : "0.875rem" },
                                                                    width: "inherit",
                                                                }}
                                                                align="left"
                                                            >
                                                                {value?.domicilo || "---"}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                sx={{
                                                                    p: { xs: "5px", md: "10px 16px" },
                                                                    fontSize: { xs: value?.poderes?.length > 3 ? "0.8vw" : "1vw", md: value?.poderes?.length ? "0.675rem" : "0.875rem" },
                                                                    width: "inherit",
                                                                }}
                                                                align="left"
                                                            >
                                                                {
                                                                    <>
                                                                        {value?.poderes?.length > 0 &&
                                                                            value?.poderes?.map((item, idx) => (
                                                                                <Typography key={idx}
                                                                                    sx={{
                                                                                        fontSize: { xs: value?.poderes?.length > 3 ? "0.8vw" : "1vw", md: value?.poderes?.length ? "0.675rem" : "0.875rem" },
                                                                                        color: "inherit",
                                                                                        mt:1
                                                                                    }}>{item.PODER}</Typography>
                                                                            ))
                                                                        }
                                                                    </>
                                                                }
                                                            </StyledTableCell>
                                                        </StyledTableRow>

                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    }

                                </TableContainer>
                            </Box>
                            : ""
                }
            </TableCell>

        </>
    )
}

export default EreportSubInnerTable