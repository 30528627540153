import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox, Grid } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={
        <CheckCircleIcon 
          style={{ 
            color: props.disabled? "rgba(100,100,100,0.5)":  "#4165F6", 
            fontSize: 30 
          }} 
        />
      }
      icon={
        <CircleIcon
          style={{
            color: "#fff",
            border: "solid 1px rgba(112, 112, 112, 0.4)",
            borderRadius: 50,
            margin: "3px",
          }}
        />
      }
      {...props}
    />
  );
}
export default function RadioButtonsGroup(props) {
  const {
    topLabelName = "Gender",
    GroupingList = [],
    topLabel = false,
    sx_radio = {},
    onChange = () => {},
    disabled = false,
    value,
    smGrid = 2,
    sx_group = {},
    xs = 12,
  } = props;

  return (
    <FormControl fullWidth>
      {topLabel ? (
        <FormLabel
          sx={{
            fontSize: 16,
            fontFamily: "Gilroy-Medium",
            color: "rgba(33, 33, 33, 0.6) !important",
            pb: "0px",
          }}
          id="demo-radio-buttons-group-label"
        >
          {topLabelName}
        </FormLabel>
      ) : null}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        sx={{ mb: 1, ...sx_group }}
      >
        <Grid container>
          {GroupingList.map((item, index) => (
            <Grid 
              item 
              xs={xs} sm={smGrid} key={index}
              sx={{
                flexGrow: 1,
              }}
            >
              <FormControlLabel
                disabled={disabled}
                key={index}
                value={item.value}
                checked={value == item.value ? true : false}
                control={<BpRadio disabled={disabled} sx={sx_radio} />}
                label={item.label}
                onChange={onChange}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
