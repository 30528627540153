import { useState, useCallback, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// Styled FormControlLabel component
const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  position: "relative",
  "& .MuiSwitch-root": {
    width: 90,
    height: 42,
    padding: 0,
    marginRight: theme.spacing(3),
    "& .MuiSwitch-switchBase": {
      padding: 1,
      "&.Mui-checked": {
        transform: "translateX(48px)",
        color: theme.palette.common.white,
        "& + .MuiSwitch-track": {
          opacity: 1,
          border: "none",
          backgroundColor: "#FF8D28",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 40,
      height: 40,
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      borderRadius: 50,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.action.selected
          : "#DBDDDE",
      border: "1px solid #DBDDDE",
      transition: theme.transitions.create(["background-color", "border"]),
    },
  },
}));

const SwitchesCustomized = ({ isAutoComplete, toggleButton }) => {
  const { t } = useTranslation();
  const [switchState, setSwitchState] = useState(isAutoComplete);
  useEffect(() => {
    // Update the switch state when the isAutoComplete prop changes
    setSwitchState(isAutoComplete);
  }, [isAutoComplete]);
  const handleSwitchChange = () => {
    toggleButton(!switchState);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography sx={{ fontSize: 16, color: "rgba(33, 33, 33, 0.6)", mr: 4 }}>
        {t("Dashboard-main.rightContent.buttons.Autocomplete")}
      </Typography>
      <FormControlLabel
        control={<Switch checked={switchState} onChange={handleSwitchChange} />}
        labelPlacement="start"
        label={
          <Box
            sx={{ position: "absolute", bottom: 8, zIndex: 3, display: "flex" }}
          >
            <Box
              component="span"
              sx={{
                display: switchState ? "block" : "none",
                color: "#ffffff",
                width: 60,
                textAlign: "center",
              }}
            >
              {t("GuestDashboard.autocompleteButtonyes")}
            </Box>
            <Box
              component="span"
              sx={{
                display: switchState ? "none" : "block",
                color: "#707070",
                width: 120,
                textAlign: "center",
              }}
            >
              {t("GuestDashboard.autocompleteButtonno")}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default SwitchesCustomized;
